

  <button type="button" class="btn btn-success"   style="margin-bottom: 30px;" (click)="addBanner">Add Banner</button>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example" datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Banners</th>
                      <th>Action</th>                      
                  </tr>
              </thead>
             <tbody>
               <tr *ngFor="let category of banners; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{category.name}}</td>
                     <td>
                      <mat-icon class="marginLeft10" color="warn" aria-hidden="false" aria-label="delete icon" matTooltip="delete banner" (click)="deleteBanner(category._id)">delete</mat-icon>
                     </td>
                 </tr> 
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>