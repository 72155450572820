<div class="row">
    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-border">
            <div class="card-body">
                <form [formGroup]="form" (ngSubmit)="addStats()">
                    <div class="form-group">
                        <label class="required">Title:</label>
                        <input class="form-control" type="text" formControlName="value"
                            (input)="space($event,'value',form)"
                            [ngClass]="{ 'is-invalid': formSubmitted && form.controls.value.errors}" />
                    </div>
                    <div class="justify-content-center d-flex mt-4">
                        <button type="submit" class="btn btn-submit mr-3">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <h2 class="card-title">Added Health Stats</h2>
                <table id="example" *ngIf="stats" datatable class="display table table-bordered">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Title</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="stats">
                        <tr *ngFor="let stat of stats, let i=index">
                            <td>{{ i+1 }}</td>
                            <td>{{ stat.title }}</td>
                            <td>
                                <span *ngIf="editId!=stat._id">{{ stat.value_prefix }}</span>
                                <span *ngIf="editId!=stat._id">{{ stat.count ? stat.count : 0}}</span>
                                <span *ngIf="editId!=stat._id">{{ stat?.count_type}}</span>

                                <form [formGroup]="countForm" (ngSubmit)="submitCount(stat)">
                                    <select class="count-prefix" *ngIf="editId==stat._id" (change)="onPrefixChange($event, stat._id)">
                                        <option selected value="">Select</option>
                                        <option [disabled]="stat.value_prefix == '$'" [selected]="stat.value_prefix == '$'" value="$">$</option>
                                    </select>

                                    <input class="w-25" type="text" *ngIf="editId==stat._id" (input)="onChange($event,'count',countForm)"
                                        formControlName="count"
                                        [ngStyle]="countSubmitted && countForm.invalid ? {'border': '1px solid red'} : {}" />
                                    
                                    <select class="count-type" *ngIf="editId==stat._id"
                                        (change)="onUpdateOptionClick($event, stat._id)">
                                        <option selected value="">Select</option>
                                        <option *ngFor="let type of countTypeList"  [disabled]="stat.count_type == type" [selected]="stat.count_type == type"
                                            [value]="type">{{type}}</option>
                                    </select>

                                    <button type="submit" *ngIf="editId==stat._id">
                                    <i class="fa fa-check text-success mr-2 ml-2" aria-hidden="true"></i>
                                    </button>
                                    <i (click)="edit(stat)" *ngIf="editId!=stat._id"
                                        class="fa fa-pencil-square-o mr-2 ml-2" aria-hidden="true"></i>
                                </form>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="!stats">No health stats added yet</div>
            </div>

        </div>
    </div>
</div>