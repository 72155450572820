<!doctype html>
<html lang="en">
  <head>
    <title>Big4Health</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      <link rel="icon" href="https://res.cloudinary.com/appsmaven/image/upload/v1588578035/Big4Health/Logo_ww5feu.png" type="image/gif" sizes="16x16">

    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css">
    <style>
    	p
    	{
    		text-align: left;
    	}
      ol
      {
        text-align: justify;
      }
      td
      {
        text-align: justify;
      }
      h4
      {
        margin: 20px 0px;
        font-size: 22px;
      }
      h5
      {
        margin: 15px 0px 0px;
      }
      a
       {
        text-decoration: underline;
        word-break: break-all;
       }
 
    </style>
  </head>
  <body>

	<div class="container">
 <h1 class="mt-4 mb-4"> Copyright Notice</h1>

<p>All files and information contained in this App or Blog located at<a href="http://www.big4healthapp.com"> http://www.big4healthapp.com </a>are copyright by CollConnect L.L.C, and may not be duplicated, copied, modified or adapted, in any way without our written permission. Our App or Blog may contain our service marks or trademarks as well as those of our affiliates or other companies, in the form of words, graphics, and logos.</p>
<p>Your use of our App, Blog or Services does not constitute any right or license for you to use our service marks or trademarks, without the prior written permission of CollConnect L.L.C.</p>
<p>Our Content, as found within our App, Blog and Services, is protected under local and foreign copyrights. The copying, redistribution, use or publication by you of any such Content, is strictly prohibited. Your use of our App and Services does not grant you any ownership rights to our Content.</p>
<p><b>Enforcement of copyright</b></p>
<p>CollConnect L.L.C takes the protection of its copyright very seriously.</p>
<p>If CollConnect L.L.C discovers that you have used its copyright materials in contravention of the license above, CollConnect L.L.C may bring legal proceedings against you seeking monetary damages and an injunction to stop you using those materials.  You could also be ordered to pay legal costs.</p>
<p>If you become aware of any use of CollConnect L.L.C’s copyright materials that contravenes or may contravene the license above, please report this to us immediately.</p>
<p>Copyright © CollConnect L.L.C 2019 All Rights Reserved</p>



	</div>
  </body>
</html>