import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OfferService } from 'src/app/services/offer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-update-offers',
  templateUrl: './update-offers.component.html',
  styleUrls: ['./update-offers.component.css']
})
export class UpdateOffersComponent implements OnInit {

  categories: any[] = [];

  OfferForm: FormGroup;

  files: any[] = [];

  submitted: boolean = false;

  OfferId: string = '';
  fileTypes = ["image/jpeg", "image/png", "image/jpg", "image/tiff", "image/gif"];

  numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
  productName;
  isFileChanged: boolean = false;
  baseUrl = environment.url;
  offerImage: string = '';




  constructor(private _fb: FormBuilder, private _activateRoute: ActivatedRoute,
    private _offerService: OfferService,
    private router: Router) {


    this.OfferForm = this._fb.group({
      product_id: ['', Validators.required],
      off_percent: ['', [Validators.required]],
      image: ['']


    });
  }

  ngOnInit(): void {
    // console.log(this._activateRoute.snapshot.paramMap)
    this.OfferId = this._activateRoute.snapshot.paramMap.get('id');
    console.log(this.OfferId, " this.OfferId ")
    this.getOfferDetailById(this.OfferId);
    this.getAllProducts();
  }



  getOfferDetailById(OfferId: string) {
    this._offerService.getOfferById(OfferId).subscribe((resp: any) => {
      if (resp.status == 200) {
        const product = resp.data;
        this.offerImage = product.image;

        this.OfferForm.controls.product_id.setValue(product.product_id);
        this.OfferForm.controls.off_percent.setValue(product.off_percent);
        //  this.OfferForm.controls.image.setValue(product.image);


      }
    })
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      const fileType = file.type;
      if (this.fileTypes.includes(fileType)) {
        this.OfferForm.controls.image.setValue(file);
        reader.onload = () => {
          this.offerImage = reader.result as string;
          this.isFileChanged = true;
        }
      } else {
        alert("only " + this.fileTypes.toString() + " format supports");
      }
    }
  }


  getAllProducts() {
    this._offerService.getProducts('').subscribe((resp: any) => {
      if (resp.status == 200) {
        this.categories = resp.data;
        const selectedProduct = this.categories.find(product => product._id === this.OfferForm.controls.product_id.value)
        this.productName = selectedProduct.name

      }
    })
  }


  get f() {
    return this.OfferForm.controls;
  }



  onlyNumber(event: KeyboardEvent) {
    const key = event.key;
    const price = this.OfferForm.controls.off_percent.value;
    if (key === '.')
      return String(price).indexOf(key) > -1 ? false : true;
    return this.numbers.includes(key);
  }


  //  updateOffer(){
  //      this.submitted = true;
  //      console.log(this.OfferForm,"offerform")
  //      if(this.OfferForm.valid){
  //      const product = this.OfferForm.value;
  //      console.log(product,"product")
  //      const event = {

  //       "product_id": this.OfferForm.value.product_id,
  //       "off_percent":this.OfferForm.value.off_percent,
  //       "type":"product",
  //     }
  //      this._offerService.updateOffer(this.OfferId,event).subscribe((response : any) =>{
  //             this.router.navigate(['/offers/']);      
  //      })
  //     }
  //  }

  updateOffer() {
    this.submitted = true;
    if (this.OfferForm.valid) {
      const offer = this.OfferForm.value;
      const formData = new FormData();
      formData.append('product_id', offer.product_id);
      formData.append('off_percent', offer.off_percent);
      formData.append('type', 'product');
      if (offer.image) {
        formData.append('image', offer.image);
      }
      this._offerService.updateOffer(this.OfferId, formData).subscribe((response: any) => {
        this.router.navigate(['/offers/']);
      })
    }
    else {
      return
    }
  }
}





