import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  
  loading$ = new BehaviorSubject<Boolean>(false);

  constructor() {
   }

  /**
   * start loader
   */
  loadingOn() {
    this.loading$.next(true);
  }


  /**
   * stop loader
   */
  loadingOff() {
    this.loading$.next(false);
  }
  showLoader<T>(appliedOn: Observable<T>){
    return of(null)
      .pipe(
        tap(() => this.loadingOn()),
        concatMap(() => appliedOn),
        finalize(() => this.loadingOn()))
      
  }
}
