import 'datatables.net';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-exercise-videos',
  templateUrl: './exercise-videos.component.html',
  styleUrls: ['./exercise-videos.component.css']
})
export class ExerciseVideosComponent implements OnInit {
  
  allVideos;
  videoId;
  currentPlayingVideo
  public showLoader: boolean = false;

  apiBaseUrl: string = environment.url;


  constructor(private http: HttpClient, private router: Router, private snackbar: SnackbarService) { }
  ngOnInit() {

    this.getAllVideos();
    $(document).ready(function () {
      $('#example').DataTable();
    });


  }

  getAllVideos() {
    this.showLoader = true;
    this.http.get(this.apiBaseUrl + 'v1/getAllExerciseVideos').subscribe((data: any) => {

      if (data.status == 200) {
        this.showLoader = false;
        this.allVideos = data.data;
        $(document).ready(function () {
          $('#example').DataTable();
        });


      }
      else {

        this.allVideos = '';

      }

    })

  }


  openAddVideoPage = () => {

    this.router.navigateByUrl('/add-exercise-video')
  }

  onPlayingVideo(event) {
    event.preventDefault();
    // play the first video that is chosen by the user
    if (this.currentPlayingVideo === undefined) {
      this.currentPlayingVideo = event.target;
      this.currentPlayingVideo.play();
    } else {
      // if the user plays a new video, pause the last one and play the new one
      if (event.target !== this.currentPlayingVideo) {
        this.currentPlayingVideo.pause();
        this.currentPlayingVideo = event.target;
        this.currentPlayingVideo.play();
      }
    }
  }

  deleteVideo(id) {
    console.log("hereee")
    if (confirm("Are you sure to want to delete?")) {
      this.videoId = { "videoId": id };
      this.http.post(this.apiBaseUrl + 'v1/deleteVideo', this.videoId).subscribe((data: any) => {
        if (data.status == 200) {
          this.snackbar.successSnackBar(" Exercise deleted successfully.", '');
          this.getAllVideos();
        }
        else { }
      });
    }
    else { }
  }
}

