import 'rxjs/add/operator/map';
import { Router} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';
import { FormGroup, FormControl,Validators } from '@angular/forms';

@Component({
  selector: 'app-add-sound',
  templateUrl: './add-sound.component.html',
  styleUrls: ['./add-sound.component.css']
})
export class AddSoundComponent implements OnInit {

  
formdata;
soundLink;
soundName;
soundType;
submitted;
formdetail;
invalid:boolean  = false;
imageUrl;
imageSrc;
unvalidImgTypeMsg;
uploaded:boolean  = false;
extFile;


public showLoader:boolean= false;

apiBaseUrl : string = environment.url;


    constructor(private snackbar: SnackbarService,private http: HttpClient, private router: Router) { }

  ngOnInit() {
  this.formdata = new FormGroup({
        soundFile: new FormControl("",[Validators.required]),
        soundName: new FormControl("",[Validators.required]),
         soundType: new FormControl("",[Validators.required])
      });

}

  get f() { return this.formdata.controls; }

   addExerciseVideos(data) {
  
      this.submitted = true;         
        if (this.formdata.invalid) {
            return;
        }
        if (this.extFile != "mp3") {
          alert("Please upload .mp3 file")
          return
        }
         this.formdetail = {
             'soundLink':this.imageUrl,
             'soundName':data.soundName,
             'soundType':data.soundType
         }       
 
    this.http.post(this.apiBaseUrl+'v1/addSound',this.formdetail).subscribe((data : any) => {
     if(data.status == 200){ 
      this.snackbar.successSnackBar("Sound added successfully.",'');
       this.router.navigate(['/stress-management']);
     
   }     
         else
         {
          
          this.invalid = true;
  
         }

     })

     }




  uploadInstallerImage(imageData) {
         
    const formData: any = new FormData();
    const fileName: any = imageData.target.files[0];
    formData.append('soundFile', fileName);
    console.log(formData);
    const idxDot = imageData.target.files[0].name.lastIndexOf(".") + 1;
    this.extFile = imageData.target.files[0].name.substr(idxDot, imageData.target.files[0].name.length).toLowerCase();
    
    if (this.extFile == "mp3") {
      this.showLoader = true;
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(fileName);
      this.unvalidImgTypeMsg = false;
    this.http.post(this.apiBaseUrl+'fileupload2', formData).subscribe((data : any) => {
      console.log("POST Request is successful ", data);

      if (data.status = 200) {
             this.showLoader = false;
        this.uploaded= true;
        this.imageUrl = data.data;
      }

    })
    }else{
      this.unvalidImgTypeMsg = true;
    }

  }





}
