import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';
import { ShopAndProductService } from 'src/app/shopAndProductManagement/service/shop-and-product.service';

@Component({
  selector: 'app-user-subscription-detail',
  templateUrl: './user-subscription-detail.component.html',
  styleUrls: ['./user-subscription-detail.component.css']
})
export class UserSubscriptionDetailComponent implements OnInit {

  config: SwiperOptions = {
    pagination: { 
      el: '.swiper-pagination', 
      clickable: true 
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoplay : true,
    fadeEffect : { crossFade : true},
    spaceBetween: 30
  };

  product : any;

  apiBaseUrl = environment.url;
  selectedColors;
  backgroundColor = '#FFFFFF80';


  constructor(private _activateRoute : ActivatedRoute, private _shopAndProductService : ShopAndProductService) {
    
   }

  ngOnInit(): void {
    console.log(this._activateRoute.snapshot.paramMap)
    this.getProductDetailById(this._activateRoute.snapshot.paramMap.get('id'))
  }

  getProductDetailById(productId : string){
     this._shopAndProductService.getProductById(productId).subscribe((resp : any) =>{
          if(resp.status == 200){
              this.product = resp.data;
              this.selectedColors = resp.data.color
              console.log(this.selectedColors,"oooooooooooooooooo")

          }
     })
  }

}
