

  <button type="button" class="btn btn-success" (click)="addCategory(null)"  style="margin-bottom: 30px;">Add Category</button>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example"   datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Name</th>
                      <th>Action</th>                      
                  </tr>
              </thead>
             <tbody>
               <tr *ngFor="let category of categories; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{category.name}}</td> 
                
                     <td>
                      <mat-icon  color="primary" aria-hidden="false" aria-label="view icon" routerLink="/{{route.eLearningVideos}}/{{category._id}}" matTooltip="open category videos">open_in_new</mat-icon>   
                      <button  (click)="updateCategory(category)" class="btn btn-success mr-2 ml-2" matTooltip="Edit" > <i class="fa fa-edit"
                        aria-hidden="true"></i></button>
                      <button matTooltip="Delete" (click)="deleteCategory(category._id)" class="btn btn-danger"> <i class="fa fa-trash"
                        aria-hidden="true"></i></button>
                     </td>
                 </tr>
                
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>