
<html lang="en">
  <head>
    <title>BiG4 Health</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

 
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap" rel="stylesheet">  
    
  </head>
  <body>
 
    <div class="container p-0">
        <div class="component">
              <div class="login-form">
                <div class="main-div">
                    <div class="panel">
                        <div class="logo">
                            <img src="assets/Logo.png" class="img-fluid mb-4">
                        </div>

                    </div>
                     <div *ngIf="invalid" style="color: #dc3545; padding-left:26px; font-size: 100%;">Invalid Credentials</div>

                    <form [formGroup] = "form" (ngSubmit) = "adminLogin()" (keyup.enter)="adminLogin()" enctype="multipart/form-data">
                        <div class="form-group">
                            <input type="email" formControlName='email' class="form-control custom-filed" id="inputEmail" placeholder="Email Address"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div class="icon">
                                <i class="fa fa-envelope inbox-icon"></i>
                            </div>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf='f.email.errors.required'>E-Mail is required</div>
                            <div *ngIf='f.email.errors.email'>Invalid E-mail</div>
                    </div>
                        </div>
                        <div class="form-group">
                            <input type="password" formControlName='password' class="form-control custom-filed" id="inputPassword" placeholder="Password"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <div class="icon">
                                <i class="fa fa-lock pass-icon"></i>
                            </div>
                             <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf='f.password.errors.required'>Password is required</div>
                    </div>
                        </div>                     
                         <input class="btn btn-login mt-4" type="submit" value="LOG IN">
                    </form>
                </div>
                <p class="botto-text mt-5 mb-5"> All Rights Reserved | BIG4 Health</p>
            </div>
        </div> 
    </div>


  </body>


    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script>
</html>