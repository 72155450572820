import * as $ from 'jquery';
import { Router } from '@angular/router';
import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';


@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  blogs : any[] = [];
  route : Routes = new Routes();

  constructor(private _blogService : BlogService, private _router : Router) { }

  ngOnInit(): void {
     this.getBlogs();
  }

  getBlogs(){
    this._blogService.getBlogs().subscribe((response : any)=>{
          if(response.status==200){
             this.blogs = response.data;
          }
          $(document).ready(function() {
            $('#example').DataTable();
            } );
    })
  }

  editBlog(blogId : string){
     this._router.navigate([this.route.updateBlog+'/'+blogId])
  }

  deleteBlog(blogId : string){
      const confirm = window.confirm("Are you sure you want to delete this blog");
      if(confirm){
        this._blogService.deleteBlog(blogId).subscribe((response : any)=>{
          const index =  this.findDeleteBlogIndex(blogId);
         // console.log(index);
          this.blogs.splice(index,1);
          $(document).ready(function() {
            $('#example').DataTable();
            } );
      })
      }
  }

  findDeleteBlogIndex(blogId : string){
      let index = 0;
      for (index = 0; index < this.blogs.length; index++) {
           if(blogId === this.blogs[index]._id)
                break;
      }
      return index;
  }

}
