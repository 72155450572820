import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _router: Router, public dialog: MatDialog) {

  }

  successSnackBar(message: string, router: string) {
    this.dialog.open(SnackbarComponent, {
      data: {
        autoClose: true,
        message: message,
        router: router
      }
    });
  }

  show(message: string, router: string) {
    this.dialog.open(SnackbarComponent, {
      data: {
        autoClose: false,
        message: message,
        router: router,
      }
    });
  }

  alertWithConfirmations(message){
    return Swal.fire({
        title: message,
        icon: 'warning',
        showClass: {
            popup: 'productEnable'
        },
        showCancelButton: true,
        confirmButtonText: 'OK',
    })
}

alertWithConfirmationsWithOutCancel(message){
  return Swal.fire({
      title: message,
      icon: 'warning',
      showClass: {
          popup: 'productEnable'
      },
      // showCancelButton: true,
      confirmButtonText: 'OK',
  })
}

alert(message){
  return Swal.fire({
      icon: 'warning',
      text:message
  })
}
}
