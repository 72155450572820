       <button type="button" class="btn btn-success" (click)="openAddVideoPage()" style="margin-bottom: 30px;">Add Exercise Video</button>


        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
           <div class="card card-border">
            <div class="card-body" id = "example_wrapper">
              <table id="example" *ngIf="allVideos"  datatable class="display">
                <thead>
                    <tr >
                        <th>Sr. No.</th>
                        <th>Category</th>
                        <th>Name</th>
                        <th>Video</th>                        
                        <th>Created Date</th>                     
                        <th>Action</th>                     
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let video of allVideos, let i=index">
                        <td>{{i+1}}</td>
                        <td>{{video.categoryName}}</td>
                        <td>{{video.type}}</td>
                        
                        <td><video width="220" height="160" controls (playing)="onPlayingVideo($event)">
                        <source src={{video.videoLink}} type="video/mp4">
                      </video></td>   
                        <td>{{video.createdOn | date}}</td>
                       <td>
                        <button (click)="deleteVideo(video._id)" class="btn btn-danger" matTooltip="Delete"> <i class="fa fa-trash"
                          aria-hidden="true"></i></button>
                      </td>
                    </tr>                   
                </tbody>
              </table>
            </div>
           </div>
          </div>
        </div>

