import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  count: any

  constructor(private _router: Router) { }

  ngOnInit(): void { }

  logout() {
    sessionStorage.clear();
    this._router.navigate([''])
  }
}
