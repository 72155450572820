import 'rxjs/add/operator/map';
import { Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';
import { FormGroup, FormControl,Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-exercise',
  templateUrl: './edit-exercise.component.html',
  styleUrls: ['./edit-exercise.component.css']
})
export class EditExerciseComponent implements OnInit {


formdata;
exerciseId;
exerciseName;
timeDuration;
submitted;
formdetail;
invalid:boolean  = false;

apiBaseUrl : string = environment.url;

constructor(private http: HttpClient, private router: Router,private route:ActivatedRoute,private snackbar:SnackbarService) { }

    //constructor(private http: Http, private router: Router) { }

  ngOnInit() {
this.exerciseId = this.route.snapshot.params.id;

 this.formdata = new FormGroup({
      exerciseName: new FormControl("",[Validators.required]),
      timeDuration: new FormControl("",[Validators.required])     
    });

this.http.post(this.apiBaseUrl+'v1/getExerciseAdminForEdit',{id:this.exerciseId}).subscribe((data : any) => {
     if(data.status == 200){
     	console.log(data.data[0])
this.formdata.setValue({
'exerciseName' : data.data[0].exerciseName,
'timeDuration' : data.data[0].timeDuration
})

}
})

}

  get f() { return this.formdata.controls; }







 editExerciseByAdmin(data) {
      this.submitted = true;         
        if (this.formdata.invalid) {
            return;
        }
         this.formdetail = {
             'exerciseId':this.exerciseId,
             'exerciseName':data.exerciseName,
             'timeDuration':data.timeDuration
         }       
    
    
    this.http.post(this.apiBaseUrl+'v1/editExerciseAdmin',this.formdetail).subscribe((data : any) => {
     if(data.status == 200){ 
      this.snackbar.successSnackBar("Exercise updated successfully.",'');

       this.router.navigate(['/exercises']);
     
   }     
         else
         {
          
          this.invalid = true;
  
         }

     })

     }




numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  
}
