<!--<button type="button" class="btn btn-success" routerLink="/quiz"  style="margin-bottom: 30px;">View All Quiz</button>
-->

<div class="row" >
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
   <div class="card card-border">
    <div class="card-body" id = "example_wrapper">
        <form [formGroup] = "OfferForm">
            <div class="row">
               <div class="col-sm-4">
                   <div class="form-group">
                       <label for="product_id" class="required">Select Product:</label>
                       <select class="form-control" formControlName="product_id" [ngClass]="{ 'is-invalid': submitted && f.product_id.errors}" >
                        <option *ngFor="let category of categories; let i = index" [value]="category._id">{{category.name}}</option>
                       </select>
                   </div>
               </div>
               <div class="col-sm-4">
                <div class="form-group">
                    <label for="off_percent" class="required">Off Percent:</label>
                    <input id="off_percent" maxlength="5"    formControlName="off_percent" [ngClass]="{ 'is-invalid': submitted && f.off_percent.errors}" (keypress)="onlyNumber($event)" class="form-control" placeholder="Enter Off">
                    <div *ngIf="OfferForm.controls.off_percent.invalid &&  submitted"
                    class="error">
                    <div *ngIf="OfferForm.controls.off_percent.errors.required">
                        Off Percent Required
                    </div>
                </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                   <div class="form-group">
                       <input type="file" id="image" [ngClass]="{ 'is-invalid': submitted && f.image.errors}" class="form-control" accept="image/*" (change)="onFileChange($event)">
                   </div>
               </div>
              </div> 
            </div>

           
  
        <div class="justify-content-center d-flex mt-4" >
          <button type="submit" class="btn btn-submit mr-3" (click)="addOffer($event)" >Submit</button>
        </div>
      
      <!-- </form> -->
    <!-- </div> -->
   <!-- </div>
  </div> -->
</div>
