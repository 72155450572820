import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  
  apiBaseUrl = environment.url;

  constructor(private _http : HttpClient) { }

  addBlog(blog : FormData){
      return this._http.post(`${this.apiBaseUrl}v1/blog`, blog);
  }

  getBlogs(){
    return this._http.get(`${this.apiBaseUrl}v1/blog_admin`);
  }

  deleteBlog(blogId : string){
    return this._http.delete(`${this.apiBaseUrl}v1/blog/${blogId}`);
  }

  getBlogById(blogId : string){
    return this._http.get(`${this.apiBaseUrl}v1/blog/${blogId}`);
  }

  updateBlog(blogId : string, blogData : FormData){
     return this._http.put(`${this.apiBaseUrl}v1/blog/${blogId}`, blogData);
  }

}
