import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  apiBaseUrl : string = environment.url;

  constructor(private _http : HttpClient) { }

  getsubscription(){
    return this._http.get(this.apiBaseUrl+'v1/transactions/subscriptions');
 }


}
