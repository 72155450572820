import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HealthDataService {

  apiBaseUrl: string = environment.url;

  constructor(private _http: HttpClient) { }

  add(data) {
    return this._http.post(this.apiBaseUrl + 'v1/admin/update-configuration', data );
  }

  getAll() {
    return this._http.get(this.apiBaseUrl + 'v1/get-graphs');
  }

  updateCount(id,count){
    return this._http.put(this.apiBaseUrl + `v1/admin/update-graph-data/${id}`,count);
  }


}
