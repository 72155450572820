<button type="button" class="btn btn-success" (click)="openAddSoundPage()" style="margin-bottom: 30px;">Add
  Sound</button>


<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="card card-border">
      <div class="card-body" id="example_wrapper">
        <table id="example" *ngIf="allExercise" datatable class="display">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Sound Type</th>
              <th>Sound Name</th>
              <th>Sound Link</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let exercise of allExercise, let i=index">
              <td>{{i+1}}</td>
              <td>{{exercise.soundType}}</td>
              <td>{{exercise.soundName}}</td>
              <td><audio controls>
                  <source src="{{exercise.soundLink}}" type="audio/ogg">
                  Your browser does not support the audio tag.
                </audio></td>
              <td>
                <button (click)="deleteSound(exercise._id,i)" class="btn btn-danger" matTooltip="Delete" matTooltipPosition="bottom"> <i class="fa fa-trash"
                    aria-hidden="true"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>