import { environment } from 'src/environments/environment';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CertificationLogoService } from 'src/app/services/certification-logo.service';

@Component({
  selector: 'app-logo-certification',
  templateUrl: './logo-certification.component.html',
  styleUrls: ['./logo-certification.component.css']
})
export class LogoCertificationComponent implements OnInit {

  id: number
  photo: any
  isEdit: boolean = false
  fileInvalid = false;
  selectedFile: File
  certifications: any[]
  baseUrl: string = environment.url;
  @ViewChild('image') image: ElementRef

  constructor(
    private certificationLogoService: CertificationLogoService) { }

  ngOnInit(): void {
    this.getAll();
  }

  addLogo() {

    if (!this.fileInvalid && this.selectedFile) {
      const formData = new FormData();
      formData.append('certification', this.selectedFile);

      if (this.isEdit) {
        this.certificationLogoService.edit(this.id, formData).subscribe(response => {
          this.clear()
        })
      } else {
        this.certificationLogoService.add(formData).subscribe((response: any) => {
          this.certifications.unshift({ certification: response.addCertification.certification });
          this.clear();
        })
      }
    } else {
      this.fileInvalid = true;
    }
  }

  clear() {
    this.selectedFile = null;
    this.image.nativeElement.value = ''
    this.isEdit = false
    this.photo = null
    this.getAll()
  }

  onFileChange(event) {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      this.fileInvalid=false
      this.selectedFile = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          
          if(height < 180 || width < 180) {
            this.fileInvalid = true    
          } else {
            this.photo = reader.result;
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
      this.fileInvalid=false
    } else {
      this.image.nativeElement.value=''
      this.fileInvalid = true;
      this.selectedFile = null;
    }
  }

  getAll() {
    this.certificationLogoService.getAll().subscribe((response: any) => {
      this.certifications = response.getCertification
    })
  }

  edit(id: number, file) {
    this.id = id
    this.isEdit = true
    this.photo =  file
  }

  delete(id) {
    const index = this.certifications.findIndex(item => item._id === id);
    this.certificationLogoService.delete(id).subscribe(response => {
      this.certifications.splice(index, 1);
    })
  }

}
