import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PostService } from 'src/app/services/post.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-world-post-details',
  templateUrl: './world-post-details.component.html',
  styleUrls: ['./world-post-details.component.css']
})
export class WorldPostDetailsComponent implements OnInit {

  post:any
  baseUrl=environment.url

  constructor(private postService: PostService,
    private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      
      this.getPostBySlug(params.get('id'))
    });
  }

  getPostBySlug(slug: string) {
    this.postService.getPostDetail(slug).subscribe((response: any) => {
      this.post=response.data
    })
  }


}
