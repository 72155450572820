import Quill from 'quill';
import { Router } from '@angular/router';
import { Routes } from 'src/app/models/routes';
import { EditorChangeContent } from 'ngx-quill';
import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.css']
})
export class CreateBlogComponent implements OnInit {

  blogForm : FormGroup

  submitted : boolean = false;

  fileTypes = ["image/jpeg","image/png","image/jpg","image/tiff","image/gif"];

  routes : Routes = new Routes();

  constructor(private _fb : FormBuilder, private _blogService : BlogService, private _router : Router) { }

  ngOnInit(): void {
     this.blogForm = this._fb.group({
        title : ['', [Validators.required, Validators.maxLength(60)]],
        description : ['', Validators.required],
        blogCategory : ['', Validators.required],
        short_description : [''],
        image : ['', Validators.required],
        author: ['', Validators.required],
        bio:['',Validators.required]
     })
  }

  get f(){
    return this.blogForm.controls;
  }

  created(event: Quill) {
    // tslint:disable-next-line:no-console
    console.log('editor-created', event)
  }

  contentChanged(event : EditorChangeContent){
      if(event.text.trim() ==""){this.blogForm.get('description').setValue('')}
         this.blogForm.controls.short_description.setValue(event.text.substring(0,102).replace('\n',' ').trim());
  }

   onFileChange(event : any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
     const fileType=file.type;
    if(this.fileTypes.includes(fileType)){
         this.blogForm.controls.image.setValue(file);
    }else{
      alert("only "+this.fileTypes.toString()+" format supports");
    }
    }
  }

  createBlog(){
      this.submitted = true;
      console.log(this.blogForm.value);
      if(this.blogForm.valid){
            const blog = this.blogForm.value;
            const formData = new FormData();
            formData.append('title',blog.title);
            formData.append('blogCategory',blog.blogCategory);
            formData.append('description', blog.description);
            formData.append('short_description', blog.short_description);
            formData.append('image', blog.image);
            formData.append('author', blog.author);
            formData.append('bio', blog.bio);

            console.log("category..",formData)

            this._blogService.addBlog(formData).subscribe((response : any) =>{
               if(response.status==200){
                    this.blogForm.reset();
                    this.submitted = false;
                    this._router.navigate([this.routes.blogs]);
               }
                    
            })
      }else{
         if(this.blogForm.controls.title.errors?.maxlength){
            alert("Title must be less than or equal to "+this.blogForm.controls.title.errors.maxlength.requiredLength+" characters");
         }
      }
  }

  space(event,formControl){ 
    if(event?.target?.value?.trim() ==""){ this.blogForm.get(formControl).setValue('')}
  }
}
