

  <!-- <button type="button" class="btn btn-success" routerLink="/{{route.addProduct}}/{{categoryname}}"  style="margin-bottom: 30px;">Add New Product</button> -->
 

  <div class="categoryname"> {{category_name|titlecase}}</div>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example" datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Category Name</th>
                      <th>Product Name</th>
                      <th>Price(one time)</th>
                      <th>Quantity</th>
                      <th>Action</th>                      
                  </tr>
              </thead>
             <tbody>
               <tr *ngFor="let product of products; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{product.category.name}}</td> 
                     <td>{{product.name}}</td> 
                     <td>{{product.price}}</td> 
                     <td>{{product.quantity}}</td> 
                     <td>
                      <mat-icon  color="primary" aria-hidden="false" aria-label="view icon" routerLink="/{{route.viewupdateProduct}}/{{product._id}}" matTooltip="view product detail">open_in_new</mat-icon>   
                      <mat-icon class="marginLeft10" color="primary" aria-hidden="false" aria-label="edit icon" matTooltip="edit product" (click)="updateProduct(product._id)" >edit</mat-icon> 
                      <!-- <mat-icon class="marginLeft10" color="warn" aria-hidden="false" aria-label="delete icon" matTooltip="delete product" (click)="deleteProduct(product._id)">delete</mat-icon> -->
                     </td>
                 </tr>
                
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>