

  <button type="button" class="btn btn-success" routerLink="/add_quiz"  style="margin-bottom: 30px;">Add Quiz</button>

        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
           <div class="card card-border">
            <div class="card-body" id = "example_wrapper">
                <table id="example"   datatable class="display" *ngIf="!showLoader">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Name</th>
                            <!-- <th>Location</th> -->
                            <th>Action</th>                      
                        </tr>
                    </thead>
                   <tbody>
                       <tr *ngFor="let quiz of quizzes; let i = index">
                           <td>{{i+1}}</td>
                           <td>{{quiz.name}}</td> 
                           <!-- <td>{{quiz.locations}}</td> -->
                           <td>
                            <button routerLink="/update_quiz/{{quiz._id}}" class="btn btn-success mr-2" matTooltip="Edit"> <i class="fa fa-edit"
                                aria-hidden="true"></i></button>
                            <button (click)="deleteQuiz(quiz._id)" *ngIf="quizzes.length>1" class="btn btn-danger" matTooltip="Delete"> <i class="fa fa-trash"
                                aria-hidden="true"></i></button>
                           </td>
                       </tr>
                   </tbody>
                  </table>
            </div>
           </div>
          </div>
        </div>
