import 'datatables.net';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import { Router} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

 allFeedback;
 public showLoader:boolean= false;

 apiBaseUrl : string = environment.url;


    constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {

this.getAllFeedback();
     $(document).ready(function() {
    $('#example').DataTable();
    } );


}




   getAllFeedback() {  
      this.showLoader = true;
    this.http.get(this.apiBaseUrl+'v1/getAllFeedback').subscribe((data : any) => {
    
     if(data.status == 200){ 
       this.showLoader = false;
      this.allFeedback= data.data;      
     $(document).ready(function() {
    $('#example').DataTable();
    } );


   }     
         else
         {
          
         this.allFeedback= ''; 
  
         }

     })

     }



}


