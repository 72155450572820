import 'rxjs/add/operator/map';
import { Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';
import { FormGroup, FormControl,Validators } from '@angular/forms';

@Component({
   selector: 'app-add-exercise',
  templateUrl: './add-exercise.component.html',
  styleUrls: ['./add-exercise.component.css']
})
export class AddExerciseComponent implements OnInit {

formdata;
exerciseName;
timeDuration;
submitted;
formdetail;
invalid:boolean  = false;

apiBaseUrl : string = environment.url;


    constructor(private snackbar: SnackbarService,private http: HttpClient, private router: Router) { }

  ngOnInit() {
  this.formdata = new FormGroup({
        exerciseName: new FormControl("",[Validators.required]),
         timeDuration: new FormControl("",[Validators.required])
      });

}

  get f() { return this.formdata.controls; }

   addExercise(data) {
      this.submitted = true;         
        if (this.formdata.invalid) {
            return;
        }
         this.formdetail = {
             'exerciseName':data.exerciseName,
             'timeDuration':data.timeDuration
         }       
    
    console.log(this.formdetail)

    this.http.post(this.apiBaseUrl+'v1/addExerciseAdmin',this.formdetail).subscribe((data : any) => {
     if(data.status == 200){ 
      this.snackbar.successSnackBar(" Exercise added successfully.",'');
       this.router.navigate(['/exercises']);
     
   }     
         else
         {
          
          this.invalid = true;
  
         }

     })

     }


numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


}
