import 'datatables.net';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';
import { LoaderService } from '../services/loader/loader.service';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';

@Component({
  selector: 'app-stress-management',
  templateUrl: './stress-management.component.html',
  styleUrls: ['./stress-management.component.css']
})
export class StressManagementComponent implements OnInit {
  allExercise;
  soundId;
  public showLoader: boolean = true;
  songs = []
  apiBaseUrl: string = environment.url;

  constructor(private http: HttpClient, private snackbar: SnackbarService, private router: Router, public loaderService: LoaderService, private ngxBootstrapConfirmService: NgxBootstrapConfirmService,) { }

  ngOnInit() {

    this.adminLogin();
    $(document).ready(function () {
      $('#example').DataTable();
    });
  }

  adminLogin() {
    this.showLoader = true;
    this.http.get(this.apiBaseUrl + 'v1/getAllSongs').subscribe((data: any) => {

      if (data.status == 200) {
        this.showLoader = false;
        this.allExercise = data.data;
        $(document).ready(function () {
          $('#example').DataTable();
        });
      }
      else {
        this.allExercise = '';
      }
    })
  }


  openAddSoundPage = () => {

    this.router.navigateByUrl('/add-sound')
  }


  deleteSound(id: string, index: number) {
    // alert(imageId);
    let options = {
      title: 'Are you sure you want to remove this Sound?',
      confirmLabel: 'Yes',
      declineLabel: 'No'
    }
    this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
      if (res) {
        this.soundId = { "soundId": id };
        this.http.post(this.apiBaseUrl + 'v1/deleteSound', this.soundId).subscribe((data: any) => {

          //  window.location.reload();
          this.snackbar.successSnackBar("Sound deleted successfully.", '');
          window.location.reload();
          this.adminLogin()

        })

      }
    }
    );
  }


  // deleteSound(id){
  //   const confirm = window.confirm("Are you sure you want to delete this blog");
  //   if(confirm){
  //     this.soundId ={"soundId":id};
  //     this.http.post(this.apiBaseUrl+'v1/deleteSound',this.soundId).subscribe((data : any) => {
  //       const index =  this.findDeleteBlogIndex(id);
  //      // console.log(index);
  //       this.songs.splice(index,1);
  //       $(document).ready(function() {
  //         $('#example').DataTable();
  //         } );
  //   })
  //   }

  // }

  // findDeleteBlogIndex(Id : string){
  //   let index = 0;
  //   for (index = 0; index < this.songs.length; index++) {
  //        if(Id === this.songs[index]._id)
  //             break;
  //   }
  //   return index;
  // }


}


