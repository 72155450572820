<div class="row">
    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-border">
            <div class="card-body">
                <h2 class="card-title">Home Images</h2>
                <div class="form-group existing-image-area" *ngFor="let image of desktopImages; let i = index">
                    <label>  {{ image ? 'Section '+(i+1) : 'Select Image' }} :</label>
                    <div>
                    <img *ngIf="image" [src]="image.image" height="50px" width="50px" >
                    <label [class.text-danger]="fileInvalid[i]">*The image dimensions must be {{ desktopResolutions[i]?.height +'x'+ desktopResolutions[i]?.width }} pixels.</label>
                </div>
                    <div class="home-images">
                        <div class="update-btn">
                            <button type="button" class="btn btn-submit">
                                <i class="fa fa-upload mr-2" aria-hidden="true"></i>
                                Upload
                            </button>
                            <input type="file" [id]="'file'+i" accept="image/*" (change)="onFileChange($event,i,'DESKTOP')" class="cursor-pointer form-control mr-2"  [ngClass]="{ 'is-invalid': added && fileInvalid[i]}" >
                        </div>  
                        <div class="save-btn" *ngIf="selectedImages[i]">
                          <button type="button" class="btn btn-submit ml-2" (click)="addImage(image,i,'DESKTOP')">{{ image ? 'Update' : 'Add' }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-border">
            <div class="card-body">
                <h2 class="card-title">Mobile Web View Images</h2>
                <div class="form-group existing-image-area" *ngFor="let image of mobileImages; let i = index">
                    <label>  {{ image ? 'Section '+(i+1) : 'Select Image' }} :</label>
                    <div>
                    <img *ngIf="image" [src]=" image.image" height="50px" width="50px" >
                    <label [class.text-danger]="mobileInvalid[i]">*The image dimensions must be {{ mobileResolution[0].height +'x'+ mobileResolution[0].width }} pixels.</label>
                </div>
                    <div class="home-images">
                        <div class="update-btn">
                            <button type="button" class="btn btn-submit">
                                <i class="fa fa-upload mr-2" aria-hidden="true"></i>
                                Upload
                            </button>
                            <input type="file" [id]="'file'+i" accept="image/*" (change)="onFileChange($event,i,'MOBILE')" class="cursor-pointer form-control mr-2"  [ngClass]="{ 'is-invalid': added && fileInvalid[i]}" >
                        </div>  
                        <div class="save-btn" *ngIf="selectMobileImages[i]">
                          <button type="button" class="btn btn-submit ml-2" (click)="addImage(image,i,'MOBILE')">{{ image ? 'Update' : 'Add' }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>