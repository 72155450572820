import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ShopAndProductService } from '../service/shop-and-product.service';

@Component({
  selector: 'app-product-under-category',
  templateUrl: './product-under-category.component.html',
  styleUrls: ['./product-under-category.component.css']
})
export class ProductUnderCategoryComponent implements OnInit {

  products : any[] = [];

  route : Routes = new Routes();
  category_name;
  categoryname;
  constructor(private _activateRoute : ActivatedRoute,
    private snackbar:SnackbarService,
    private _router : Router,
     private _shopAndProductService : ShopAndProductService,
     private ngxBootstrapConfirmService: NgxBootstrapConfirmService) { }

  ngOnInit(): void {
   // console.log(this._activateRoute.snapshot.paramMap)
     this.getProductsByCategoryId(this._activateRoute.snapshot.paramMap.get('categoryId'));
     this.categoryname = this._activateRoute.snapshot.paramMap.get('name')
  }

 getProductsByCategoryId(categoryId : string){
   this._shopAndProductService.getProductByCategory(categoryId).subscribe((resp : any) =>{
        // console.log(resp);
         if(resp.status == 200){
           this.products = resp.data;
           console.log( this.products)

           if (resp.data && resp.data.length>0){
             console.log("helllo")
           this.category_name = resp.data[0].category.name
           }
         }
         $(document).ready(function() {
          $('#example').DataTable();
          });
   })

 }

 deleteProduct(productId : string){
  let options ={
    title: 'Sure you want to delete this Product?',
    confirmLabel: 'Yes',
    declineLabel: 'No'
  }
  this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
    if (res) {
      this.deleteProductApiCall(productId);
    }
  });
}

deleteProductApiCall(productId : string){
   this._shopAndProductService.deleteProduct(productId).subscribe((resp : any) =>{
       if(resp.status == 200){
           console.log(resp);
           this.snackbar.successSnackBar("Successfully deleted product", '');

           this.getProductsByCategoryId(this._activateRoute.snapshot.paramMap.get('categoryId'));
       }
   })
}

updateProduct(productId : string){
    this._router.navigate([this.route.updateProduct,productId]);
}

}
