import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {


  formdata;
  firstName;
  lastName;
  email;
  password;
  image;
  submitted;
  formdetail;
  invalid: boolean = false;
  apiBaseUrl: string = environment.url;

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.formdata = new FormGroup({

      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required])

    });

  }

  get f() { return this.formdata.controls; }

  addUser(data) {
    this.submitted = true;
    if (this.formdata.invalid) {
      return;
    }
    this.formdetail = {
      'firstName': data.firstName,
      'lastName': data.lastName,
      'email': data.email,
      'password': data.password
    }

    this.http.post(this.apiBaseUrl + 'v1/addUser', this.formdetail).subscribe((data: any) => {

      if (data.status == 200) {
        alert("Successfully added!")
        this.router.navigate(['/all-users']);
      }
      else {
        this.invalid = true;
      }
    })
  }
}