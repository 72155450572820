import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { QuizService } from 'src/app/services/quiz.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-update-quiz',
  templateUrl: './update-quiz.component.html',
  styleUrls: ['./update-quiz.component.css']
})
export class UpdateQuizComponent implements OnInit {

  quizId : string = undefined;
  showLoader : boolean = false;
  submitted : boolean = false;
  fileTypes = ["image/jpeg","image/png","image/jpg","image/tiff"];
  answerTypes : string[] = ['text', 'image'];
  quizById : any = undefined;
  images:any[]=[]
  API_URL = environment.url
  quiz : FormGroup;
  chosenAnswer:boolean=false

  currentQuestionIndex : number = 0;

  quizFromAPI : any = undefined;

  constructor(private _router : Router, private _routerSnapshot : ActivatedRoute,
     private _quizService : QuizService, private _fb : FormBuilder) {
       this.quizId =  this._routerSnapshot.snapshot.paramMap.get('id');
       this.quiz = this._fb.group({
         name : ['', Validators.required],
         questions : this._fb.array([], [Validators.required, Validators.maxLength(10)])
       });
   }

  ngOnInit(): void {
       this.getQuizById(this.quizId);
  }

  getQuizById(quizId : string){
       this._quizService.getQuizById(quizId).subscribe((response : any) => {
          const quiz = response.data;
          this.quizFromAPI = quiz;
          this.quiz.controls.name.setValue(quiz.name);
          const questions = quiz.questions; 

          var questionIndex = -1;
          questions.forEach(ques => {
               questionIndex = questionIndex+1;
               const answerType = ques.answers[0].type;
               ques.answertype = answerType;
               this.addQuestion(ques);
               const answers = ques.answers;
               this.images = answers
               answers.forEach(answer => {
                  this.addAnswer(answer,questionIndex);
               });
          });
       });
  }

  get questions(){
     return this.quiz.get('questions') as FormArray
  }

  addQuestion(ques : any){
      const question = this._fb.group({
         title : [ques!==undefined ? ques.title : '', Validators.required],
         answertype : [ques!==undefined ? ques.answertype : this.answerTypes[0], Validators.required],
         answers : this._fb.array([], [Validators.required, Validators.minLength(2), Validators.maxLength(4)])
      });
      this.questions.push(question);
      this.currentQuestionIndex= this.questions.length-1;

  }

   answers(questionIndex : number){
     return this.questions.controls[questionIndex].get('answers') as FormArray;
  }

  addAnswer(answer : any, questionIndex : number){

     let answerGroup = null;
     if(answer!==undefined){
        answerGroup =  this._fb.group({
          type : [answer.type, Validators.required],
          value : [answer.value, Validators.required],
          isRight : [answer.isRight, Validators.required]
       });
     }else{

      answerGroup =  this._fb.group({
       type : [this.questions.controls[questionIndex].get('answertype').value, Validators.required],
        value : ['', Validators.required],
        isRight : [false, Validators.required]
     });
     }
     const questionFormArray = this.answers(questionIndex);
     questionFormArray.push(answerGroup);
  }

  get f(){
     return this.quiz.controls;
  }

  answerTypeChange(event : any, questionIndex : number, answerIndex : number){
    this.answers(questionIndex).controls[answerIndex]
    .get('type').setValue(event.target.value);
    this.answers(questionIndex).controls[answerIndex]
    .get('value').setValue('');
 }

 answerTypeChangeFromQuestion(event : any, questionIndex : number){
  const answerType = event.target.value;
  this.answers(questionIndex).controls.forEach(control=>{
      control.get('type').setValue(answerType);
      control.get('isRight').setValue(false);
      control.get('value').setValue('');
  })
}

 getAnswerType(questionIndex : number, answerIndex : number){
   return this.answers(questionIndex).controls[answerIndex]
   .get('type').value;
 }

 async onFileChange(event : any, questionIndex : number, answerIndex : number) {
  const reader = new FileReader();
  
  if(event.target.files && event.target.files.length) {
    const [file] = event.target.files;
    reader.readAsDataURL(file);
   const fileType=file.type;
  if(this.fileTypes.includes(fileType)){
    reader.onload = (even: any) => {
      this.answers(questionIndex).controls[answerIndex].get('value').setValue(even.target.result)  
    }
      if(confirm("Do you really want to upload this file")){
          const formData = new FormData();
          formData.append("media", this.modifyFile(file));
          this._quizService.quizAnswerImage(formData).subscribe((res:any) => {
            this.answers(questionIndex).controls[answerIndex].get("value").setValue(res.data);
          });
      }
    } else {
      alert("only "+this.fileTypes.toString()+" format supports");
    }
  }
}

modifyFile(originalFile:File){
  // Original file name
  const originalFileName = originalFile.name;

  // Extract the first 5 characters of the original filename
  const first5Characters = originalFileName.slice(0, 5);

  // Determine the original file extension
  const originalExtension = originalFileName.split('.').pop();

  // Default extension if the original extension is not known
  const defaultExtension = 'png';

  // Create a new file name with the first 5 characters and default extension
  const newFileName = first5Characters + '.' + (originalExtension || defaultExtension);

  // Create a new Blob with the same content and a different name
  const modifiedFile = new File([originalFile], newFileName, { type: originalFile.type });

  return modifiedFile;
}

removeQuestion(questionIndex : number){
    this.questions.removeAt(questionIndex);

}

removeAnswer(questionIndex : number, answerIndex : number){
  this.answers(questionIndex).removeAt(answerIndex);
}

isAlreadyAnswerSelected(questionIndex : number){

  this.answers(questionIndex).controls.forEach(answer =>{
     const value =  answer.get('isRight').value;
     if(value){
      answer.get('isRight').setValue(false);
     }
  })
}

  updateQuiz(){
    const quiz = this.quiz.value;
    this.submitted = true;

    if(this.quiz.valid){
        const questionIndex = this._quizService.isNoAnswerSelectedAsCorrect(quiz);
        if(questionIndex===-1){
        this._quizService.updateQuiz(quiz, this.quizId).subscribe((response : any)=>{
             if(response.status==200)
                  this._router.navigate(['quiz'])
        }); }
        else {
           this.currentQuestionIndex = questionIndex;
           alert("Question doesn't have any correct answer");
        }
    }else{
     const questionIndexAndAnswerLength = this._quizService.renderOnErrorQuestion(quiz,this.currentQuestionIndex);
     this.currentQuestionIndex = questionIndexAndAnswerLength.currentQuestionIndex ;

     if(this.questions.length===0){
            this.addQuestion(undefined)
     } 
     if (this.answers(this.currentQuestionIndex)?.errors?.minlength || this.answers(this.currentQuestionIndex)?.errors?.required) {
         this.addAnswer(undefined,this.currentQuestionIndex)
     }     
    }
  }

}
