<!-- The Modal -->
<div class="modal" id="myModal" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content delete-modalclass">







    </div>
  </div>
</div>
<!-- The Modal -->





<button type="button" class="btn btn-success" (click)="openAddExercisePage()" style="margin-bottom: 30px;">Add
  Exercise</button>


<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="card card-border">
      <div class="card-body" id="example_wrapper">
        <table id="example" *ngIf="allExercise" datatable class="display">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Exercise Name</th>
              <th>Time Duration</th>
              <th>Created Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let exercise of allExercise, let i=index">
              <td>{{i+1}}</td>
              <td>{{exercise.exerciseName}}</td>
              <td>{{exercise.timeDuration}}</td>
              <td>{{exercise.createdOn | date}}</td>
              <td>
                <button routerLink="/edit-exercise/{{exercise._id}}" class="btn btn-success mr-2" matTooltip="Edit"> <i
                    class="fa fa-edit"></i></button>
                <button (click)="deleteExercise(exercise._id)" class="btn btn-danger" matTooltip="Delete"> <i
                    class="fa fa-trash" aria-hidden="true"></i></button>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>