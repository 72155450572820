import 'rxjs/add/operator/map';
import { Color } from 'ng-chartjs';
import { Router } from '@angular/router';
import { ChartOptions } from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OrdersService } from '../services/orders.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  isAdminLogin;
  apiBaseUrl: string = environment.url;
  chartOptions = { responsive: true };
  chartLabels = ['Jan', 'Feb', 'Mar', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  chartLegend = true;
  chartPlugins = [];
  chartdata1 = []
  dataStrings = [];
  chartData = [
    { data: this.chartdata1, label: 'Yearly Revenue' },
  ];

  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: '#00c9ff',
    },
  ];
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: { //you're missing this
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Revenue Rate'
        }
      }]
    }//END scales


  };

  constructor(public orderService: OrdersService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.isAdminLogin = sessionStorage.getItem('adminId');

    if (this.isAdminLogin == null) {
      this.router.navigate([''])
    }
  }

  userCount;
  userCountToday;
  userCountDisable;
  productCount;
  products;

  public showLoader: boolean = false;


  ngOnInit() {

    this.showLoader = true;
    this.http.get(this.apiBaseUrl + 'v1/dashboard').subscribe((data: any) => {
      if (data.status == 200) {
        this.showLoader = false;

        this.userCount = data.userCount;
        this.userCountToday = data.userCountToday;
        this.userCountDisable = data.userCountDisable;
        this.productCount = data.productCount
        this.chartdata1 = data.revenue;
        this.dataStrings = this.chartdata1.map(function (value) {
          return String(value);
        });
        this.chartdata1 = this.dataStrings
        this.chartData = [
          { data: this.chartdata1, label: 'Yearly Revenue' },
        ];


        this.getLatestOrders()
      }
    })
  }

  getLatestOrders() {
    this.orderService.getLatestOrders().subscribe((resp: any) => {
      if (resp.status == 200) {

        this.products = resp.data;
        $(document).ready(function () {
          $('#example').DataTable();
        });
      }

    });
  }

}
