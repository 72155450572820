import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BannerService } from '../services/banner.service';
import { AddBannerComponent } from './add-banner/add-banner.component';


@Component({
  selector: 'app-banner-management',
  templateUrl: './banner-management.component.html',
  styleUrls: ['./banner-management.component.css']
})
export class BannerManagementComponent implements OnInit {

  banners : any[] = []

  route : Routes = new Routes();

  constructor(public dialog: MatDialog, private bannerService : BannerService) { }


  ngOnInit(): void {
    this.getAllBanner();

  }

  getAllBanner(){
    this.bannerService.getBanner().subscribe((resp : any)=>{
      if(resp.status ==200){
           this.banners = resp.data;
           $(document).ready(function() {
            $('#example').DataTable();
            });
      }
    })
  }

  addBanner(){
    const dialogRef = this.dialog.open(AddBannerComponent, {
      width: '350px',
    
     // position : { top : '50px'}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result==='success'){
          this.getAllBanner();
       }
     
    });
       
  }

  deleteBanner(categoryId : string){
    const confirm = window.confirm("Are you sure want to delete this category");
    if(confirm){
        this.bannerService.deleteBanner(categoryId).subscribe((resp : any) =>{
             if(resp.status == 200)
                 this.getAllBanner();
        })
    }
}


}

