import { Messages } from '../shared/messages';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';
import { TestimonialService } from '../services/testimonials.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  type: string
  title: string
  added = false
  selectedType:string
  selectedFile: File
  fileInvalid = false;
  Messages = Messages
  testimonialList: any[] = []
  baseUrl: string = environment.url;
  @ViewChild('video') video: ElementRef;

  constructor(
    private testimonialService: TestimonialService,
    private snackbarService: SnackbarService) { }

  ngOnInit(): void {
    this.selectedType = "Home Page"
    this.type = "Home Page"
    this.getAll();
  }

  addStats() {
    this.added = true;
    if (this.fileInvalid || !this.selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append('image', this.selectedFile);
    formData.append('type', this.type);
    formData.append('title', this.title);
    this.testimonialService.add(formData).subscribe((response: any) => {
      this.snackbarService.alertWithConfirmationsWithOutCancel("Testimonial successfully updated");
      setTimeout(() =>{
        this.clear()
      },2000)
    
    })
  }

  clear() {
    this.type ="Home Page";
    this.selectedType = "Home Page"
    this.added = false
    this.title = ''
    this.selectedFile = null;
    this.video.nativeElement.value = ''
    this.getAll()
  }

  // onFileChange(event) {
  //   if (event.target.files && event.target.files[0].type.includes('video')) {
  //     const videoElement = document.createElement('video');
  //     videoElement.src = URL.createObjectURL(event.target.files[0]);
  //     videoElement.preload = 'metadata';
  //     videoElement.onloadedmetadata = () => {

  //       const durationInSeconds = videoElement.duration;
  //       if (durationInSeconds > 660) {
  //         this.snackbarService.alert('Video duration should be less than 11 minutes.')
  //         this.selectedFile = null;
  //         this.video.nativeElement.value = ''
  //         return;
  //       }
  //       this.fileInvalid = false
  //       this.selectedFile = event.target.files[0];
  //     }
  //   }
  //   else {
  //     this.fileInvalid = true;
  //     this.selectedFile = null;
  //     this.video.nativeElement.value = ''
  //   }
  // }

  onFileChange(event) {
    const maxFileSizeMB = 60;
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024; // Convert MB to bytes
  
    if (event.target.files && event.target.files[0].type.includes('video')) {
      const file = event.target.files[0];
  
      if (file.size > maxFileSizeBytes) {
        this.snackbarService.alert(`File size should be less than ${maxFileSizeMB} MB.`);
  
        this.selectedFile = null;
        this.video.nativeElement.value = '';
        return;
      }
  
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);
      videoElement.preload = 'metadata';
      videoElement.onloadedmetadata = () => {
        const durationInSeconds = videoElement.duration;
        if (durationInSeconds > 660) {
          this.snackbarService.alert('Video duration should be less than 11 minutes.'
          );
  
          this.selectedFile = null;
          this.video.nativeElement.value = '';
          return;
        }
  
        this.fileInvalid = false;
        this.selectedFile = file;
      };
    } else {
      this.fileInvalid = true;
      this.selectedFile = null;
      this.video.nativeElement.value = '';
    }
  }

  getAll() {
    this.testimonialService.getAll().subscribe((response: any) => {
      this.testimonialList = []
      this.testimonialList = response.getTestimonial.filter(item => item.type == this.selectedType)
    })
  }

  delete(id) {
    this.snackbarService.alertWithConfirmations("Are you sure want to delete this testimonial?").then((result) => {
      if (result.isConfirmed) {
        this.testimonialService.deleteTestimonial(id).subscribe(response => {
          this.getAll()
        })
      }
    })
  }

  space(event) {
    if (event.target.value?.trim() == "") {
      this.title = ''
    }
  }
}
