import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { catchError, max } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Component, OnInit ,EventEmitter, Output,} from '@angular/core';
import { ELearningService } from 'src/app/services/eLearning/e-learning.service';
import { noWhitespaceValidator } from 'src/app/_validators/noWhitespaceValidator';


@Component({
  selector: 'app-update-elearning-video',
  templateUrl: './update-elearning-video.component.html',
  styleUrls: ['./update-elearning-video.component.css']
})
export class UpdateElearningVideoComponent implements OnInit {

  @Output() success = new EventEmitter<boolean>();

  title : string = 'Add Video';
  error;

  videoForm : FormGroup;
  showLoader : boolean = true;

  videos ;
  captions;

  file : any = undefined;
  file1 : any = undefined;

  categories : any[] = [];
  submitted;
  duration;
  fileExtensionError: boolean = false;
  category_name;
  filesize;
  categoryId
  videoId


  constructor(private loaderService: LoaderService,
    private elearningService:ELearningService,private _fb : FormBuilder,private router: Router,private route: ActivatedRoute,
    private _activateRoute : ActivatedRoute,

    ) { 

  }

  ngOnInit(): void {
    this.videoId = this._activateRoute.snapshot.paramMap.get('id');
    this.getvideoDetailById(this.videoId);

    this.getAllCategories();
    this.loaderService.loadingOn()

    this.videoForm = this._fb.group({
      category_id : [ '',Validators.required],
      videos : ['', Validators.required],
      captions:[''],
      title:['',[Validators.required,noWhitespaceValidator, Validators.maxLength(35)]]
    })
  }

  get f(){
     return this.videoForm.controls;
  }


  getvideoDetailById(videoId : string){
    this.elearningService.getvideoDetailId(videoId).subscribe((resp : any) =>{
         if(resp.status == 200){
             const product = resp.data;
             this.videos = product.image;

             console.log(this.videos,"uuuuuuuuuuuuuuuuuuuuuuuuu")

             this.videoForm.controls.product_id.setValue(product.product_id);
             this.videoForm.controls.off_percent.setValue(product.off_percent);
            //  this.OfferForm.controls.image.setValue('');

            
            }
    } )
 } 

   onFileChange($event) {
    const reader = new FileReader();
    
    
    if($event.target.files && $event.target.files.length) {
      const [file] = $event.target.files;
      console.log(file.duration,"dhjghjsdjsjjs")
      reader.readAsDataURL(file);
      this.file = file;
      console.log(file.size/1024/1024 + ' MB',"size")
      this.filesize = file.size/1024/1024
      if( this.filesize > 50){
        alert("Please provide video with less then 50 Mb.");
        return;
       }
      
      reader.onload = () => {
      this.videos = reader.result ;
   
      };
    }
  }
  getDuration(e) {
    console.log(e,"eeeeeeeeeeeeeeeeeeeeeeeeeeee")
    this.duration = e.target.duration;
    console.log(this.duration,"duration")
  }

  onCaptionChange(event : any) {
    this.file1 = event.target.files[0];
    var res = this.file1['name'].match('.vtt');
    if (res != '.vtt') {
      alert("Please provide caption in .vtt format");
      return;

    }
    else{
    let reader = new FileReader();
    reader.onload = (e) => {
      this.captions = reader.result;
    };
    reader.readAsDataURL(this.file1);
  }
  }
  
  

  getAllCategories(){
    this.elearningService.getCategories().subscribe((resp : any)=>{
      if(resp.status ==200){
           this.categories = resp.data;
           console.log(this.categories,"this.categories")
           if(this.categories.length > 0) 
                   this.videoForm.controls.category_id.setValue(this.categoryId)       
      }
    })
  }


  submit(){
    this.submitted = true;
    let form = new FormData();  
    this.filesize = this.file.size/1024/1024
    if( this.filesize > 50){
      alert("Please provide video with less then 50 Mb.");
      return;
     }  
    if(this.videoForm.invalid){
      return;

     }
     if (this.file1 !=undefined){
     var res = this.file1['name'].match('.vtt');
     if (res != '.vtt') {
       alert("Please provide caption in .vtt format");
       return;
     }
     
    }
      console.log(this.duration,"llllllllllllllllll")
      form.append('videos', this.file);
      form.append('captions', this.file1);
      form.append('category_id', this.videoForm.value.category_id);
      form.append('title', this.videoForm.value.title);
      form.append('duration',this.duration);
      let activity$ = this.elearningService.AddVideo(form).pipe(
        catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );



    this.loaderService.showLoader(activity$).subscribe(
      (res: any) => {
        this.success.emit(true);
        this.router.navigate(['/e-learning/videos/',this.videoForm.value.category_id ]);

      },
      (err) => {
        this.error = err.error.message;
      }
    );
  }

}
