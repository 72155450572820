import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BannerService {

  apiBaseUrl = environment.url;

  constructor(private _http : HttpClient) { }

  getBanner(){
    return this._http.get(this.apiBaseUrl+'v1/banners');
  }

  addBanner(data:any){
    return this._http.post(this.apiBaseUrl+'v1/banners',data);
  }

  deleteBanner(id:any){
    return this._http.delete(this.apiBaseUrl+'v1/banners',id);
  }

}
