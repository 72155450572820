import { Router } from '@angular/router';
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css']
})
export class SnackbarComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,private _router:Router,private _matDialog:MatDialogRef<any>) { }

  ngOnInit(): void {
    if(this.data.autoClose) {
      setTimeout(() => {
        this.closeDialog();
      }, 5000);
    }
  }

  closeDialog() {
    this._matDialog.close();
    this._matDialog.afterClosed().subscribe(result => {
      if (this.data.router !== '')
        this._router.navigate([this.data.router]);
    });
  }

  closeSnackBar() {
    this.data.snackbar.dismiss();
  }
}
