export class Pagination {
    
    constructor(page:any) {
        this.page=page  
    }
    currentIndex=0
    size:any
    page:number
    total?:number
}
