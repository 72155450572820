import { Component, OnInit,Input,AfterViewInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { noWhitespaceValidator } from 'src/app/_validators/noWhitespaceValidator';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@syncfusion/ej2-base';
import { OrdersComponent } from '../../orders/orders.component';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {

  submitted : boolean = false;
  categoryname;
  currentColorIndex: number = -1;
  productForm : FormGroup;
  product;
  numbers = ["0","1","2","3","4","5","6","7","8","9","."];
  user;
  total_refund_amount
  total_percent
  total_payable_amount


  constructor(private _orderService : OrdersService, private _fb : FormBuilder,private router: Router,private _activateRoute : ActivatedRoute, private ngxBootstrapConfirmService: NgxBootstrapConfirmService) { }

  ngOnInit(): void {

    this.getProductDetailById(this._activateRoute.snapshot.paramMap.get('id'))
     this.productForm = this._fb.group({
      total_amount : ['', Validators.required],
      percent : ['', [Validators.required]],
      refund : ['', [Validators.required]],
      
     });
  }

  get f(){
    return this.productForm.controls;
  }
  
  getProductDetailById(productId : string){
    this._orderService.getOrderDetail(productId).subscribe((resp : any) =>{
         if(resp.status == 200){
            this.product = resp.data.price;
            var total_percent = resp.data.tax
            this.total_percent = (total_percent / 100) * this.product;   
            this.total_payable_amount = ((this.product + this.total_percent + resp.data.shipping_charge)).toFixed(2)
            this.productForm.controls.total_amount.setValue(this.total_payable_amount);
            this.user = resp.data.user        
         }
    })
 }
 

 onlyNumber(event : KeyboardEvent){
  const key = event.key;
  const percent = this.productForm.controls.percent.value;
  if(key==='.')
     return String(percent).indexOf(key) > -1 ? false : true;

  return this.numbers.includes(key);
}


total_refund(event : KeyboardEvent){
  var number= this.total_payable_amount
  var total_percent = this.productForm.value.percent
  var percent = (total_percent / 100) * number;
  this.total_refund_amount = ((number - percent)).toFixed(2)
  // console.log("dkjkj",total_refund_amount)
  this.productForm.controls.refund.setValue(this.total_refund_amount);

 }

test(){
  if(this.productForm.valid){
   const product =  {
     "user": this.user,
     "amount":this.total_refund_amount
    }
    console.log(product)
   let options ={
    title: 'Are you sure you want to intiate refund of this Order?',
    confirmLabel: 'Yes',
    declineLabel: 'No'
  }
  this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
    if (res) {
      this._orderService.getRefund(this._activateRoute.snapshot.paramMap.get('id'),product).subscribe((resp : any) =>{
        if(resp.status == 200){
            console.log(resp);
            this.router.navigate(['/order/past']);
            alert("Refund has been intiated successfully ")

        }
    })
  }
  });
}
   
}


}

