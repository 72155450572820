import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ChargesService {

  apiBaseUrl : string = environment.url;

  constructor(private _http : HttpClient) { }

  getCharges(){
    return this._http.get(this.apiBaseUrl+'v1/charges/tax');
 }

 deleteChargesById(quizId : string){
  return this._http.delete(this.apiBaseUrl+'v1/quiz/'+quizId);
}
updateCharge(data:any,){
  return this._http.put(this.apiBaseUrl+'v1/charges/tax',data);
}
}
