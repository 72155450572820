import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any[], filter : string): any[] {
    if(filter==='')
       return value;
    else
       return value.filter( val => val.medName.toLowerCase().includes(filter));
  }

}
