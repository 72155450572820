import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home.service';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  added: boolean = false
  fileInvalid: boolean[]
  mobileInvalid: boolean[]
  baseUrl: string = environment.url;
  selectedImages = new Array()
  selectMobileImages = new Array()
  desktopImages = new Array()
  mobileImages = new Array()

  desktopResolutions = [{ height: 1363, width: 1440 }, { height: 1409, width: 1440 }, { height: 1243, width: 1440 }]
  mobileResolution = [{ width: 480, height: 750 }]


  constructor(private homeService: HomeService, private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.desktopImages = new Array(3).fill(null);
    this.mobileImages = new Array(3).fill(null);
    this.fileInvalid = new Array(3).fill(null);
    this.mobileInvalid = new Array(3).fill(null);
    this.getAll();
  }

  addImage(image, index, type) {
    this.added = true
    if (this.selectedImages[index] || this.selectMobileImages[index]) {
      const formData = new FormData();
      let file;
      this.selectedImages[index] ? file = this.selectedImages[index] : file = this.selectMobileImages[index]
      formData.append('image', file);
      formData.append('type', type)

      if (image && type == "DESKTOP") {
        this.homeService.edit(image._id, formData).subscribe((response: any) => {
          this.desktopImages[index] = ({ _id: response.updateHomeImage._id, image: response.updateHomeImage.image })

          this.snackbar.successSnackBar("Image updated successfully!", '')
        })

      } else if (image && type == "MOBILE") {
        this.homeService.edit(image._id, formData).subscribe((response: any) => {
          this.mobileImages[index] = ({ _id: response.updateHomeImage._id, image: response.updateHomeImage.image })

          this.snackbar.successSnackBar("Image updated successfully!", '')
        })
      }
      else {
        this.homeService.add(formData).subscribe((response: any) => {
          if (type == "DESKTOP") {
            this.desktopImages[index] = ({ _id: response.addHomepage._id, image: response.addHomepage.image })
          }
          else {
            this.mobileImages[index] = ({ _id: response.addHomepage._id, image: response.addHomepage.image })
          }

          document.getElementById("file" + index)['value'] = ''

          this.snackbar.successSnackBar("Image added successfully!", '')

        })

      }
      file = null
      type == "DESKTOP" ? this.selectedImages[index] = null : this.selectMobileImages[index] = null
      type == "DESKTOP" ? this.fileInvalid[index] = false : this.mobileInvalid[index] = false

    } else {
      type == "DESKTOP" ? this.fileInvalid[index] = true : this.mobileInvalid[index] = true
    }
  }

  onFileChange(event, index, type) {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;

          if (type == 'DESKTOP' && (height < this.desktopResolutions[index].height || width < this.desktopResolutions[index].width)) {
            this.fileInvalid[index] = true
            this.selectedImages[index] = null
          }
          if (type == 'MOBILE' && (height < this.mobileResolution[0].height || width < this.mobileResolution[0].width)) {
            this.mobileInvalid[index] = true
            this.selectMobileImages[index] = null
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);

      type == "DESKTOP" ? this.fileInvalid[index] = false : this.mobileInvalid[index] = false
      type == "DESKTOP" ? this.selectedImages[index] = file : this.selectMobileImages[index] = file

    } else {
      type == "DESKTOP" ? this.fileInvalid[index] = true : this.mobileInvalid[index] = true
      type == "DESKTOP" ? this.selectedImages[index] = null : this.selectMobileImages[index] = null
    }
  }

  getAll() {
    this.homeService.getAll().subscribe((response: any) => {
      let desktop = response.homeData?.DESKTOP;
      let mobile = response.homeData?.MOBILE;
      for (let i = 0; i < desktop.length; i++) {
        this.desktopImages[i] = desktop[i]
      }
      for (let i = 0; i < mobile.length; i++) {
        this.mobileImages[i] = mobile[i]
      }
    })
  }

}
