
          <div class="row">
          
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 dash-card-spacing">
                        <div class="card user card-hover">
                                <div class="card-body">
                                    
                                        <div class="card-text pt-2 mr-4">
                                            <h3 class="mb-0">{{userCount}}</h3>
                                            <small>Total Users</small>
                                        </div>
               
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 dash-card-spacing">
                            <div class="card Business card-hover">
                                <div class="card-body">

                                        <div class="card-text pt-2 mr-4">
                                            <h3 class="mb-0">{{userCountToday}}</h3>
                                            <small>Joined Today</small>
                                        </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 dash-card-spacing">
                            <div class="card Product card-hover">
                                <div class="card-body">

                                        <div class="card-text pt-2 mr-4">
                                            <h3 class="mb-0">{{userCountDisable}}</h3>
                                            <small>Total Deactivated Users</small>
                                        </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 dash-card-spacing">
                            <div class="card Product card-hover">
                                <div class="card-body">

                                        <div class="card-text pt-2 mr-4">
                                            <h3 class="mb-0">{{productCount}}</h3>
                                            <small>Total Products</small>
                                        </div>

                                </div>
                            </div>
                        </div>


                    <div class="col-md-12">
                        <h3 class="order-title">Latest Orders</h3>
                    </div>
                    <div class="col-md-12">
                    <div class="latest-orders">
                        <table  datatable class="display" id="customers">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Order Id</th>
                                    <th>Product Id</th>
                                    <!-- <th>Category Name</th>  -->
                                    <th>Product Name</th>
                                    <th>Price(one time)</th>
                                </tr>
                            </thead>
                           <tbody>
                             <tr *ngFor="let product of products; let i = index">
                                   <td>{{i+1}}</td>
                                   <td><a routerLink="/order/active/order_detail/{{ product._id }}">{{ product._id }}</a></td>
                                   <td>{{ product.product._id }}</td>
                                   <!-- <td>{{product.product.category.name}}</td>  -->
                                   <td>{{product.product.name}}</td> 
                                   <td>{{ ((((product.tax) / 100) * (product.price)) + (product.price) *(product.qty) + (product.shipping_charge))  | number:'1.2-2' }}</td>
                                    
                               </tr>
                              
                           </tbody>
                          </table>
                          <p class="text-right">
                          <button  class="btn btn-submit mt-3" routerLink="/order/active">See More</button></p>

                        </div>
                    </div>
                    


                    
                    <div class="col-md-12">
                        <h3 class="revenue-title">Revenue</h3>
                    </div>
                    <div style="display: block;height: 266px;width: 620px;">
                        <canvas baseChart 
                          chartType="bar"
                          [datasets]="chartData"
                          [labels]="chartLabels"
                          [options]="barChartOptions"
                          [plugins]="chartPlugins"
                          [colors]="lineChartColors"
                          [legend]="chartLegend">

                        </canvas>
                      </div>

                      
                      
        </div>
     