import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmailService } from 'src/app/services/email.service';
import { SnackbarService } from 'src/app/services/snackbar.service';


@Component({
  selector: 'app-view-sent-email',
  templateUrl: './view-sent-email.component.html',
  styleUrls: ['./view-sent-email.component.css']
})
export class ViewSentEmailComponent implements OnInit {

  userId:any
  emails:any[]
  replies:any[]
  baseURL=environment.url

  constructor(
    private emailService:EmailService,
    private activatedRoute:ActivatedRoute,
    private snackbarService:SnackbarService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(data => {
      if(data['userId']) {
        this.userId=data['userId']
        this.getUsersSentEmail()
      } else {
        this.getAllSentEmail();
      }
    })
  }

  getAllSentEmail() {
    this.emailService.getAllSentEmails().subscribe((response:any) => {
      this.emails=response.data
      $(document).ready(function () { $('#example').DataTable(); });
    })
  }

  getUsersSentEmail() {
    this.emailService.getUsersSentEmails(this.userId).subscribe((response:any) => {
      this.replies=response.getMassMails
      $(document).ready(function () { $('#example').DataTable(); });
    })
  }

  showContent(content) {
    this.snackbarService.show(content,'')
  }

  currentGroupEmails:string[]
  showEmails(groupedData:any) {
    this.currentGroupEmails=groupedData.emails.map(element => `${element.user}`)
  }
  
  deleteMail(notificationArray:any) {
    const isArray=Array.isArray(notificationArray)
    if(isArray) {
      const ids=notificationArray.map(notification => notification._id)
      this.emailService.deleteEmail(ids).subscribe((response) =>{
        this.getAllSentEmail()
      })
    } else {
      const id=[ notificationArray ]
      this.emailService.deleteEmail(id).subscribe((response) =>{
        this.getUsersSentEmail()
      })
    }
    
  }
}
