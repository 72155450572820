import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MedicationService {

  apiBaseUrl = environment.url;

  constructor(private _http : HttpClient, private _authService : AuthService) { }

  getMedications(){
     return this._http.get(this.apiBaseUrl+'v1/medication?userId='+this._authService.getAdminId());
  }

  addMedication(medicationName : string){
      return this._http.post(this.apiBaseUrl+'v1/medication',{medName : medicationName, userId : this._authService.getAdminId()});
  }

  updateMedication(medicationName : string, medicationId : string){
       return this._http.put(this.apiBaseUrl+'v1/medication/'+medicationId, {medName : medicationName});
  }

  deleteMedication(medicationId : string){
     return this._http.delete(this.apiBaseUrl+'v1/medication/'+medicationId);
  }

}
