import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader/loader.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {

  constructor(
    private authService:AuthService,
    private _loader : LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   // alert("Helllo")
    this._loader.loadingOn();

    let token=this.authService.getToken();
    
    const modifiedRequest = request.clone({
      setHeaders: {
        'Authorization': token,
      }
    });
    
    return next.handle(token ? modifiedRequest : request).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            //  console.log('event--->>>', event);
              this._loader.loadingOff();
          }
          return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.warn(error)
        this._loader.loadingOff();  
          return throwError(error);
      }));
  }
}
