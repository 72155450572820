import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ContactUsService {

    apiBaseUrl: string = environment.url;

    constructor(private _http: HttpClient) { }

    getAll() {
        return this._http.get(environment.url + 'v1/get-contact');
    }
}
