<section class="view_sec">
    <div class="container card">
      <div class="row">
        <div class="col-lg-6 col-md-12  mb-2">
            <div class="swiper-container mySwiper">
                <swiper [config]="config">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" *ngFor="let image of product?.images">
                    <img [src]="apiBaseUrl+image.image" style="height: 400px;width: 100%;object-fit: contain;">
                  </div>
                </div>
                <div class="swiper-pagination"></div>
                </swiper>
              </div>
        </div>
        <div class="details col-lg-6 col-md-12 px-1">
          <h3 class="product-title">{{product?.name}}</h3>
          <p class="product-description">{{product?.description}}</p>
           <h4 class="price mt-3">Category: <span>{{product?.category?.name}}</span></h4>
          <h4 class="price mt-3">Price: <span>${{product?.price}}</span></h4>
          <h4 class="price mt-3">Quantity: <span>{{product?.quantity}}</span></h4>       
          <h4 class="price mt-3">Size: <span>{{product?.size}}</span></h4>   

          <h4 class="price mt-3">Colors: <span *ngFor = "let color of selectedColors">
            <span id = "circle" style="background-color:{{ color }};"></span></span></h4>       


        </div>
      </div>
    </div>
    </section>

   