<!-- <button type="button" class="btn btn-success" (click)="openAddUserPage()" sty>Add User</button> -->
<!-- <angular2csv [data]="data1" filename="test.csv" [options]="options" style="margin-bottom: 80px;margin-left: 73%;color:black;">  
          <button type="button" class="btn btn-secondary"><span class="fa fa-download"></span> Download Template</button>
              </angular2csv> -->
<button (click)="downloadCSV()" class="btn btn-success" style="margin-left: 73%;">Download CSV</button>
<div class="row">

  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="card card-border">


      <div class="card-body" id="example_wrapper">
        <table id="example" *ngIf="allUsers" datatable class="display">
          <thead>
            <tr>
              <th>Sr. No.</th>-
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Age</th>
              <th>Weight</th>
              <th>Height</th>
              <th>BMI</th>
              <th>BP Max</th>
              <th>BP Min</th>
              <th>Cholesterol</th>
              <th>Glucose</th>
              <th>Login Type</th>
              <th>Inactive/Active</th>
              <th>Signup Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of allUsers, let i=index">
              <td>{{i+1}}</td>
              <td>{{user.firstName}}

              </td>
              <td>{{user.lastName}}</td>
              <td>{{user.email}}</td>
              <td>{{user.age}}</td>
              <td>{{user.weight}}</td>
              <td>{{user.height}}</td>
              <td>{{metricData[i].bmi | number:'1.2-2'}}</td>
              <td>{{metricData[i].bloodPressureMax}}</td>
              <td>{{metricData[i].bloodPressureMin}}</td>
              <td>{{metricData[i].cholesterol}}</td>
              <td>{{metricData[i].glucose}}</td>
              <td>{{user.loginType}}</td>
              <td>
                <div *ngIf="user.isActive == '1'; else elseTemplate">
                  <label class="switch">
                    <input type="checkbox" (change)="toggleSwitch($event.target.value, user._id)"
                      value="{{user.isActive}}" [checked]="true">
                    <span class="slider round"></span>
                  </label>
                </div>

                <ng-template #elseTemplate>
                  <label class="switch">
                    <input type="checkbox" (change)="toggleSwitch($event.target.value, user._id)"
                      value="{{user.isActive}}" [checked]="false">
                    <span class="slider round"></span>
                  </label>
                </ng-template>
              </td>
              <td>{{user.signUpDate | date}}</td>
              <td>
               <button (click)="deleteUser(user._id)"  class="btn btn-danger"  matTooltip="Delete"> <i class="fa fa-trash" aria-hidden="true" ></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>