

  <button type="button" class="btn btn-success" routerLink="/e-learning/AddVideos"  style="margin-bottom: 30px;">Add Video</button>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example"   datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Category Name</th>
                      <th>Title</th>
                      <th>Video</th>
                      <th>Action</th>
                  </tr>
              </thead>

             <tbody >

                <tr *ngFor="let video of allVideos; let i = index">
                    <td >{{i+1}}</td>
                    <td>{{video.categoryDetail.name}}</td>
                    <td>{{video.title}}</td>
                    <td><video width="220" height="160" controls (playing)="onPlayingVideo($event)">
                        <source src={{video.url}} type="video/mp4">
                      </video></td>
                      <td>

                        <button matTooltip="Delete" (click)="deleteVideos(video._id)" class="btn btn-danger"> <i class="fa fa-trash"
                            aria-hidden="true"></i></button>
                    </td>
                </tr>
                <!-- <tr class="odd"><td valign="top" colspan="4" class="dataTables_empty">No videos available</td></tr> -->

             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>

