import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ELearningService } from 'src/app/services/eLearning/e-learning.service';

@Component({
  selector: 'app-allvideos',
  templateUrl: './allvideos.component.html',
  styleUrls: ['./allvideos.component.css']
})
export class AllvideosComponent implements OnInit {

  allVideos : any[] = [];
  getcategory;
  SERVER_URL: string = environment.url;
  showLoader : boolean = true;

  categoryId : string = undefined
  currentPlayingVideo: HTMLVideoElement;

  constructor(private _activeRoute : ActivatedRoute, private dialog : MatDialog,    private _router : Router,private elearningService:ELearningService) {}

  ngOnInit(): void {
   // console.log(this._activeRoute.snapshot.paramMap)
    this.categoryId = this._activeRoute.snapshot.paramMap.get('id');
    this.getVideos();
  }



  onPlayingVideo(event) {
    event.preventDefault();
    // play the first video that is chosen by the user
    if (this.currentPlayingVideo === undefined) {
        this.currentPlayingVideo = event.target;
        this.currentPlayingVideo.play();
    } else {
    // if the user plays a new video, pause the last one and play the new one
        if (event.target !== this.currentPlayingVideo) {
            this.currentPlayingVideo.pause();
            this.currentPlayingVideo = event.target;
            this.currentPlayingVideo.play();
        }
    }
}


  getVideos(){
       this.getAllVideos();
  }



  getAllVideos(){
    this.elearningService.getVideos().subscribe((resp : any) =>{
       if(resp.status == 200){
         this.allVideos = resp.data;
         console.log("resp.data==========",resp.data)
       }
       $(document).ready(function() {
        $('#example').DataTable();
        });
 })
  }



  jqueryTable(){
    $(document).ready(function() {
      $('#example').DataTable();
      });
  }

  deleteVideos(videoId:string){
    this.elearningService.deleteVideoById(videoId).subscribe((response : any)=>{

          window.location.reload();

    }, error => this.showLoader = false)
  }


}

