import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopAndProductService {

  apiBaseUrl : string = environment.url;

  constructor(private _http : HttpClient) { }

  addCategory(name : string, image : File,
              isFoodCategory:any, haveSubscription:any) {
      let formData = new FormData();
      formData.append('name', name);
      formData.append('image', image);
      formData.append('isFoodCategory',isFoodCategory);
      formData.append('haveSubscription',haveSubscription);
      
      return this._http.post(`${this.apiBaseUrl}v1/product/category`,formData);
  }

  getProducts(){
    return this._http.get(`${this.apiBaseUrl}v1/product/all/list`);
  }

  getCategories(id : string){
    return this._http.get(`${this.apiBaseUrl}v1/product/category?search=${id}`);
  }

 updateCategory(id : string, name : string, image : File,isFoodCategory:any,haveSubscription:any){
  let formData = new FormData();
  formData.append('name', name);
  formData.append('isFoodCategory',isFoodCategory);
  formData.append('haveSubscription',haveSubscription);

  if(image)
     formData.append('image', image)
  return this._http.put(`${this.apiBaseUrl}v1/product/category/${id}`,formData);
}

deleteCategory(id : string){
  return this._http.delete(`${this.apiBaseUrl}v1/product/category/${id}`);
}

addProduct(product : any){
  let productData = new FormData();
  
  productData.append('name', product.name);
  productData.append('price', product.price);
  productData.append('description', product.description);
  productData.append('category', product.category);
  productData.append('quantity', product.quantity);
  productData.append('size', product.size);
  productData.append('length', product.length);
  productData.append('width', product.width);
  productData.append('height', product.heigth);
  productData.append('weight', product.weigth);

  
  product.images.forEach(image => {
    productData.append('images', image);
  });

  
  var data = product.colors
  var result = data.map(function(val) {
    return val.p_color;
  }).join(',');
  
  
  productData.append('color', result);
  
  return this._http.post(`${this.apiBaseUrl}v1/product`, productData);
}

updateColors(productId:string,colors:string[]) {
  return this._http.put(`${this.apiBaseUrl}v1/product/color/${productId}`,colors);
}

getProductByCategory(categoryId : string){
  return this._http.get(`${this.apiBaseUrl}v1/admin/all/list?category=${categoryId}`);
}

getProductById(productId : string){
  return this._http.get(`${this.apiBaseUrl}v1/product/${productId}/details/product`);
}

deleteProduct(productId : string){
  return this._http.delete(`${this.apiBaseUrl}v1/product/${productId}`);
}

updateProduct(productId : string,product : any){
  let productData = new FormData();
  productData.append('name', product.name);
  productData.append('price', product.price);
  productData.append('description', product.description);
  productData.append('category', product.category);
  productData.append('quantity', product.quantity);
  productData.append('size', product.size);
  productData.append('length', product.length);
  productData.append('width', product.width);
  productData.append('height', product.heigth);
  productData.append('weight', product.weigth);


  product.images.forEach(image => {
    productData.append('images', image);
  });
  productData.append('existing_images', product.existing_images);

  var data = product.colors
  var result = data.map(function(val) {
    return val.p_color;
  }).join(',');
  
  
  productData.append('color', result);

  return this._http.put(`${this.apiBaseUrl}v1/product/${productId}`, productData);
}

}
