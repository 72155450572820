import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  user:any
  baseUrl=environment.url
  constructor(
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private userService:UserService) { }

  ngOnInit(): void {
    let userId=this.activatedRoute.snapshot.paramMap.get('id');
    this.getUser(userId)
    
  }

  getUser(userId:string) {
    this.userService.getUser(userId).subscribe((response:any) => {
      this.user=response.data
    })
  }
}
