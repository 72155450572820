import 'datatables.net';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.css']
})


export class ExercisesComponent implements OnInit {
  allExercise;
  public showLoader: boolean = false;

  apiBaseUrl: string = environment.url;
  constructor(private snackbar: SnackbarService, private http: HttpClient, private router: Router) { }

  ngOnInit() {

    this.adminLogin();
    $(document).ready(function () {
      $('#example').DataTable();
    });
  }

  adminLogin() {
    this.showLoader = true;
    this.http.get(this.apiBaseUrl + 'v1/getAllExercises').subscribe((data: any) => {
      console.log(data)
      if (data.status == 200) {
        this.showLoader = false;
        this.allExercise = data.data;
        $(document).ready(function () {
          $('#example').DataTable();
        });
      }
      else {
        this.allExercise = '';
      }
    })
  }

  openAddExercisePage = () => {
    this.router.navigateByUrl('/add-exercise')
  }


  deleteExercise(id) {
    if (confirm("Are you sure to want to delete?")) {
      this.http.post(this.apiBaseUrl + 'v1/deleteExerciseAdmin', { exerciseId: id }).subscribe((data: any) => {
        if (data.status == 200) {
          this.snackbar.successSnackBar(" Exercise deleted successfully.", '');
          window.location.reload();
          this.adminLogin()

        }
        else {

          // this.invalid = true;

        }

      })
    }
    else {

    }
  }




}


