import Quill from 'quill'
import { Router } from '@angular/router';
import { Routes } from 'src/app/models/routes';
import { EditorChangeContent } from 'ngx-quill';
import { Component, OnInit } from '@angular/core';
import { EmailService } from 'src/app/services/email.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrls: ['./create-email.component.css']
})
export class CreateEmailComponent implements OnInit {

  blogForm : FormGroup
  submitted : boolean = false;

  //fileTypes = ["image/jpeg","image/png","image/jpg","image/tiff","image/gif"];

  routes : Routes = new Routes();

  constructor(private _fb : FormBuilder, private _emailService : EmailService, private _router : Router) { }

  ngOnInit(): void {
    // console.log(this._emailService.checkedCategoryList,"jjjjjjjjjjjjjjjjjj")
     this.blogForm = this._fb.group({
        subject : ['', [Validators.required, Validators.maxLength(60)]],
        description : ['', Validators.required],
        image : [],
        short_description : [''],
     })
     
     if (this._emailService.checkedCategoryList == undefined){
       alert("Please select Users for sending emails")
      this._router.navigate(['/Emails']);
     }
  }

  get f(){
    return this.blogForm.controls;
  }

  created(event: Quill) {
    // tslint:disable-next-line:no-console
    console.log('editor-created', event)
  }

  contentChanged(event : EditorChangeContent){
      console.log(event);
     // if(event.text.length<=102)
         this.blogForm.controls.short_description.setValue(event.text.substring(0,102).replace('\n',' ').trim());
  }

   onFileChange(event : any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
     const fileType=file.type;
    //if(this.fileTypes.includes(fileType)){
      this.blogForm.controls.image.setValue(file);
    //}else{
    //  alert("only "+this.fileTypes.toString()+" format supports");
    //}

    } else {

    }
  }

  createBlog(){
      this.submitted = true;
      
      if(this.blogForm.valid){
        console.log("success of mass email removed validation of client feedback")
            const blog = this.blogForm.value;
            const formData = new FormData();
            formData.append('subject',blog.subject);
            formData.append('content', blog.description);
            formData.append('short_description', blog.short_description);
            formData.append('attachment', blog.image || "");
            // formData.append('attachment', blog.image =="undefined" ? "": blog.image);
            formData.append('users', this._emailService.checkedCategoryList);

            this._emailService.SendEmail(formData).subscribe((response : any) =>{
               if(response.status==200){
                    this.blogForm.reset();
                    this.submitted = false;
                    this._router.navigate(['/Emails']);
                    alert('Email(s) sent successfully!')
               }
                    
            })
      }else{
        console.log("else part")
        //  if(this.blogForm.controls.title.errors?.maxlength){
        //     alert("Title must be less than or equal to "+this.blogForm.controls.title.errors.maxlength.requiredLength+" characters");
        //  }
      }
  }

}
