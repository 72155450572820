import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ShopAndProductService } from '../service/shop-and-product.service';
import { AddOrUpdateShopCategoryComponent } from '../add-or-update-shop-category/add-or-update-shop-category.component';

@Component({
  selector: 'app-shop-categories',
  templateUrl: './shop-categories.component.html',
  styleUrls: ['./shop-categories.component.css']
})
export class ShopCategoriesComponent implements OnInit {

  categories : any[] = []

  route : Routes = new Routes();

  constructor(public dialog: MatDialog, private _shopAndProductService : ShopAndProductService,private snackbar:SnackbarService) { }

  ngOnInit(): void {
     this.getAllCategories();
  }

  addCategory(category : any){
    const dialogRef = this.dialog.open(AddOrUpdateShopCategoryComponent, {
      width: '350px',
      data : category,
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result==='success'){
          this.getAllCategories();
       }
    });
       
  }

  getAllCategories(){
    this._shopAndProductService.getCategories('').subscribe((resp : any)=>{
      if(resp.status ==200){
        this.categories = resp.data;
        $(document).ready(function() {
          $('#example').DataTable();
        });
      }
    })
  }

  deleteCategory(categoryId : string){
      if(window.confirm("Are you sure want to delete this category?")){
        this._shopAndProductService.deleteCategory(categoryId).subscribe((resp : any) =>{
            this.getAllCategories();
            this.snackbar.successSnackBar("Category successfully deleted",'');
        },error => {
          this.snackbar.successSnackBar("This category is already associated with products. If you wish to delete this category, please change the category of your products first.",'');
        })
      }
  }
}
