import { FormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MedicationService } from 'src/app/services/medication.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-add-medication',
  templateUrl: './add-medication.component.html',
  styleUrls: ['./add-medication.component.css']
})
export class AddMedicationComponent implements OnInit {

  medication = new FormControl('', Validators.required);

  title : string = undefined;

  constructor(public dialogRef: MatDialogRef<AddMedicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _medicationService : MedicationService) { }

  ngOnInit(): void {
    this.title = this.data === null ? 'Add Medication' : 'Update Medication';
    if(this.data !==null)
           this.medication.patchValue(this.data.medName);
  }

  submit(){
      if(this.medication.valid){
      const medicationName = this.medication.value;
      if(this.data === null)
               this.addMedication(medicationName);
      else
               this.updateMedication(medicationName); 
    }                  
  }

  addMedication(medicationName : string){
       this._medicationService.addMedication(medicationName).subscribe((resp : any) =>{
            this.dialogRef.close('result');
       })
  }

  updateMedication(medicationName : string){
      this._medicationService.updateMedication(medicationName, this.data.id).subscribe((resp : any) => {
          this.dialogRef.close('result');
      })
  }

}
