import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MedicationService } from 'src/app/services/medication.service';
import { AddMedicationComponent } from '../add-medication/add-medication.component';

@Component({
  selector: 'app-medication',
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.css']
})
export class MedicationComponent implements OnInit {

  showLoader : boolean = true;

  medications : any [] = [];

  search : string = '';

  constructor(private _medicationService : MedicationService,public dialog: MatDialog) { }

  ngOnInit(): void {
       this.getMedications();
  }

  getMedications(){
    this._medicationService.getMedications().subscribe((response : any)=>{
      this.showLoader = false;
      this.medications = response.data;
  }, error => this.showLoader = false)
  }

  addMedication(){
        this.openDialog(null);
  }

  updateMedication(medication : any){
     this.openDialog({ id : medication._id, medName : medication.medName});
  }

  openDialog(data : any){
    const dialogRef = this.dialog.open(AddMedicationComponent, {
      width: '350px',
      data : data,
      position : { top : '50px'}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result==='result'){
          this.getMedications();
       }
     
    });
  }

  deleteMedication(medication : any, index : number){
      this._medicationService.deleteMedication(medication._id).subscribe((resp : any) =>{
         if(resp.status == 200)
              this.medications.splice(index,1);
      })
  }

}
