 <div id="mySidenav" class="sidenav">
       <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>      
       <a href="/all-users">All Users</a>
    </div>

    <header class="top-bar">
    <nav class="navbar navbar-expand-lg navbar-dark bg-blue pt-3 pb-3">
     <div class="container">    
      <a class="navbar-brand" href="javascript:void(0)" routerLink="/dashboard"><img src="assets/Logo-nav-white.png" class="img-fluid"></a>
      <span class="toggle" onclick="openNav()">&#9776;</span>

      <a (click)="logout()" style="cursor: pointer;" class=" ml-auto"><i class="fa fa-sign-out" aria-hidden="true"></i></a>

     </div>
    </nav>
    </header>

    <div class="navigation">
    <div class="container">
    <ul class="nav">    
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/dashboard">Dashboard</a>
      </li>  
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/all-users">All Users</a>
      </li>  
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/exercises">Exercises</a>
      </li>  
       <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/exercise-videos">Exercise Videos</a>
      </li>  
       <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/stress-management">Stress Management</a>
      </li>  
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/workout-challenges">Wellness Challenges</a>
      </li>  
       <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/feedback">Feedback</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/quiz">Quiz</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/medication">Medication</a>
      </li>

    </ul>
    </div>
    </div>
