
  <button type="button" [disabled]="!a"  class="btn btn-success" style="margin-bottom: 14px;margin-left: 77%;" routerLink="/createEmail">Send Email</button>
  <div class="row">
    <div class="checked_all">
<input type="checkbox" [(ngModel)]="isMasterSel" name="list_name" value="h1" (change)="checkUncheckAll()" class="check_alll" />
<label class="check_all">Select All / Unselect All</label>
</div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div>   
      <div class="card-body" id = "example_wrapper">
      </div>
          <table id="example"   datatable class="display">
              <thead>

                  <tr>
                      <th>Sr. No.</th>
                      <th>User</th>
                      <th>Email</th>  
                      <th>Send Email</th>
                      <th>View Reply</th> 
                  </tr>
              </thead>
             <tbody>
               <tr *ngFor="let category of categories; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{category.firstName}} {{category.lastName}}</td> 
                     <td>{{category.email}}</td>
                     <td>
                      <div class="form-check">
                        <input type="checkbox" [(ngModel)]="category.isSelected" name="list_name" value="{{category.email}}" (change)="isAllSelected()"/>
                      </div>
                      </td>
                      <td>
                        <a class="btn btn-success" [routerLink]="['/view-sent-mail']" [queryParams]="{userId:category._id}" matTooltip="View Reply" matTooltipPosition="right">
                          <span class="fa fa-eye"></span>
                        </a>
                      </td>
                 </tr>
                
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>