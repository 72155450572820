<div class="row">
    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-border">
            <div class="card-body">
                <h2 class="card-title">Add Testimonials</h2>
                <div class="form-group">
                    <label class="required">Title:</label>
                    <input class="form-control" [(ngModel)]="title" (input)="space($event)" maxlength="100"  placeholder="Enter Title"
                        [ngClass]="{ 'is-invalid': added && !title}" />
                </div>
                <div class="form-group">
                    <label class="required">Select Type:</label>
                    <div class="testimonial-select">
                        <select class="form-control" [(ngModel)]="type" [ngClass]="{ 'is-invalid': added && !type}">
                            <option value="Home Page">BIG4 Home Page</option>
                            <option value="Ingredients">BIG4 Health Ingredients</option>
                            <option value="Business">BIG4 Health In Business</option>
                        </select>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </div>
                </div>

                <div class="form-group">
                    <label class="required">Select Video:</label>
                    <input type="file" accept="video/*" (change)="onFileChange($event)"
                        class="form-control cursor-pointer" #video [ngClass]="{ 'is-invalid': added && fileInvalid }">
                </div>
                <label *ngIf="added && !selectedFile && !fileInvalid" class="validation-error">{{ Messages.REQUIRED_FILE
                    }}</label>
                <label *ngIf="added && fileInvalid" class="validation-error">{{ Messages.REQUIRED_VIDEO_FILE }}</label>
                <div class="justify-content-center d-flex mt-4">
                    <button type="button" class="btn btn-submit mr-3" (click)="addStats()">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <div class="uploaded-vides-outer mt-4">
        <div class="uploaded-video-box">
            <div class="row">
                <div class="col-md-12">
                    <div class="places-buttons">
                        <div class="row mt-4">
                            <div class="col-md-12 ml-auto mr-auto">
                                <div class="row">
                                    <div class="col-md-4 testimonial-heading">
                                        <button (click)="selectedType='Home Page';getAll()" mat-raised-button
                                            class="uploaded-video-box" [class.active]="selectedType=='Home Page'">
                                            <h3>BIG4 Home Page</h3>
                                        </button>
                                    </div>
                                    <div class="col-md-4 testimonial-heading">
                                        <button (click)="selectedType='Ingredients';getAll()" mat-raised-button
                                            class="uploaded-video-box" [class.active]="selectedType=='Ingredients'">
                                            <h3>BIG4 Health Ingredients</h3>
                                        </button>
                                    </div>
                                    <div class="col-md-4 testimonial-heading">
                                        <button (click)="selectedType='Business' ;getAll()" mat-raised-button
                                            class="uploaded-video-box" [class.active]="selectedType=='Business'">
                                            <h3>BIG4 Health In Business</h3>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4" *ngFor="let video of testimonialList">
                        <div class="testimonial-video">
                            <h4 class="testimonial-title-label mb-0 mt-3">{{video.title}}</h4>
                            <video height="300px" width="400px" controls>
                                <source [src]="video.image" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                            <button (click)="delete(video._id)" class="btn btn-danger" matTooltip="Delete"> <i
                                    class="fa fa-trash" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>