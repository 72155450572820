import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancelled-request',
  templateUrl: './cancelled-request.component.html',
  styleUrls: ['./cancelled-request.component.css']
})
export class CancelledRequestComponent implements OnInit {

  type="cancelled"

  constructor() { }

  ngOnInit(): void {
  }

}
