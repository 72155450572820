

  <button type="button" class="btn btn-success" routerLink="/update-reward"  style="margin-bottom: 30px;">Update Reward</button>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example"   datatable class="display" *ngIf="!showLoader">
              <thead>
                  <tr>
                      <th>Pass Reward</th>
                      <th>Fail Reward</th>
                      <!-- <th>Location</th> -->
                      <!-- <th>Action</th>                       -->
                  </tr>
              </thead>
             <tbody>
                 <tr >
                     <td>{{pass}}$</td>
                     <td>{{fail}}$</td> 
                     <!-- <td>{{quiz.locations}}</td> -->
                     <!-- <td>
                      <mat-icon color="primary" aria-hidden="false" aria-label="edit icon" routerLink="/update_quiz/{{quiz._id}}">edit</mat-icon> 
                      <mat-icon class="marginLeft10" *ngIf="quizzes.length>1" (click)="deleteQuiz(quiz._id)" color="warn" aria-hidden="false" aria-label="delete icon">delete</mat-icon>
                     </td> -->
                 </tr>
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>
