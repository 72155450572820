import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {


  products : any[] = [
    { id : 1, categoryName : "Category", productName : 'Product Name', price : '10.0'}
  ]

  route : Routes = new Routes();

  orderStatus : string[] = ['Active','Completed','Cancelled'];

  constructor() { }

  ngOnInit(): void {
     this.getProductsByCategoryId(null);
     
  }

 getProductsByCategoryId(categoryId : string){
  $(document).ready(function() {
    $('#example').DataTable();
    } );
 }

}
