import { FormControl, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { ELearningService } from 'src/app/services/eLearning/e-learning.service';
import { noWhitespaceValidator } from 'src/app/_validators/noWhitespaceValidator';

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.css']
})
export class CategoryDialogComponent implements OnInit {
  @Output() success = new EventEmitter<boolean>();

  title: string = undefined;
  submitted: boolean = false;

  category: FormControl = new FormControl('', [Validators.required, noWhitespaceValidator, Validators.maxLength(25)]);

  constructor(public dialogRef: MatDialogRef<CategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _eLearningService: ELearningService, public loaderservice: LoaderService, private snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.title = this.data === null ? 'Add Category' : 'Update Category';
    if (this.data !== null)
      this.category.patchValue(this.data.name);
  }

  submit() {
    if (this.category.valid) {
      const categoryName = this.category.value;
      if (this.data === null)
        this.addCategory(categoryName);
      else
        this.updateCategory(categoryName);

    }
  }

  addCategory(categoryName: string) {
    this.submitted = true;

    this._eLearningService.createCategory({name: categoryName}).subscribe((resp: any) => {
      if (resp.status == 200) {
        this.snackbar.successSnackBar(resp.message, '');

        this.dialogRef.close('result');
      }
      else {
        this.snackbar.successSnackBar(resp.message, '');
        this.dialogRef.close('result');

      }
    })
  }

  updateCategory(categoryName: string) {
    this.submitted = true;
    this._eLearningService.createCategory({ name: categoryName, id: this.data.id }).subscribe((resp: any) => {
      if (resp.status == 200) {
        this.snackbar.successSnackBar(resp.message, '');

        this.dialogRef.close('result');
      }
      else {
        this.snackbar.successSnackBar(resp.message, '');
        this.dialogRef.close('result');

      }
    })
  }

}
