import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators,} from "@angular/forms";
import { AdminAddressService } from 'src/app/services/admin-address.service';
import { noWhitespaceValidator } from 'src/app/_validators/noWhitespaceValidator';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.css']
})
export class AddAddressComponent implements OnInit {

  productForm : FormGroup;

  files : any[] = [];
  numbers = ["0","1","2","3","4","5","6","7","8","9","."];


  submitted : boolean = false;
 
  constructor(private addressService : AdminAddressService, private _fb : FormBuilder,private router: Router,private _activateRoute : ActivatedRoute,) { }

  ngOnInit(): void {
    
     this.productForm = this._fb.group({
       name : ['', [Validators.required,noWhitespaceValidator]],
       country : ['', [Validators.required,noWhitespaceValidator]],
       state : ['', [Validators.required,noWhitespaceValidator]],
       city : ['', [Validators.required,noWhitespaceValidator]],
       zip_code: ['', [Validators.required,noWhitespaceValidator]],
       address: ['', [Validators.required,noWhitespaceValidator]],
       phone: ['', [Validators.required,noWhitespaceValidator]],
       street1: ['', [noWhitespaceValidator]],
       street2: ['', [noWhitespaceValidator]],

      
     });
  }
  get f(){
    return this.productForm.controls;
  }

  onlyNumber(event : KeyboardEvent){
    const key = event.key;
    const zipcode = this.productForm.controls.zip_code.value;
    // const phone = this.productForm.controls.phone.value;

    if(key==='.')
       return String(zipcode).indexOf(key) > -1 ? false : true;
      //  return String(phone).indexOf(key) > -1 ? false : true;

    return this.numbers.includes(key);
  }


  addAddress(){
    this.submitted = true;
    if(this.productForm.invalid){
      return
    }
     if(this.productForm.valid){
      let adminAdress = {
        
          "name": this.productForm.value.name,
          "phone": this.productForm.value.phone,
          "country": this.productForm.value.country,
          "address": this.productForm.value.address,
          "city": this.productForm.value.city,
          "zip_code": this.productForm.value.zip_code,
          "street1": this.productForm.value.street1,
          "street2": this.productForm.value.street2,
          "state": this.productForm.value.state
      
      }
     

      this.addressService.addAddress(adminAdress).subscribe((resp : any)=>{
          if(resp.status == 200){
            this.productForm.reset();
            this.submitted = false;
            this.router.navigate(['/address/']);

            alert("Address added successfully.")

          }
          else{
            alert(resp.message)
          }

      });
     }else{
       
          alert("Fields are required");
       
     }
  }

}
