<h3 mat-dialog-title>{{title}}</h3>
<div mat-dialog-content>
    <p>
        <mat-form-field appearance="outline">
          <mat-label>Category</mat-label>
          <input matInput placeholder="Enter category" [formControl]="category" maxlength="25">
          <mat-error *ngIf="category.invalid">Category is required</mat-error>
        </mat-form-field>
      </p>
</div>
<div mat-dialog-actions align="end" *ngIf='submitted==true'>
  <button mat-raised-button color="warn" mat-dialog-close>Close</button>
  <button mat-raised-button color="primary" (click)="submit()" disabled >Submit</button>
</div>
<div mat-dialog-actions align="end" *ngIf='submitted==false'>
  <button mat-raised-button color="warn" mat-dialog-close>Close</button>
  <button mat-raised-button color="primary" (click)="submit()" >Submit</button>
</div>