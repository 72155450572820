import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrdersService } from 'src/app/services/orders.service';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';

@Component({
  selector: 'app-return-orders',
  templateUrl: './return-orders.component.html',
  styleUrls: ['./return-orders.component.css']
})
export class ReturnOrdersComponent implements OnInit {

  products :any[] = []

  route : Routes = new Routes();
  selectControl : FormControl = new FormControl();

  constructor( public orderService:OrdersService,private router: Router,public dialog: MatDialog, private ngxBootstrapConfirmService: NgxBootstrapConfirmService) { }

  ngOnInit(): void {
     this.getReturnOrders();
     this.selectControl.setValue('')
     
  }

  getReturnOrders(){
    this.orderService.getReturnOrders().subscribe((resp : any)=>{
      if(resp.status ==200){

           this.products = resp.data;
           console.log(this.products,"sdgfgsdf)")
           $(document).ready(function() {
            $('#example').DataTable();
            } );
      }

    } );

  }


  test(id:any){
    console.log(this.selectControl.value); 
    if(this.selectControl.valid){
     const product =  {
       "status":this.selectControl.value
      }
     let options ={
      title: 'Are you sure you want to change status of this Order?',
      confirmLabel: 'Yes',
      declineLabel: 'No'
    }
    this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
      if (res) {
        this.orderService.changeStatus(id,product).subscribe((resp : any) =>{
          if(resp.status == 200){
              console.log(resp);
              window.location.reload();

          }
      }
      )
    }
    });
  }
     
 }


}