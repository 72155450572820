<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card card-border">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-md-12 text-center">
                        <img [src]="post?.userDetail?.profilePicture" height="200px" width="200px">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold">Title</label>
                            <p class="col-md-6"> {{ post?.title | titlecase}} </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold">Status</label>
                            <p class="col-md-6"> {{ post?.status }} </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold">Professional Type</label>
                            <p class="col-md-6"> {{ post?.userDetail?.professionalType }} </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold">Date & Time</label>
                            <p class="col-md-6"> {{ post?.createdAt | date : 'yyyy-MM-dd HH:mm:ss aa'}}</p>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold"> Webinar Language</label>
                            <p class="col-md-6"> {{ post?.language | titlecase}} </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold">Catgeory</label>
                            <p class="col-md-6"> {{ post?.big_categories.name }} </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold">Webinar Url</label>
                            <p class="col-md-6"><a href="{{post?.webinar_url}}" target="_new">Webinar Link</a> </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold">User Name</label>
                            <p class="col-md-6"> {{ post?.userDetail?.name }} </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold">User Type</label>
                            <p class="col-md-6"> {{ post?.userDetail?.userType }} </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6 font-weight-bold">Description</label>
                            <p class="col-md-6"> {{ post?.description | titlecase }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>