<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <table id="example" *ngIf="list" datatable class="display">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Booking Date</th>
                            <th>Booking End Date</th>
                            <th>User</th>
                            <th>Notes</th>
                            <th>MedUser</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let post of list, let i=index">
                            <td>{{ i+1 }}</td>
                            <td>{{ post.bookingDate | date : 'yyyy-MM-dd HH:mm:ss aa' }}</td>
                            <td>{{ post.bookingEndDate | date : 'yyyy-MM-dd HH:mm:ss aa' }}</td>
                            <td>{{ post.userDetail?.name }}</td>
                            <td>{{ post.notes }}</td>
                            <td>{{ post.meduserDetail.name }}</td>
                            <td>{{post.status}}</td>
                        </tr>
                        <tr *ngIf="list.length==0">
                            <td colspan="100%">No data found!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>