<h3 mat-dialog-title>{{title}}</h3>
<div mat-dialog-content>
  <form [formGroup]="categoryForm">
   <div class="row">
    <p>
        <mat-form-field appearance="outline">
          <mat-label>Category</mat-label>
          <input matInput placeholder="Enter category" formControlName="category" maxlength="25">
          <mat-error *ngIf="f.category.invalid && submitted">Category is required</mat-error>
        </mat-form-field>
      </p>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group" style = "margin-left: -4%;">
            <input type="file" [ngClass]="{ 'is-invalid': submitted && f.image.errors}" class="form-control" accept="image/*" (change)="onFileChange($event)"  formControlName="image" >
        </div>
    </div>

  <div class="row" >
    <div class="col-sm-12">
      <mat-label>Is this Food Category?</mat-label>
        <input  type="checkbox" formControlName="isFoodCategory" />
    </div>
  </div>

    <div class="row" *ngIf ="f.isFoodCategory.value==true">
      <div class="col-sm-12">
      <mat-label>Do you want to add subscription?</mat-label>
        <input type="checkbox" formControlName ="saveSubscription" />
    </div>
     
    </div>
    </div>
    <br>
    <div class="row" *ngIf="imagePreview">
      <img [src]="imagePreview" alt="" style="width: 100%;">
      <br>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>Close</button>
  <button mat-raised-button color="primary" (click)="submit()">Submit</button>
</div>