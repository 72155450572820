import * as $ from 'jquery';
import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../services/subscription.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  showLoader : boolean = true;

  categories : any[] = [];

  route : Routes = new Routes();

  constructor(private subscriptionService : SubscriptionService) { }

  ngOnInit(): void {
     this.getAllsubscription();
  }

  

  getAllsubscription(){
    this.subscriptionService.getsubscription().subscribe((response : any) => {
      this.showLoader = false;
     // console.log(response);
      this.categories = response.data;
    $(document).ready(function() {
      $('#example').DataTable();
      } );
  },error => this.showLoader = false);

}

}