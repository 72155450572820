import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { QuizService } from "src/app/services/quiz.service";
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@syncfusion/ej2-base';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';

@Component({
  selector: 'app-update-reward',
  templateUrl: './update-reward.component.html',
  styleUrls: ['./update-reward.component.css']
})
export class UpdateRewardComponent implements OnInit {
  showLoader: boolean = false;
  submitted: boolean = false;
  productForm : FormGroup;
  pass;
  fail;
  numbers = ["0","1","2","3","4","5","6","7","8","9","."];



  constructor(private _fb: FormBuilder,
    private _router: Router,
    private _quizService: QuizService,private router: Router,private _activateRoute : ActivatedRoute, private ngxBootstrapConfirmService: NgxBootstrapConfirmService) { }


  ngOnInit(): void {
    this.getReward();
    this.productForm = this._fb.group({
      fail : ['', Validators.required],
      pass : ['', [Validators.required]],
      
     });
  }
  get f(){
    return this.productForm.controls;
  }
  
  getReward(){
    this._quizService.getQuizRewards().subscribe((resp : any) =>{
         if(resp.status == 200){
            this.fail = resp.data.fail;
            this.productForm.controls.fail.setValue(this.fail);
            this.pass = resp.data.pass;
            this.productForm.controls.pass.setValue(this.pass); 
         }
    })
 }

 

 onlyNumber(event : KeyboardEvent){
  const key = event.key;
  const percent = this.productForm.controls.pass.value;
  const percentt = this.productForm.controls.fail.value;

  if(key==='.')
     return String(percent).indexOf(key) > -1 ? false : true;

  return this.numbers.includes(key);
}



updateReward(){
  this.submitted =true
  if(this.productForm.invalid){
    return
  }
  if(this.productForm.valid){
     const product =  {
       "fail": this.productForm.value.fail,
       "pass":this.productForm.value.pass
      }
      console.log(product)
     let options ={
      title: 'Are you sure you want to update rewards for quiz?',
      confirmLabel: 'Yes',
      declineLabel: 'No'
    }
    this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
      if (res) {
        this._quizService.updateReward(product).subscribe((resp : any) =>{
          if(resp.status == 200){
              console.log(resp);
              this.router.navigate(['/quiz-reward']);
              alert("Rewards has been updated successfully ")
  
          }
      })
    }
    });
  }
  
   
}


}

