import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators,} from "@angular/forms";
import { AdminAddressService } from 'src/app/services/admin-address.service';
import { noWhitespaceValidator } from 'src/app/_validators/noWhitespaceValidator';

@Component({
  selector: 'app-admin-address',
  templateUrl: './admin-address.component.html',
  styleUrls: ['./admin-address.component.css']
})
export class AdminAddressComponent implements OnInit {

  productForm : FormGroup;

  files : any[] = [];
  numbers = ["0","1","2","3","4","5","6","7","8","9","."];


  submitted : boolean = false;
  isAddress;
 
  constructor(private addressService : AdminAddressService, private _fb : FormBuilder,private router: Router,private _activateRoute : ActivatedRoute,) { }

  ngOnInit(): void {
    this.getAddressDetail();
     this.productForm = this._fb.group({
       name : ['', [Validators.required,noWhitespaceValidator]],
       country : ['', [Validators.required,noWhitespaceValidator]],
       state : ['', [Validators.required,noWhitespaceValidator]],
       city : ['', [Validators.required,noWhitespaceValidator]],
       zip_code: ['', [Validators.required,noWhitespaceValidator]],
       address: ['', [Validators.required,noWhitespaceValidator]],
       phone: ['', [Validators.required,noWhitespaceValidator]],
       street1: ['', [noWhitespaceValidator]],
       street2: ['', [noWhitespaceValidator]],
     });
  }

  get f(){
    return this.productForm.controls;
  }

  getAddressDetail(){
    this.addressService.getAddress().subscribe((resp : any) =>{
         if(resp.status == 200){
             const product = resp.address;
             if (product==null){
              this.isAddress="null"
             }
           
             this.productForm.controls.name.setValue(product.name);
             this.productForm.controls.phone.setValue(product.phone);
             this.productForm.controls.country.setValue(product.country);
             this.productForm.controls.city.setValue(product.city);
             this.productForm.controls.state.setValue(product.state);
             this.productForm.controls.zip_code.setValue(product.zip_code);

             this.productForm.controls.address.setValue(product.address);
             this.productForm.controls.street1.setValue(product.street1);
             this.productForm.controls.street2.setValue(product.street2);           
        }
    });
  }

  deleteAddress(){
    const confirm = window.confirm("Are you sure you want to delete this address.");
      if(confirm){
        this.addressService.deleteAddress().subscribe((response : any)=>{
          alert("Address deleted successfully")
          location.reload();
      })
      }
  }
}
