import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ELearningService } from 'src/app/services/eLearning/e-learning.service';

@Component({
  selector: 'app-assign-video-to-category',
  templateUrl: './assign-video-to-category.component.html',
  styleUrls: ['./assign-video-to-category.component.css']
})
export class AssignVideoToCategoryComponent implements OnInit {

  getcategory;
  clicked = false;
  allVideos : any[] = [];
  showLoader : boolean = true;
  categoryId : string = undefined
  SERVER_URL: string = environment.url;
  currentPlayingVideo: HTMLVideoElement;

  constructor(private _activeRoute : ActivatedRoute, 
              private _router : Router,
              private elearningService:ELearningService,
              private snackbar:SnackbarService) {}

  ngOnInit(): void {
    this.categoryId = this._activeRoute.snapshot.paramMap.get('id');
    this.getVideoByCategory(this._activeRoute.snapshot.paramMap.get('categoryId'));

    this.getVideos();
  }


  onPlayingVideo(event) {
    event.preventDefault();
    // play the first video that is chosen by the user
    if (this.currentPlayingVideo === undefined) {
        this.currentPlayingVideo = event.target;
        this.currentPlayingVideo.play();
    } else {
    // if the user plays a new video, pause the last one and play the new one
        if (event.target !== this.currentPlayingVideo) {
            this.currentPlayingVideo.pause();
            this.currentPlayingVideo = event.target;
            this.currentPlayingVideo.play();
        }
    }
}


  getVideos(){
      this.getVideoByCategory(this.categoryId); 
  }

  updatevideo(categoryId : string){
    this._router.navigate(['e-learning/UpdateVideos/'+categoryId]);}

  getVideoByCategory(categoryId : string){
    this.elearningService.getVideosByCategory(categoryId).subscribe((resp : any) =>{
      // console.log(resp);
       if(resp.status == 200){
        this.getcategory=resp.data.name;
         this.allVideos = resp.data.videos;
       
       $(document).ready(function() {
        $('#example').DataTable();
        });
      }
 })

  }



  deleteVideos(categoryId : string,videoId:string){
    //  this.showLoader = true;
      const a = {
        "category_id":categoryId,
        "video_id":videoId
      }
      const confirm = window.confirm("Are you sure want to delete this video?");
      if(confirm){
      this.elearningService.deleteVideoById(a).subscribe((response : any)=>{
      this.snackbar.successSnackBar("Video successfully deleted",'');

      window.location.reload();
    
      }, error => this.showLoader = false)
    }
  }

}
