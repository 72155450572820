
   <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example" datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>OrderId</th>

                      <th>Product Id</th>
                      <th>Category Name</th>
                      <th>Product Name</th>
                      <th>Price</th>
                      <th>Ordered On</th>
                      <th>Action</th>                      
                  </tr>
              </thead>
             <tbody>
               <tr *ngFor="let product of products; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{ product._id }}</td>
                     <td>{{ product.product._id }}</td>
                     <td>{{product.product.category.name}}</td> 
                     <td>{{product.product.name}}</td> 
                     <td>{{product.grandtotal > 0 ? product.grandtotal.toFixed(2) :  ((((product.tax) / 100) * (product.price)) + (product.price) *(product.qty) + (product.shipping_charge))  | number:'1.2-2' }}</td>
                     <td>{{product.createdAt  | date :'dd LLLL,yyyy , h:mm a'}}</td> 

                     <td>
                      <mat-icon  color="primary" aria-hidden="false" aria-label="view icon" routerLink="/{{route.orderDetails2}}/{{product._id}}" matTooltip="view order details">open_in_new</mat-icon>   
                     </td>
                 </tr>
                
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>