import 'rxjs/add/operator/map';
import { Router} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth/auth.service';
import { LoaderService } from '../services/loader/loader.service';
import { FormGroup, FormControl,Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form;
email;
password;
submitted;
isAdminLogin;
invalid:boolean  = false;


    constructor(private userService: UserService, private router: Router,
       private _authService : AuthService, private _loader : LoaderService) 
{
  this.isAdminLogin  = sessionStorage.getItem('adminId');

    if(this.isAdminLogin != null){
      this.router.navigateByUrl('/login');
    }

}

  ngOnInit() {
  this.form = new FormGroup({
        email: new FormControl("",[Validators.required,Validators.email]),
         password: new FormControl("",[Validators.required])
      });

}

  get f() { return this.form.controls; }

   adminLogin() {
    this.submitted = true;         
    if (this.form.invalid) return;
    

    let data=this.form.value
    console.log(data,"formdetail.url")
    
    console.log(environment.url,"environment.url")

    console.log(data,"data")
  
    this.userService.login(data.email, data.password).subscribe((data : any) => {
     if(data.status == 200){ 
       sessionStorage.setItem('adminId',"YesLoggedIn");
       this._authService.saveAdminId(data.admin[0]._id);
       this._authService.saveToken(data.token);
       this._authService.showNavBar(true);
     //  this._loader.loadingOff();
       this.router.navigate(['/dashboard']);
     
   }     
         else
         {
          
          this.invalid = true;
        //  this._loader.loadingOff();
         }

     }, error => this._loader.loadingOff())

     }

}