import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { QuizService } from 'src/app/services/quiz.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {

  showLoader : boolean = true;

  quizzes : any[] = [];

  constructor(private _quizService : QuizService,private snackbar:SnackbarService) { }

  ngOnInit(): void {
   
    this.getQuizzes();
   
  }

  getQuizzes(){
     this._quizService.getQuizzes().subscribe((response : any) => {
          this.showLoader = false;
         // console.log(response);
          this.quizzes = response.data;
          $(document).ready(function() {
            $('#example').DataTable();
            } );
     }, error => this.showLoader = false);
  }

  deleteQuiz(quizId : string){
    //  this.showLoader = true;
      this._quizService.deleteQuizById(quizId).subscribe((response : any)=>{
         //   this.showLoader = false;
            var index = -1;
            var loopIndex = -1;
            this.quizzes.forEach(quiz => {
                loopIndex = loopIndex+1;
                 if(quiz._id === quizId){
                         index = loopIndex;
                 }        
            });
            if(index !==-1)
                this.quizzes.splice(index,1);
                this.snackbar.successSnackBar("Quiz deleted successfully.",'');

      }, error => this.showLoader = false)
  }

}
