import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  apiBaseUrl = environment.url;

  constructor(private _http : HttpClient) { }

  getReports(){
    return this._http.get(this.apiBaseUrl+'v1/reports');
  }
}
