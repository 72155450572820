<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="rejectingAccount">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <table id="example" *ngIf="accounts" datatable class="display">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Gender</th>
                            <th>Contact</th>
                            <th>Status</th>
                            <th>Signup Date</th>
                            <th>Inactive/Active</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let account of accounts, let i=index">
                            <td>{{ i+1 }}</td>
                            <td>{{ account.email }}</td>
                            <td>{{ account.firstName+' '+account.lastName }}</td>
                            <td>{{ account.professionalType }}</td>
                            <td>{{ account.gender }}</td>
                            <td>{{ account.mobileNumber }}</td>
                            <td>{{ account.admin_status }}</td>
                            <td>{{ account.signUpDate | date}}</td>
                            <td>
                                <div *ngIf="account.isActive == '1'; else elseTemplate">
                                    <label class="switch">
                                        <input type="checkbox" (change)="toggleSwitch($event.target.value, account._id)"
                                            value="{{account.isActive}}" [checked]="true">
                                        <span class="slider round"></span>
                                    </label>
                                </div>

                                <ng-template #elseTemplate>
                                    <label class="switch">
                                        <input type="checkbox" (change)="toggleSwitch($event.target.value, account._id)"
                                            value="{{account.isActive}}" [checked]="false">
                                        <span class="slider round"></span>
                                    </label>
                                </ng-template>
                            </td>
                            <td>
                                <div style="display: flex;">
                                    <button *ngIf="(account.ProfileCreated=='1')"
                                        [routerLink]="['/professional_accounts',account._id]" class="btn btn-info mr-2"
                                        matTooltip="View"><i class="fa fa-eye" aria-hidden="true"></i></button>
                                    <ng-container
                                        *ngIf="(account.admin_status=='Approved' || account.admin_status=='Rejected'); else manageUser">
                                        <button (click)="delete(account, i)" class="btn btn-danger" matTooltip="Delete">
                                            <i class="fa fa-trash" aria-hidden="true"></i></button>
                                    </ng-container>

                                    <ng-template #manageUser>
                                        <ng-container *ngIf="(account.ProfileCreated=='1'); else infoNotProvided">
                                            <button (click)="manageAccount(account,'Approved')" matTooltip="Approve"
                                                class="btn btn-success mr-2"><i class="fa fa-check"
                                                    aria-hidden="true"></i></button>
                                            <button (click)="manageAccount(account,'Rejected')" class="btn btn-danger"
                                                matTooltip="Delete"> <i class="fa fa-trash"
                                                    aria-hidden="true"></i></button>
                                        </ng-container>

                                        <ng-template #infoNotProvided>
                                            <span class="text-danger">Info Not Provided</span>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="accounts.length==0">
                            <td colspan="100%">No data found!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6" [hidden]="!rejectingAccount">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <div class="row">
                    <mat-label class="col-sm-6">Reason of Rejection*</mat-label>
                    <textarea class="col-sm-6" rows="6" [(ngModel)]="rejectReason"></textarea>

                    <span *ngIf="rejectSubmitted && (!rejectReason || rejectReason.trim()=='')" class="text-danger">
                        Please enter reason of rejection.
                    </span>
                    <span *ngIf="rejectSubmitted && (rejectReason && rejectReason.trim()!='' && rejectReason.length<20)"
                        class="text-danger">
                        Please enter minimum 20 characters.
                    </span>
                </div>

                <hr>

                <div class="row text-center">
                    <button class="col-sm-4 btn btn-success mr-2" (click)="rejectAccount()">Reject</button>
                    <button class="col-sm-4 btn btn-danger" (click)="rejectingAccount=null">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>