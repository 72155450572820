import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrdersService } from 'src/app/services/orders.service';


@Component({
  selector: 'app-cancel-orders',
  templateUrl: './cancel-orders.component.html',
  styleUrls: ['./cancel-orders.component.css']
})
export class CancelOrdersComponent implements OnInit {

  products: any[] = [];
  
  route : Routes = new Routes();

  constructor( public orderService:OrdersService,public dialog: MatDialog,) { }

  ngOnInit(): void {
     this.getCancelOrders();

     
  }

  getCancelOrders(){
    this.orderService.getCancelOrders().subscribe((resp : any)=>{
      if(resp.status ==200){
           this.products = resp.data;
           $(document).ready(function() {
            $('#example').DataTable();
            });
      }
    });
  }


 

}

