import { Router } from '@angular/router';
import { Browser } from '@syncfusion/ej2-base';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit,Input,AfterViewInit } from '@angular/core';
import {FormArray,FormBuilder,FormGroup,Validators,} from "@angular/forms";
import { ShopAndProductService } from '../service/shop-and-product.service';
import { noWhitespaceValidator } from 'src/app/_validators/noWhitespaceValidator';
import { Routes } from 'src/app/models/routes';


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit,AfterViewInit {

  priceSubscribsion : string[] = ['Price for 30 days subscription', 'Price for 45 days subscription','Price for 60 days subscription'];
  sizes: any = ['XL', 'L', 'M', 'S','6','6.5','7','7.5','8','8.5','9','9.5','10','10.5','11','12','13','14','15']
  numbers = ["0","1","2","3","4","5","6","7","8","9","."];
  previewImages : string[] = [];
  categories : any[] = [];
  productForm : FormGroup;
  files : any[] = [];
  cValue: string = "";
  submitted : boolean = false;
  categoryname;
  currentColorIndex: number = -1;
  route : Routes = new Routes();

  constructor(private _shopAndProductService : ShopAndProductService, private _fb : FormBuilder,private router: Router,private _activateRoute : ActivatedRoute,) { }

  ngOnInit(): void {
    this.getProducts()
  }

  getProducts() {
    this._shopAndProductService.getProducts().subscribe((response:any) => {
      if(response.totalRecords>0) {
        this.router.navigate([`/${this.route.updateProduct}/${response.data[0]._id}`])
      } else {
        this.getAllCategories();
        this.productForm = this._fb.group({
          category : ['', Validators.required],
          name : ['', [Validators.required, Validators.maxLength(50),noWhitespaceValidator]],
          price : ['', [Validators.required,noWhitespaceValidator]],
          description : ['', [Validators.required,noWhitespaceValidator]],
          images : [[], [Validators.required,Validators.minLength(1), Validators.maxLength(8)]],
          quantity: ['', [Validators.required,noWhitespaceValidator]],
          length: ['', [Validators.required,noWhitespaceValidator]],
          width: ['', [Validators.required,noWhitespaceValidator]],
          heigth: ['', [Validators.required,noWhitespaceValidator]],
          weigth: ['', [Validators.required,noWhitespaceValidator]],

          colors:this._fb.array(
            [],
            [Validators.maxLength(10)]
          ),
          size:[[]],
        });
      }
    }) 
  }

  ngAfterViewInit(){}
    get value(): string {
        if (Browser.info.name === 'msie' && this.cValue.length > 7) {
            return this.cValue.substring(0, this.cValue.length - 2);
        } else {
            return this.cValue;
        }
    }
    
    @Input('value')
    set value(value: string) {
        this.cValue = value;
    }
  
  onFileChange(event : any){
    console.warn("HI")
    if(event.target.files && event.target.files.length) {
      const files =event.target.files;
      if((files.length+this.files.length)>8){
         alert("Max Limit of Images is 8")
      } else{
        for(var i = 0; i<files.length; i++){
          const file = files[i];
          const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload =  () => {
            const blob =  reader.result as string;
            this.previewImages.push(blob);
          };
          this.files.push(file);
        }
      //  console.log(this.files);
        let images = this.productForm.controls.images.value as Array<File>;
        Array.from(files).forEach((file : File) => images.push(file));
       // console.log(images);
        this.productForm.controls.images.setValue(images);
      }  
    }
  }

  onlyNumber(event : KeyboardEvent){
    const key = event.key;
    const price = this.productForm.controls.price.value;
    if(key==='.')
       return String(price).indexOf(key) > -1 ? false : true;
    return this.numbers.includes(key);
  }

  getAllCategories(){
    this._shopAndProductService.getCategories('').subscribe((resp : any)=>{
      if(resp.status ==200){
        this.categories = resp.data;
        if(this.categories.length > 0) {
          this.productForm.controls.category.setValue(this.categories[0]._id);        
        }
      }
    })
  }

  get f(){
    return this.productForm.controls;
  }

  removeImage(index : number){
     console.log(index);
     let images = this.productForm.controls.images.value as Array<File>;
     console.log(images)
     images.splice(index,1);
    // images.sp
     console.log(images);
     this.productForm.controls.images.setValue(images);
     this.previewImages.splice(index,1);
     this.files.splice(index,1);
  }


  get colors() {
    return this.productForm.get("colors") as FormArray;
  }

  addQuestion() {
    // console.log(this.questions.length)
    const question = this._fb.group({
      p_color: [""],
      
    });
    
    this.colors.push(question);
    this.currentColorIndex = this.colors.length - 1;
    
  }

  
  removeColor(colorIndex: number) {
    this.colors.removeAt(colorIndex);
  }


  addProduct(event : any){
    this.submitted = true;
     if(this.productForm.valid){
      const product =  this.productForm.value;
      const category_id = this.productForm.value.category 

      this._shopAndProductService.addProduct(product).subscribe((resp : any)=>{
          if(resp.status == 200){
            this.productForm.reset();
            this.files = [];
            this.previewImages = [];
            this.submitted = false;
          }
          this.router.navigate(['/products/',category_id]);

      });
     }else{
       const imageErrors =  this.productForm.controls.images.errors;
       console.log(imageErrors);
       if (imageErrors?.required || imageErrors?.minlength) {
          alert("Image is required");
       }
     }
  }

}
