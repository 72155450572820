import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdminAddressService {

  apiBaseUrl : string = environment.url;

  constructor(private _http : HttpClient) { }

  

addAddress(product : any){
  
  return this._http.post(`${this.apiBaseUrl}v1/admin/address`, product);
}

getAddress(){
  
  return this._http.get(`${this.apiBaseUrl}v1/admin/address`);
}

deleteAddress(){
  
  return this._http.delete(`${this.apiBaseUrl}v1/admin/address`);
}
}
