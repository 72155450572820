import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appMaxLimit]'
})
export class MaxLimitDirective {

  @Input() appMaxLimit = 0;

  constructor() { }

  @HostListener('keypress',['$event']) onMouseEnter(event : any) {
       return event.target.value.length<this.appMaxLimit;
  }

}
