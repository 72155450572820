import Quill from 'quill';
import { Routes } from 'src/app/models/routes';
import { EditorChangeContent } from 'ngx-quill';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BlogService } from 'src/app/services/blog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-update-blog',
  templateUrl: './update-blog.component.html',
  styleUrls: ['./update-blog.component.css']
})
export class UpdateBlogComponent implements OnInit {

  blogForm : FormGroup

  submitted : boolean = false;

  blogId : string = undefined;

  blogImage : string = '';
  isFileChanged : boolean = false;
  baseUrl = environment.url;

  fileTypes = ["image/jpeg","image/png","image/jpg","image/tiff","image/gif"];

  route : Routes = new Routes();
  constructor(private _fb : FormBuilder, private _blogService : BlogService, 
    private _activateRoute : ActivatedRoute, private _router : Router) { 
  }

  ngOnInit(): void {
    this.blogId = this._activateRoute.snapshot.paramMap.get('id');
    // alert(this.blogId);
     this.blogForm = this._fb.group({
        title : ['', [Validators.required, Validators.maxLength(60)]],
        blogCategory:['',Validators.required],
        description : ['', Validators.required],
        short_description : [''],
        image : [''],
        author: ['', Validators.required],
        bio:['',Validators.required]
     })
     this.getBlogByBlogId(this.blogId);
  }

  get f(){
    return this.blogForm.controls;
  }

  getBlogByBlogId(blogId : string){
        this._blogService.getBlogById(blogId).subscribe((response : any) =>{
           const blog = response.data;
           this.blogImage = blog.image;
           this.blogForm.setValue ({
             title : blog.title,
             description : blog.description,
             blogCategory : blog.blogCategory,
             short_description : blog.short_description,
             author:blog.author,
             bio:blog.bio,
             image : ''
           })
        })
  }

  created(event: Quill) {
    // tslint:disable-next-line:no-console
    console.log('editor-created', event)
  }

  contentChanged(event : EditorChangeContent){
     // console.log(event.text.length)
     // if(event.text.length<=102)
     //    this.blogForm.controls.short_description.setValue(event.text.replace('\n',' ').trim());
         this.blogForm.controls.short_description.setValue(event.text.substring(0,102).replace('\n',' ').trim());
  }

   onFileChange(event : any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
     const fileType=file.type;
    if(this.fileTypes.includes(fileType)){
         this.blogForm.controls.image.setValue(file);
         reader.onload = () =>{
           this.blogImage = reader.result as string;
           this.isFileChanged = true;
         }
    }else{
      alert("only "+this.fileTypes.toString()+" format supports");
    }
    }
  }

  updateBlog(){
      this.submitted = true;
      if(this.blogForm.valid){
            const blog = this.blogForm.value;
            const formData = new FormData();
            formData.append('title',blog.title);
            formData.append('blogCategory',blog.blogCategory);
            formData.append('description', blog.description);
            formData.append('short_description', blog.short_description);
            formData.append('author', blog.author);
            formData.append('bio', blog.bio);
            if(blog.image)
                formData.append('image', blog.image);
            this._blogService.updateBlog(this.blogId,formData).subscribe((response : any) =>{
               if(response.status==200){
                   this._router.navigate([this.route.blogs])
               }
                    
            })
      }
      else{
        if(this.blogForm.controls.title.errors?.maxlength){
           alert("Title must be less than or equal to "+this.blogForm.controls.title.errors.maxlength.requiredLength+" characters");
        }
     }
  }


}
