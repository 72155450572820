import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  apiBaseUrl : string = environment.url;

  constructor(private _http : HttpClient) { }

  getActiveOrders(){
    return this._http.get(this.apiBaseUrl+'v1/active-order-admin');
 }


 getCancelOrders(){
  return this._http.get(this.apiBaseUrl+'v1/cancel-order-admin');
}

getCompltedOrders(){
  return this._http.get(this.apiBaseUrl+'v1/past-order-admin');
}


getLatestOrders(){
  return this._http.get(this.apiBaseUrl+'v1/recent-order-admin');
}



getReturnOrders(){
  return this._http.get(this.apiBaseUrl+'v1/return-orders');
}

getOrderDetail(Id : string){
  return this._http.get(this.apiBaseUrl+'v1/order/'+Id);
}

changeStatus(Id : string,data:any){
  return this._http.post(this.apiBaseUrl+'v1/order/'+Id ,data);
}

getRefund(Id : string,product:any){
  return this._http.put(this.apiBaseUrl+'v1/order/'+ Id +'/refund',product);
}

}
