import { Router } from '@angular/router';
import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrdersService } from 'src/app/services/orders.service';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-active-orders',
  templateUrl: './active-orders.component.html',
  styleUrls: ['./active-orders.component.css']
})
export class ActiveOrdersComponent implements OnInit {

  products :any[] = []
  total;
  route : Routes = new Routes();
  selectControl : FormControl = new FormControl();
  productForm : FormGroup;
  haveselected="false";
  constructor( public orderService:OrdersService, private _fb : FormBuilder,private router: Router,public dialog: MatDialog, private ngxBootstrapConfirmService: NgxBootstrapConfirmService) { }

  ngOnInit(): void {
     this.getActiveOrders();
     this.productForm = this._fb.group({
      selectControl : [''],
     
      
     });
     this.selectControl.setValue('')
     
  }

  get f(){
    return this.productForm.controls;
  }

  getActiveOrders(){
   
    this.orderService.getActiveOrders().subscribe((resp : any)=>{
      if(resp.status ==200){

           this.products = resp.data;
           console.log("resp.data;-------------",resp.data)
           $(document).ready(function() {
            $('#example').DataTable();
            } );
      }
      for(let j=0;j<this.products.length;j++){   
        this.total=(this.products[j].price) +  (this.products[j].shipping_charge)

   }

    } );

  }


  test(id:any){
    this.haveselected="false";
    console.log(this.selectControl.value); 
    if(this.selectControl.valid){
     const product =  {
       "status":this.selectControl.value
      }
     let options ={
      title: 'Are you sure you want to change status of this Order?',
      confirmLabel: 'Yes',
      declineLabel: 'No'
    }
    this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
      if (res) {
        this.orderService.changeStatus(id,product).subscribe((resp : any) =>{
          if(resp.status == 200){

              this.getActiveOrders();
              this.selectControl.setValue('')

          }
      }
      )
    }
    });
  }}

}