import { PostService } from '../services/post.service';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit {


  //can be managed in one component but datatables
  @Input() type="Professional" //Client
  webUrl=environment.web
  urlAppend='big-me-details'
  posts=[]
  constructor(
    private postService:PostService,private snackbarService:SnackbarService) { }

  ngOnInit(): void {
    this.getPosts()

    this.urlAppend=this.type=='Client' ? 'big-me-details' : this.urlAppend
  }

  getPosts() {
      this.postService.getByType(this.type).subscribe((response:any) => {
        this.posts=response.postsWithViewsAndDaysAgo;

        $(document).ready(function () {
          $('#example').DataTable();
        });
      })
  }

  enable(post:any,status:"DEL"|"ACT") {
    this.snackbarService.alertWithConfirmations(`Are you sure you want to ${post.status == 'ACT' ? 'disable' : 'enable' } it?`).then((result) => {
      if (result.isConfirmed) {
        this.postService.enableDisable(post._id, status).subscribe((response: any) => {
          post.status = status;
        })
      }
    })
  }

  showContent(content) {
    this.snackbarService.show(content,'')
  }

  deletePost(post) {
    this.snackbarService.alertWithConfirmations("Are you sure you want to delete this post?").then(response => {
      if (response.isConfirmed) {
        this.postService.deletemedAndme(post._id).subscribe(response => {
          this.snackbarService.successSnackBar("Post deleted successfully", null)
          this.getPosts()
        })
      }
    })
  }

}
