<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card card-border">

            <div class="card-body" id="example_wrapper">
                <table id="example" *ngIf="posts" datatable class="display world-post-data">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date & Time</th>
                            <th>Title</th>
                            <th>Webinar Language</th>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor=" let i=index;let post of posts">
                            <td>{{ i+1 }}</td>
                            <td>{{ post.date | date:'yyyy-MM-dd HH:mm:ss aa' }}</td>
                            <td>{{ post.title |titlecase }}</td>
                            <td>{{ post.language | titlecase }}</td>
                            <td class="category-area">{{ post.big_categories.name }}</td>
                            <td>
                                <p class="description-data">{{ post.description |titlecase }}</p>
                                <a *ngIf="post.description.length > 70" (click)="showDescription(post.description)"
                                    class="view-data" data-toggle="modal" data-target="#staticBackdrop">View more</a>
                            </td>
                            <td>
                                <ng-container>
                                    <a *ngIf="post.status=='DEL'" href="javascript:void(0);" class="btn btn-success"
                                        (click)="enable(post, 'ACT')">Enable</a>
                                    <a *ngIf="post.status=='ACT'" href="javascript:void(0);" class="btn btn-danger"
                                        (click)="enable(post, 'DEL')">Disable</a>
                                </ng-container>
                            </td>
                            <td>
                                <button [routerLink]="['/posts/world/'+post.webinarSlug]" class="view-detail-btn"
                                    matTooltip="View"><span class="fa fa-eye"></span> </button>
                                <button class="btn btn-danger ml-2" (click)="delete(post)" matTooltip="Delete"><span
                                        class="fa fa-trash"></span> </button>
                            </td>
                        </tr>
                        <tr *ngIf="posts.length==0">
                            <td colspan="100%">No data found!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Webinar Description</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{description}}</p>
            </div>
        </div>
    </div>
</div>