<button type="button" class="btn btn-success" *ngIf="isAddress=='null'" routerLink="/add-address"  style="margin-bottom: 30px;">Add Address</button>
<button type="button" class="btn btn-success"  *ngIf="isAddress!='null'" style="margin-bottom: 30px;" (click)="deleteAddress()">Delete Address</button>


<div class="categoryname"> Address</div>

<div class="row" >
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
   <div class="card card-border">
    <div class="card-body" id = "example_wrapper" >
          <form [formGroup] = "productForm" *ngIf="isAddress!='null'">
           <div class="row">
               
               <div class="col-sm-4">
                <div class="form-group">
                    <label for="name" class="required">Name:</label>
                    <input id="name" maxlength="35"    formControlName="name"  class="form-control" placeholder="Enter name" readonly>
                    
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label for="phone" class="required">Phone:</label>
                    <input id="phone" maxlength="15" formControlName="phone"  class="form-control" placeholder="Enter phone" readonly >
                    
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="country" class="required">Country</label>
                    <input id="country" maxlength="20"  formControlName="country" class="form-control" placeholder="Enter country" readonly>
                    
                </div>
            </div>             
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="state" class="required">State</label>
                    <input id="state" maxlength="17" formControlName="state"  class="form-control" placeholder="Enter state" readonly>
                    
                </div>
            </div> 
            
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="city" class="required">City</label>
                    <input id="city"  formControlName="city"  class="form-control" placeholder="Enter city" readonly>
                    
                </div>
            </div>  

            <div class="col-sm-4">
                <div class="form-group">
                    <label for="zip_code" class="required">Zip Code</label>
                    <input id="zip_code"  formControlName="zip_code"  class="form-control" placeholder="Enter Zip code" readonly>
                    
                </div>
            </div> 

            

            
            

           
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="address" class="required">Address:</label>
                    <textarea id="address"  formControlName="address" class="form-control" placeholder="Enter address " rows="3" readonly></textarea>                    
                </div>
            </div>
        </div>   


        <div class="col-sm-4">
            <div class="form-group">
                <label for="street1" >Street 1</label>
                <input id="street1" maxlength="200" formControlName="street1"  class="form-control" placeholder="Enter Street1" readonly >
                
            </div>
        </div>  


        <div class="col-sm-4">
            <div class="form-group">
                <label for="street2" >street 2</label>
                <input id="street2" maxlength="200" formControlName="street2" class="form-control" placeholder="Enter Street2" readonly>
               
            </div>
        </div>  
           </div>



          
         
      </form>
      <p *ngIf="isAddress=='null'">No Address</p>
    </div>
   </div>
  </div>
</div>
