
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          
            <div class="card-body">

                 <!-- <form [formGroup] = "formdata" (ngSubmit) = "addExerciseVideos(formdata.value)" enctype="multipart/form-data"> -->
                   <form [formGroup] = "formdata" >

                   <div class="form-group"> 
                    <label for="Name" class="required">Upload Sound:</label>
                    
                   <input type="file" class="form-control" id="inputDuration"  formControlName='soundFile' name="myfile" (change)="uploadInstallerImage($event)" placeholder="Add Image"/>
                   <div *ngIf="uploaded"> Sound has been uploaded. </div>

                    <div *ngIf=  "submitted && f.soundFile.errors" class="invalid-feedback"> Sound is required </div>
                     <div *ngIf="unvalidImgTypeMsg" style="font-size: 12px; color:red">Only mp3 is allowed</div>
                 </div>
                  <div class="form-group">
                    <label for="pwd" class="required">Sound Name:</label>
                    <input type="text" formControlName='soundName' class="form-control" id="inputDuration" placeholder="Enter Sound Name" name="category" [ngClass]="{ 'is-invalid': submitted && f.soundName.errors}">
                  </div>   
                  <div class="form-group">
                    <label for="pwd" class="required">Sound Type:</label>
                    <!-- <input type="text" formControlName='soundType' class="form-control" id="inputDuration" placeholder="Enter Sound Type" name="type" [ngClass]="{ 'is-invalid': submitted && f.soundType.errors}"> -->
                       <select class="form-control" formControlName='soundType' [ngClass]="{ 'is-invalid': submitted && f.soundType.errors}">
                        <option>Classical</option>
                        <option>Spiritual</option>
                        <option>Meditation</option>
                      </select>
                  </div>
                  <div class="justify-content-center d-flex mt-4">
                  <div >
                                  <button type="submit" class="btn btn-submit mr-3" (click) = "addExerciseVideos(formdata.value)">Submit</button>
                              </div>

                                 <!-- <ng-template #elseTemplate>
                                  <button type="submit" class="btn btn-submit mr-3"  disabled>Submit</button>
                                 </ng-template> -->
                  <button  class="btn btn-danger" routerLink="/stress-management">Cancel</button>
                  </div>
                </form>
            </div>
           
          </div>
        </div>
   