import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  apiBaseUrl : string = environment.url;

  constructor(private _http : HttpClient) { }

  getQuizzes(){
     return this._http.get(this.apiBaseUrl+'v1/quiz');
  }

  getQuizById(quizId : string){
    return this._http.get(this.apiBaseUrl+'v1/quiz/'+quizId);
  }

  getQuizByLocation(location : string){
    return this._http.get(this.apiBaseUrl+'v1/quiz?location'+location);
  }

  addQuiz(quiz : any){
    return this._http.post(this.apiBaseUrl+'v1/quiz', quiz);
  }

  deleteQuizById(quizId : string){
    return this._http.delete(this.apiBaseUrl+'v1/quiz/'+quizId);
  }

  updateQuiz(quiz : any, quizId : string){
    return this._http.put(this.apiBaseUrl+'v1/quiz/'+quizId, quiz);
  }

  quizAnswerImage(formData : FormData) {
    return this._http.post(this.apiBaseUrl+'quizAnswerImage', formData);
  }

  renderOnErrorQuestion(quiz : any,index:any){
    var response = { currentQuestionIndex : index, answerLength : -1}
    for (let index = 0; index < quiz.questions.length; index++) {
      const question = quiz.questions[index];
      if(question.title==='' || question.title===undefined) {
       response.currentQuestionIndex = index;
       break;
      }
      else if(question.answers.length===0){
          response.currentQuestionIndex = index;
          response.answerLength = 0;
          break;
      }
      else{
            const answers = question.answers;
            let answerIndex = -1;
            for (let i = 0; i < answers.length; i++) {
              const answer = answers[i];
              if(answer.value ==='' || answer.value === undefined){
                 answerIndex = answerIndex+1;
                 break;
             } 
            }
            if(answerIndex > -1){
             response.currentQuestionIndex = index;
             break;
            }
      }
      
    }

    return response;
  }

  isNoAnswerSelectedAsCorrect(quiz : any){
     var questionIndex = -1;
     const questions =  quiz.questions;
     for (let index = 0; index < questions.length; index++) {
       const question = questions[index];
       const answers = question.answers;
       var hasCorrectAnswer = false;
       for (let i = 0; i < answers.length; i++) {
         const answer = answers[i];
         if(answer.isRight){
              hasCorrectAnswer = answer.isRight;
              break;
         }  
       }

       if(!hasCorrectAnswer){
           questionIndex = index;
           break;
       }
     }
     return questionIndex;
  }

  getQuizRewards(){
    return this._http.get(this.apiBaseUrl+'v1/reward');
  }

  updateReward(reward : any){
    return this._http.put(this.apiBaseUrl+'v1/reward', reward);
  }
}
