<!doctype html>
<html lang="en">
  <head>
    <title>Big4Health</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <link rel="icon" href="https://res.cloudinary.com/appsmaven/image/upload/v1588578035/Big4Health/Logo_ww5feu.png" type="image/gif" sizes="16x16">
    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css">
    <style>
      h1
      {
        font-size: 25px;
      }
    	p
    	{
    		text-align: left;
    	}
      ol
      {
        text-align: justify;
      }
      td
      {
        text-align: justify;
      }
      h4
      {

        margin: 20px 0px;
        font-size: 22px;
      }
      h4 span
      {
        color: #538135;
        line-height: 38px;
      }
      h5
      {
        margin: 15px 0px 0px;
      }
       
       a
       {
        text-decoration: underline;
        word-break: break-all;
       }
       .download-text 
       {
        color: #002099;
        font-weight: bold;
        text-align: center;
       }
       .download-text span
       {
        color: #00b0f0;
       }
       
 
    </style>
  </head>
  <body>

	<div class="container">
 <h1 class="mt-4">About Us</h1>


<h4>All started with a campaign- <span>“Know Your BIG4”</span></h4>

<p>Back in February 2014, a physician recognized as a “Champion of Health and Wellness” started a campaign to create awareness of heart disease, high blood pressure, diabetes, and cancer. He led the campaign for four months and screened thousands of patients, health care providers, and staffs. Later, his compassion engaged many in a new way of eating, drinking, exercising, stress management, and socializing in a seamless way.</p>

<p>Now, the inspiration has turned into a worldwide ”All-In-One” mobile technology that can connect millions of patients to health care providers; a mobile technology that can help millions of users prevent and improve the BIG4: Diabetes, High Blood Pressure, High Cholesterol, and Obesity; a tool that your business can use to decrease health insurance cost. The BIG4 Health App is helping to decrease patient readmissions to the emergency room (ER) and saving health care cost. The technology empowers you and your loved ones to take control of your life while still enjoying every minute.</p>

<p>The BIG4 Health App engages you and your entire family to successfully transition to a healthier lifestyle. This includes eating healthy foods, exercising daily, smart sleeping habits, improved stress management, healthy relationships, and avoiding risky substances. You can more easily understand individual health and make key lifestyle changes.</p>

<p>Today, your lifestyle may become your medicine.</p>


<p class="download-text"><span>JUST</span> DOWNLOAD IT!</p>

	</div>
  </body>
</html>