import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: any[], field : string): any[] {
    return value.sort((a,b)=> a[field].toLowerCase() > b[field].toLowerCase() ? 1 : ((a[field].toLowerCase() < b[field].toLowerCase()) ? -1 : 0 ));
  }

}
