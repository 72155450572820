<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <table id="example" *ngIf="posts" datatable class="display">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>User</th>
                            <th>Time</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Action</th>
                            <th>View</th>
                            <!-- <th>Navigate to Post</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let post of posts, let i=index">
                            <td>{{ i+1 }}</td>
                            <td>{{ post.user?.name }}</td>
                            <td>{{ post.timeAgo }}</td>
                            <td>{{ post.title }}</td>
                            <td class="med-description-clamp">
                                <p class="description-data"> {{ post.description }}</p>
                                <a *ngIf="post.description.length > 20" (click)="showContent(post.description)"
                                    class="view-data">View more</a>
                            </td>
                            <td>
                                {{ post.status === 'ACT' ? 'Enabled' : 'Disabled' }}
                              </td>

                            <td>
                                <ng-container>
                                    <a *ngIf="post.status=='DEL'" href="javascript:void(0);" class="btn btn-success"
                                        (click)="enable(post, 'ACT')">Enable</a>
                                    <a *ngIf="post.status=='ACT'" href="javascript:void(0);" class="btn btn-danger"
                                        (click)="enable(post, 'DEL')">Disable</a>
                                </ng-container>
                                <button class="btn btn-danger ml-2" (click)="deletePost(post)" matTooltip="Delete"><span
                                  class="fa fa-trash"></span> </button>
                            </td>
                            <!-- <td> -->
                              <!-- <button [routerLink]="['/posts/world/'+post.webinarSlug]" class="view-detail-btn"
                                  matTooltip="View"><span class="fa fa-eye"></span> </button> -->

                          <!-- </td> -->
                            <td>
                                <a [href]="webUrl+urlAppend+'?id='+post._id" target="_blank" class="btn btn-info ml-2"
                                    matTooltip="Navigate to Post">
                                    <span class="fa fa-eye"></span>
                                </a>
                            </td>
                        </tr>
                        <tr *ngIf="posts.length==0">
                            <td colspan="100%">No data found!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
