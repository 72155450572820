

  <button type="button" class="btn btn-success" routerLink="/e-learning/AddVideos/{{ categoryId }}"  style="margin-bottom: 30px;">Add Video</button>
  <div class="categoryname"> {{getcategory |titlecase}}</div>
 
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example" datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Category Name</th>
                      <th>Video</th>
                      <th>Action</th>                      
                  </tr>
              </thead>
             <tbody >
             
                <tr *ngFor="let v of allVideos; let i = index">
                    <td >{{i+1}}</td>
                    <td>{{getcategory}}</td>
                    <td><video width="220" height="160" controls (playing)="onPlayingVideo($event)">
                        <source src={{SERVER_URL+v.url}} type="video/mp4">
                      </video></td>  
                      <td> 
                     <mat-icon class="marginLeft10" color="warn" aria-hidden="false" aria-label="delete icon" matTooltip="delete video" (click)="deleteVideos(categoryId,v._id)">delete</mat-icon>
                     <mat-icon class="marginLeft10" color="primary" aria-hidden="false" aria-label="edit icon" matTooltip="edit video" (click)="updatevideo(categoryId)">edit</mat-icon> 

                    </td>
                </tr>
                <!-- <tr class="odd"><td valign="top" colspan="4" class="dataTables_empty">No videos available</td></tr> -->
           
             </tbody>

             
            </table>
      </div>
     </div>
    </div>
  </div>