import * as $ from 'jquery';
import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChargesService } from '../services/charges.service';

@Component({
  selector: 'app-charges-managemenrt',
  templateUrl: './charges-management.component.html',
  styleUrls: ['./charges-management.component.css']
})
export class ChargesManagementComponent implements OnInit {

  showLoader : boolean = true;
  tax : any[] = [];
  charge;
  route : Routes = new Routes();
  constructor(private ChargesService : ChargesService, public dialog: MatDialog) { }

  ngOnInit(): void {
     this.getAllCharges();
  }
 
  getAllCharges(){
    this.ChargesService.getCharges().subscribe((response : any) => {
      this.showLoader = false;
     // console.log(response);
      this.tax = response.tax;
      this.charge = response.charge;
    $(document).ready(function() {
      $('#example').DataTable();
      } );
  },error => this.showLoader = false);

}
  

// deleteCharges(categoryId : string){
//     const confirm = window.confirm("Are you sure want to delete this category?");
//     if(confirm){
//       this.ChargesService.deleteChargesById(categoryId).subscribe((response : any)=>{
//          //   this.showLoader = false;
//             var index = -1;
//             var loopIndex = -1;
//             this.categories.forEach(quiz => {
//                 loopIndex = loopIndex+1;
//                  if(quiz._id === categoryId){
//                          index = loopIndex;
//                  }        
//             });
//             if(index !==-1)
//                 this.categories.splice(index,1);
//       }, error => this.showLoader = false)
//     }
//   }
}
