
<div class="row" >
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
            <form [formGroup] = "blogForm">
            <div class="row">
                 <div class="col-sm-12">
                  <div class="form-group">
                      <label for="subject" class="required">Subject:</label>
                      <input id="subject" [appMaxLimit]="60" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.subject.errors}" placeholder="Enter subject" formControlName="subject">
                  </div>
            </div>
             </div>
           <div class="row">
              <div class="col-sm-12">
                  <div class="form-group">
                      <label for="description" class="required">Description:</label>
                      <quill-editor [styles]="{height: '200px', border : submitted && f.description.errors ? '1px solid red' : ''}" [ngClass]="{ 'ql-editor-border': submitted && f.description.errors}" formControlName="description" placeholder="Enter Content" (onContentChanged)="contentChanged($event)" (onEditorCreated)="created($event)"></quill-editor>
                  </div>
              </div>
             </div>  

             <div class="row">
                 <div class="col-sm-12">
                  <div class="form-group">
                      <input type="file" id="images"  class="form-control" accept="*" (change)="onFileChange($event)">
                  </div>
              </div>
             </div> 
             <!-- <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <input type="file" id="images" class="form-control" accept="*" (change)="onFileChange($event)">
                </div>
              </div>
            </div> -->
            
    
          <div class="justify-content-center d-flex mt-4">
            <button type="submit" class="btn btn-submit mr-3" (click) ="createBlog()">Submit</button>
          </div>
        </form>
      </div>
     </div>
    </div>
  </div>
  