<h3 mat-dialog-title>{{title}}</h3>
<div mat-dialog-content>
    <p>
        <mat-form-field appearance="outline">
          <mat-label>Medication</mat-label>
          <input matInput placeholder="Enter medication" [formControl]="medication">
          <mat-error *ngIf="medication.invalid">Medication is required</mat-error>
        </mat-form-field>
      </p>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>Close</button>
  <button mat-raised-button color="primary" (click)="submit()">Submit</button>
</div>