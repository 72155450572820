

  <button type="button" class="btn btn-success"  style="margin-bottom: 30px;" routerLink="../{{route.createBlog}}">Add Blog</button>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example"  datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Short Description</th>
                      <th>Author</th>
                      <th>Action</th>                      
                  </tr>
              </thead>
             <tbody>
               <tr *ngFor="let blog of blogs; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{blog.title}}</td> 
                     <td>{{ blog.blogCategory }}</td>                
                     <td>{{ blog.short_description | slice:0:15}}... </td> 
                     <td>{{ blog.author}} </td> 
                     <td>
                      <mat-icon class="marginLeft10" color="primary" aria-hidden="false" aria-label="edit icon" matTooltip="edit blog" (click)="editBlog(blog._id)">edit</mat-icon> 
                      <mat-icon class="marginLeft10" color="warn" aria-hidden="false" aria-label="delete icon" matTooltip="delete blog" (click)="deleteBlog(blog._id)">delete</mat-icon>
                     </td>
                 </tr>
                
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>