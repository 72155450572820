import 'datatables.net';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv';
import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.css']
})
export class AllUsersComponent implements OnInit {

  //  options = {
  //   fieldSeparator: ',',
  //   quoteStrings: '"',
  //   decimalseparator: '.',
  //   headers: ['FIRSTNAME', 'LASTNAME','EMAIL'],
  //   showTitle: true,
  //   useBom: true,
  //   removeNewLines: false,
  //   keys: ['firstName', 'lastName','email'],
  //   title:"Users List"
  // };
  allUsers;
  userCount;
  userId;
  metricData;
  data1;
  sendDataToStatusApi;
  public showLoader: boolean = false;
  apiBaseUrl: string = environment.url;
  usersList = []

  name = 'Angular 6';
  formula: string = "Formula 1";

  constructor(private http: HttpClient, private router: Router) { }
  ngOnInit() {

    this.adminLogin();

  }

  adminLogin() {
    this.showLoader = true;
    let http = new HttpParams()
    http = http.append('userType',"Client")
    this.http.get(this.apiBaseUrl + 'v1/getAllUsers',{params:http}).subscribe((data: any) => {

      if (data.status == 200) {
        this.showLoader = false;
        this.allUsers = data.data;
        this.data1 = this.allUsers;
        this.metricData = data.metric;
        for (let i = 0; i < this.data1.length; i++) {

          this.usersList.push
            ({
              firstName: this.data1[i].firstName,
              lastName: this.data1[i].lastName,
              email: this.data1[i].email,
              age: this.data1[i].age,
              weight: this.data1[i].weight,
              height: this.data1[i].height,
              bmi: this.data1[i].bmi,
              bloodPressureMax: this.data1[i].bloodPressureMax,
              bloodPressureMin: this.data1[i].bloodPressureMin,
              cholesterol: this.data1[i].cholesterol,
              glucose: this.data1[i].glucose,
              loginType: this.data1[i].loginType,
              isActive: this.data1[i].isActive,
            });
        }
        $(document).ready(function () {
          var dTable = $('#example').DataTable();
        });
      }
      else {
        this.allUsers = '';
      }
    })
  }

  openAddUserPage = () => {
    this.router.navigateByUrl('/add-user')
  }


  toggleSwitch(value, id) {
    if (value == "1") {
      this.sendDataToStatusApi = { "status": '0', "userId": id }
    } else if (value == "0") {
      this.sendDataToStatusApi = { "status": '1', "userId": id }
    }
    this.http.post(this.apiBaseUrl + 'v1/acticeInactiveUser', this.sendDataToStatusApi).subscribe((data: any) => {})
  }

  deleteUser(id) {
    if (confirm("Are you sure to want to delete?")) {
      this.userId = { "userId": id };
      this.http.post(this.apiBaseUrl + 'v1/deleteUser', this.userId).subscribe((data: any) => {
        if (data.status == 200) {
          alert("Successfully deleted!")
          this.adminLogin()
        }
      });
    }
  }


  downloadCSV() {
    // this.status = ["approved", "rejected", "pending"];
    var data = this.usersList

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      headers: ['FIRSTNAME', 'LASTNAME', 'EMAIL', 'AGE', 'WEIGHT', 'HEIGHT', 'BMI', 'BP MAX', 'BP MIN', 'CHALESTROL', 'GULCOSE', 'LOGIN TYPE', 'ACTIVE/INACTIVE'],
      showTitle: true,
      useBom: true,
      removeNewLines: false,
      keys: ['firstName', 'lastName', 'email'],
      title: "Users List"
    };

    new Angular2Csv(data, this.formula, options);
  }
}


