import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TrustedLogoService {
    apiBaseUrl: string = environment.url;

  constructor(private http: HttpClient) { }

  getAll(){
    return this.http.get(this.apiBaseUrl+'v1/get-trustedLogo')
  }

  add(data){
    return this.http.post(this.apiBaseUrl+'v1/add-trustedLogo',data)
  }

  edit(id,data){
    return this.http.put(`${this.apiBaseUrl}v1/edit-logo/${id}`,data)
  }

  delete(id){
    return this.http.delete(this.apiBaseUrl+'v1/delete-logo/'+id)
  }
  
}  