import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class OfferService {

  apiBaseUrl : string = environment.url;

  constructor(private _http : HttpClient) { }

  getOffers(){
    return this._http.get(this.apiBaseUrl+'v1/offer');
 }


 getProducts(id : string){
  return this._http.get(this.apiBaseUrl+'v1/admin-all/list');
}

 deleteOfferById(Id : string){
  return this._http.delete(this.apiBaseUrl+'v1/offer/'+Id);
}


addOffer(data:any){
  return this._http.post(`${this.apiBaseUrl}v1/offer`,data );
}


getOfferById(Id : string){
  return this._http.get(this.apiBaseUrl+'v1/offer/'+Id);
}

updateOffer(Id:any,data:any,){
  return this._http.put(this.apiBaseUrl+'v1/offer/'+Id,data);
}
}
