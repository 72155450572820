import { Component, OnInit } from '@angular/core';
import { ContactUsService } from '../services/contact-us.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  contacts=[]
  constructor(
    private contactUsService: ContactUsService,
    private snackbarService:SnackbarService
  ) { }

  ngOnInit(): void {
    this.getContacts()

  }

  getContacts() {
    this.contactUsService.getAll().subscribe((response:any) => {
      this.contacts=response.getContact;

      $(document).ready(function () { $('#example').DataTable(); });
    })
  }

  showContent(content) {
    this.snackbarService.show(content,'')
  }
}
