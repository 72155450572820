<div class="row">
    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-border">
            <div class="card-body">
                <h2 class="card-title">{{ isEdit ? 'Update Trusted Logo' :'Add Trusted Logo'}}</h2>
                <div class="form-group">
                    <label class="required">{{ isEdit ? 'Existing Logo:' :'Select Logo:'}}</label>
                    <img [ngClass]="{ 'show-photo' : !photo }" [src]="photo" height="120px" width="120px" >
                    <input type="file" accept="image/*" (change)="onFileChange($event)" #image class="form-control cursor-pointer" [ngClass]="{ 'is-invalid':  fileInvalid }">

                    <label [class.text-danger]="fileInvalid">*The image dimensions must be 120x120 pixels.</label>
                </div>
                <div class="justify-content-center d-flex mt-4">
                    <button type="button" class="btn btn-submit mr-3" (click)="addStats()">Submit</button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <h2 class="card-title">Added Trusted Logo</h2>
                <table id="example" *ngIf="logoList" datatable class="display table table-bordered">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Logo</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="logoList">
                        <tr *ngFor="let logo of logoList, let i=index">
                            <td>{{ i+1 }}</td>
                            <td>
                                <img [src]="logo.image" height="100px" width="100px">
                            </td>
                            <td>
                                <button class="btn btn-success mr-2" type="button" matTooltip="View" 
                                (click)="edit(logo._id,logo.image)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-danger" type="button"
                                (click)="delete(logo._id)" matTooltip="Delete">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="!logoList">No logo added yet</div>
            </div>
        </div>
    </div>
</div>