import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedInSubject = new BehaviorSubject<boolean>(false);
  loggedIn$ : Observable<boolean> = this.loggedInSubject.asObservable();

  constructor() { }

  saveAdminId(adminId : string){
     localStorage.setItem('id', adminId)
  }

  saveToken(token : string){
    localStorage.setItem('B4HEALTH_ACCESS_TOKEN', token)
 }

 getToken() : string {
  return localStorage.getItem('B4HEALTH_ACCESS_TOKEN')
}

  getAdminId(){
    return localStorage.getItem('id');
  }

  isLoggedIn(){
    const adminId = this.getAdminId();
    return (adminId !==null && adminId !==undefined) ? true : false;
  }

  showNavBar(isLoggedIn : boolean){
      this.loggedInSubject.next(isLoggedIn);
  }

}
