<!--<button type="button" class="btn btn-success" routerLink="/quiz"  style="margin-bottom: 30px;">View All Quiz</button>
-->
<!-- 
<div class="categoryname"> {{categoryname|titlecase}}</div> -->

<div class="row" >
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
            <form [formGroup] = "chargeForm">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="charges" class="required">Charges:</label>
                        <input id="charges" maxlength="7" [ngClass]="{ 'is-invalid': submitted && f.charges.errors}" formControlName="charges"  class="form-control" placeholder="Enter charges" (keypress)="onlyNumber($event)">
                        <div *ngIf="chargeForm.controls.charges.invalid &&  submitted" class="error">
                            <div *ngIf="chargeForm.controls.charges.errors.required">
                                charges required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
                <div class="justify-content-center d-flex mt-4">
                    <button type="submit" class="btn btn-submit mr-3" (click)="updateCharge()" >Submit</button>
                </div>
                
            
        
        </form>
      </div>
     </div>
    </div>
  </div>
  