
  <button type="button" class="btn btn-success" (click)="openAddChallengePage()" style="margin-bottom: 30px;">Add Wellness Challenge</button>

        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
           <div class="card card-border">
            <div class="card-body" id = "example_wrapper">
              <table id="example" *ngIf="allChallenges"  datatable class="display">
                <thead>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Challenge Name</th>
                        <th>Duration</th>
                        <th>Description</th>                                          
                        <th>Created Date</th>                     
                        <th>Action</th>                     
                    </tr>
                </thead>
                <tbody *ngIf="allChallenges">
                
                    <tr *ngFor="let challenge of allChallenges, let i=index">
                       
                        <td>{{i+1}}</td>
                        <td>{{challenge.challengeName}}</td>
                        <td>{{challenge.timeDuration }} {{ challenge.timeDurationIn }}</td>                        
                        <td>{{challenge.description }}</td>     
                        <td>{{challenge.createdOn | date}}</td>
                          
                        <td>
                            <a href="/edit-challenge/{{challenge._id}}" class="btn btn-info mr-2" matTooltip="Update" matTooltipPosition="right">
                              <span class="fa fa-edit"></span>
                            </a>

                            <a href="javascript:void(0);" (click)="deleteChallenge(challenge._id,i)" class="btn btn-danger" matTooltip="Delete" matTooltipPosition="right">
                              <span class="fa fa-trash"></span>
                            </a>
                          </td>
                      </tr>  
                  
                   
                </tbody>
                
              </table>
             <div *ngIf="!allChallenges">No challange added yet</div>
            </div>
            
           </div>
          </div>
        </div>

