import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ELearningService {

  apiBaseUrl = environment.url;

  constructor(private _http : HttpClient) { }


  createCategory(name: any) {
    return this._http.post(`${this.apiBaseUrl}v1/e-learning/add-category`,name);
    
  }

  getCategories(){
    return this._http.get(this.apiBaseUrl+'v1/e-learning/all-categories');
 }

 deleteCategoryById(categoryId : string){
  return this._http.delete(this.apiBaseUrl+'v1/e-learning/delete-category/'+categoryId);
}

updateCategoryById(name: string,categoryId : string){
  return this._http.put(this.apiBaseUrl+'v1/e-learning/'+categoryId, {name});
}


getVideosByCategory(categoryId : string){
  return this._http.get(this.apiBaseUrl+'v1/e-learning/'+categoryId);
}

deleteVideoById(id){
  return this._http.delete(this.apiBaseUrl+'v1/e-learning/delete-video/'+id);
}

getVideos(){
  let http = new HttpParams()
  http = http.set("source","admin")
  return this._http.get(this.apiBaseUrl+'v1/e-learning/list',{params:http});
}

AddVideo(data:any) {
  return this._http.post(this.apiBaseUrl+'v1/e-learning/post',data);
}

getvideoDetailId(videoId:string){
  return this._http.get(this.apiBaseUrl+'v1/e-learning'+videoId);
}
}
