<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <table id="example" datatable class="display">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Subject</th>
                            <th>Description</th>
                            <th>Attachments</th>
                            <th *ngIf="emails">Receiver's Count</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="emails">
                        <tr *ngFor="let notification of emails, let i=index">
                                <td>{{i+1}}</td>
                                <td>{{ notification.emails[0].createdAt | date: 'dd-MM-yyyy hh:mm aa' }}</td>
                                <td>{{ notification.emails[0].subject }}</td>
                                <td class="med-description-clamp">
                                    <div [innerHTML]="notification.emails[0].content" class="description-data"></div>
                                    <div> <a *ngIf="notification.emails[0].content.length > 30" (click)="showContent(notification.emails[0].content)" class="view-data">View more</a></div>
                                </td>
                                <td>
                                    <a class="btn btn-info" [href]="baseURL+notification.emails[0].attachments" target="_new" matTooltip="View Attachments" matTooltipPosition="right">
                                        <span class="fa fa-eye"></span>
                                    </a>
                                </td>
                                <td>
                                    <a (click)="showEmails(notification)" class="btn btn-info" data-toggle="modal" data-target="#showUsersEmails">{{ notification.emails?.length }}</a>
                                </td>
                                <td>
                                    <button class="btn btn-danger" (click)="deleteMail(notification.emails)" > 
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </td>
                            
                        </tr>
                        <tr *ngIf="emails.length==0">
                            <td colspan="100%">No data found!</td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="replies">
                        <tr *ngFor="let notification of replies, let i=index">
                                <td>{{i+1}}</td>
                                <td>{{ notification.createdAt | date: 'dd-MM-yyyy hh:mm aa' }}</td>
                                <td>{{ notification.subject }}</td>
                                <td class="med-description-clamp">
                                    <div [innerHTML]="notification.content" class="description-data"></div>
                                    <div> <a *ngIf="notification.content.length > 30" (click)="showContent(notification.content)" class="view-data">View more</a></div>
                                </td>
                                <td>
                                    <a class="btn btn-info" [href]="baseURL+notification.attachments" target="_new" matTooltip="View Attachments" matTooltipPosition="right">
                                        <span class="fa fa-eye"></span>
                                    </a>
                                </td>
                                <td>
                                    <button class="btn btn-danger" (click)="deleteMail(notification._id)" > 
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </td>
                            
                        </tr>
                        <tr *ngIf="replies.length==0">
                            <td colspan="100%">No data found!</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="showUsersEmails" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Receiver's Email</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="listing-area">
                    <li *ngFor="let email of currentGroupEmails">{{ email }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>