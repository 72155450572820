<div class="categoryname"> {{categoryname|titlecase}}</div>

<div class="row" >
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
   <div class="card card-border">
    <div class="card-body" id = "example_wrapper">
          <form [formGroup] = "productForm">
           <div class="row">
               
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="total_amount" class="required">Total Amount:</label>
                        <input id="total_amount" maxlength="7"   readonly formControlName="total_amount" [ngClass]="{ 'is-invalid': submitted && f.total_amount.errors}" class="form-control" placeholder="Enter total amount">
                    
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="percent" class="required">Percent Of Refund to Admin</label>
                        <input id="percent" maxlength="2"  formControlName="percent" [ngClass]="{ 'is-invalid': submitted && f.percent.errors}" class="form-control" placeholder="Enter percentage of refund " (keypress)="onlyNumber($event)" (keyup)="total_refund($event)">
                        <div *ngIf="productForm.controls.percent.invalid &&  submitted" class="error">
                            <div *ngIf="productForm.controls.percent.errors.required">
                                Please enter percent
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="refund" class="required">Refunded Amount to Client</label>
                        <input id="refund" maxlength="7" readonly  formControlName="refund" class="form-control">
                        
                    </div>
                </div>      
            
  
                <div class="justify-content-center d-flex mt-4">
                <button type="submit" class="btn btn-submit mr-3"  (click)="test()">Submit</button>
                </div>
            </div>
      </form>
    </div>
   </div>
  </div>
</div>
