import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:'root'
})
export class HomeService{

    apiBaseUrl: string = environment.url;

  constructor(private _http: HttpClient) { }

  add(data){
    return this._http.post(this.apiBaseUrl + 'v1/add-homepage', data );
  }

  edit(id,data){
    return this._http.put(`${this.apiBaseUrl}v1/update-homepage/${id}`, data );
  }

  getAll(){
    return this._http.get(this.apiBaseUrl + 'v1/home-data');
  }

}