<!--<button type="button" class="btn btn-success" routerLink="/quiz"  style="margin-bottom: 30px;">View All Quiz</button>
-->

<div class="categoryname"> Address</div>

<div class="row" >
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
   <div class="card card-border">
    <div class="card-body" id = "example_wrapper">
          <form [formGroup] = "productForm">
           <div class="row">
               <!-- <div class="col-sm-4">
                   <div class="form-group">
                       <label for="category" class="required">Select Category:</label>
                       <select class="form-control" formControlName="category" [ngClass]="{ 'is-invalid': submitted && f.category.errors}" >
                        <option *ngFor="let category of categories; let i = index" [value]="category._id">{{category.name}}</option>
                       </select>
                   </div>
               </div> -->
               <div class="col-sm-4">
                <div class="form-group">
                    <label for="name" class="required">Name:</label>
                    <input id="name" maxlength="35"    formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors}" class="form-control" placeholder="Enter name">
                    <div *ngIf="productForm.controls.name.invalid &&  submitted"
                    class="error">
                    <div *ngIf="productForm.controls.name.errors.required">
                        name required
                    </div>
                </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label for="phone" class="required">Phone:</label>
                    <input id="phone" maxlength="15" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors}" class="form-control" placeholder="Enter phone" (keypress)="onlyNumber($event)">
                    <div *ngIf="productForm.controls.phone.invalid &&  submitted"
                    class="error">
                    <div *ngIf="productForm.controls.phone.errors.required">
                        Please enter phone
                    </div>
                </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="country" class="required">Country</label>
                    <input id="country" maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.country.errors}" formControlName="country" class="form-control" placeholder="Enter country" >
                    <div *ngIf="productForm.controls.country.invalid &&  submitted" class="error">
                    <div *ngIf="productForm.controls.country.errors.required">
                        Please enter country
                    </div>
                </div>
                </div>
            </div> 
            
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="state" class="required">State</label>
                    <input id="state" maxlength="17" formControlName="state" [ngClass]="{ 'is-invalid': submitted && f.state.errors}" class="form-control" placeholder="Enter state" >
                    <div *ngIf="productForm.controls.state.invalid &&  submitted"
                    class="error">
                    <div *ngIf="productForm.controls.state.errors.required">
                        Please enter state
                    </div>
                </div>
                </div>
            </div> 
            
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="city" class="required">City</label>
                    <input id="city"  formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors}" class="form-control" placeholder="Enter city">
                    <div *ngIf="productForm.controls.city.invalid &&  submitted"
                    class="error">
                    <div *ngIf="productForm.controls.city.errors.required">
                        Please enter city
                    </div>
                </div>
                </div>
            </div>  

            <div class="col-sm-4">
                <div class="form-group">
                    <label for="zip_code" class="required">Zip Code</label>
                    <input id="Zip code" maxlength="7" formControlName="zip_code" [ngClass]="{ 'is-invalid': submitted && f.zip_code.errors}" class="form-control" placeholder="Enter zip_code" (keypress)="onlyNumber($event)">
                    <div *ngIf="productForm.controls.zip_code.invalid &&  submitted"
                    class="error">
                    <div *ngIf="productForm.controls.zip_code.errors.required">
                        Please enter zip code
                    </div>
                </div>
                </div>
            </div> 

            

            
            

           
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="address" class="required">Address:</label>
                    <textarea id="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors}" formControlName="address" class="form-control" placeholder="Enter address " rows="3"></textarea>
                    <div *ngIf="productForm.controls.address.invalid &&  submitted" class="error">
                    <div *ngIf="productForm.controls.address.errors.required">
                        Please enter address
                    </div>
                </div>
                </div>
        </div>

           </div>   


           <div class="col-sm-4">
            <div class="form-group">
                <label for="street1" >Street 1</label>
                <input id="street1" maxlength="200" formControlName="street1" [ngClass]="{ 'is-invalid': submitted }" class="form-controll" placeholder="Enter street 1" >
                <div *ngIf="productForm.controls.street1.invalid &&  submitted"
                class="error">
                <div *ngIf="productForm.controls.street1.errors.required">
                    Please enter street 1
                </div>
            </div>
            </div>
        </div>  


        <div class="col-sm-4">
            <div class="form-group">
                <label for="street2" >street2</label>
                <input id="street2" maxlength="200" formControlName="street2" [ngClass]="{ 'is-invalid': submitted }" class="form-controll" placeholder="Enter street 2" >
                <div *ngIf="productForm.controls.street2.invalid &&  submitted"
                class="error">
                <div *ngIf="productForm.controls.street2.errors.required">
                    Please enter street2
                </div>
            </div>
            </div>
        </div>  
           </div>



          
           <!-- </div>  -->
  
        <div class="justify-content-center d-flex mt-4">
          <button type="submit" class="btn btn-submit mr-3"  (click)="addAddress()">Submit</button>
        </div>
      
      </form>
    </div>
   </div>
  </div>
</div>
