import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PostService {

  apiBaseUrl: string = environment.url;

  constructor(private _http: HttpClient) { }

  getByType(userType: string) {
    let params = new HttpParams().set('userType', userType);
    return this._http.get(this.apiBaseUrl + 'v1/admin/get-posts', { params });
  }

  getPost(postId: string) {
    return this._http.get(this.apiBaseUrl + 'v1/post-detail/' + postId);
  }

  enableDisable(postId: string, status:"ACT"|"DEL") {
    let body = { status: status }
    return this._http.put(this.apiBaseUrl + 'v1/post-enableDisable/'+postId, body);
  }

  getWorldPosts(){
    return this._http.get(this.apiBaseUrl + 'v1/webinars');
  }

  getPostDetail(slug){
    return this._http.get(this.apiBaseUrl + `v1/webinar/${slug}`);
  }

  getMdPosts(type: string) {
    return this._http.get(this.apiBaseUrl + `v1/admin/appointment/requests/${type}`);
  }

  deleteWebinar(id:any){
    let param = new HttpParams()
    param = param.append("admin","yes")
    return this._http.delete(this.apiBaseUrl + `v1/delete-webinar/${id}`,{params:param});
  }


  deletemedAndme(id:any){
    let param = new HttpParams()
    param = param.append("admin","yes")
    return this._http.delete(this.apiBaseUrl + `v1/delete-post/${id}`,{params:param});
  }
}
