import { AbstractControl, FormControl } from '@angular/forms';

export function noWhitespaceValidator(control: FormControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
        control.setValue('');
        return { required: true }
    }
    else {
        return null;
    }
}

export function spaceValidator(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
        control.setValue('');
        return { required: true }
    }
    else {
        return null;
    }
}