import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upcoming-requests',
  templateUrl: './upcoming-requests.component.html',
  styleUrls: ['./upcoming-requests.component.css']
})
export class UpcomingRequestsComponent implements OnInit {

  type="upcoming"

  constructor() { }

  ngOnInit(): void {
  }

}
