<div class="col-sm-4">
   <label for="orderStatus">Select Order Status: </label> 
  <select id="orderStatus" class="form-control" style="margin-bottom: 30px;">
       <option>Select One</option>
       <option *ngFor="let status of orderStatus">{{status}}</option>
  </select>
</div>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example" datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Category Name</th>
                      <th>Product Name</th>
                      <th>Price(one time)</th>
                      <th>Action</th>                      
                  </tr>
              </thead>
             <tbody>
               <tr *ngFor="let product of products; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{product.categoryName}}</td> 
                     <td>{{product.productName}}</td> 
                     <td>{{product.price}}</td> 
                     <td>
                      <mat-icon  color="primary" aria-hidden="false" aria-label="view icon" routerLink="/{{route.orderDetails}}/{{product.id}}" matTooltip="open order details">open_in_new</mat-icon>   
                     </td>
                 </tr>
                
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>