
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

            <div class="card">
            <div class="card-body">


                   <form [formGroup] = "formdata" >
                  <div class="form-group">
                    <label for="Name" class="required">Challenge Name:</label>
                    <input type="text" maxlength="200" formControlName='challengeName' class="form-control" id="inputName" placeholder="Enter Challenge Name" name="Name"  [ngClass]="{ 'is-invalid': submitted && f.challengeName.errors}">
                  </div>

                  <div class="form-group">
                    <label for="pwd">Duration:</label>
                    <div class="row">
                    <div class="col-md-6">
                      <input type="text" maxlength="3" formControlName='timeDuration'  min="1" (keypress)="numberOnly($event)" class="form-control" id="inputDuration" [placeholder]="formdata.get('timeDurationIn').value |titlecase " name="duration" [ngClass]="{ 'is-invalid': submitted && f.timeDuration.errors}" >
                    </div>

                    <div class="col-md-6">
                      <select formControlName='timeDurationIn' class="form-control" id="inputDuration" name="durationIn"  [ngClass]="{ 'is-invalid': submitted && f.timeDurationIn.errors}">
                        <option value="minutes">Minutes</option>
                        <option value="hours">Hours</option>
                        <option value="weeks">Weeks</option>
                        <option value="days">Days</option>

                      </select>
                    </div>
                    </div>
                  </div>

                  <div class="form-group description-bottom-area">
                    <label for="pwd" class="required">Description:</label>
                    <textarea
                      rows="4"
                      maxlength="10000"
                      formControlName='description'
                      class="form-control"
                      id="description"
                      placeholder="Description"
                      name="description"
                      [ngClass]="{ 'is-invalid': submitted && f.description.errors}"
                    ></textarea>
                  </div>

                  <!-- <div class="form-group">
                    <label for="pwd" class="required">Description:</label>
                    <input type="text" maxlength="500" formControlName='description' class="form-control" id="description" placeholder="Description" name="description"  [ngClass]="{ 'is-invalid': submitted && f.description.errors}">
                  </div> -->
                  <div class="justify-content-center d-flex mt-4">
                  <button type="submit" class="btn btn-submit mr-3" (click)="addWorkoutChallenge(formdata.value)">Submit</button>
                  <button  class="btn btn-danger"  routerLink="/workout-challenges">Cancel</button>
                  </div>
                </form>
            </div>
            </div>

          </div>
        </div>
