import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-workout-challenge',
  templateUrl: './add-workout-challenge.component.html',
  styleUrls: ['./add-workout-challenge.component.css']
})
export class AddWorkoutChallengeComponent implements OnInit {

  formdata;
  duration;
  submitted;
  formdetail;
  challengeName;
  invalid: boolean = false;
  apiBaseUrl: string = environment.url;


  constructor(private http: HttpClient, private router: Router, private snackbar: SnackbarService) { }

  ngOnInit() {
    this.formdata = new FormGroup({
      challengeName: new FormControl("", [Validators.required]),
      timeDuration: new FormControl("", [Validators.required]),
      timeDurationIn: new FormControl("minutes", [Validators.required]),
      description: new FormControl("", [Validators.required]),
    });

  }

  get f() { return this.formdata.controls; }

  addWorkoutChallenge(data) {
    this.submitted = true;
    if (this.formdata.invalid) {
      return;
    }
    this.formdetail = {
      'challengeName': data.challengeName,
      'timeDuration': data.timeDuration,
      'timeDurationIn': data.timeDurationIn,
      'description': data.description,
    }

    this.http.post(this.apiBaseUrl + 'v1/addWorkoutChallenge', this.formdetail).subscribe((data: any) => {
      if (data.status == 200) {
        this.router.navigate(['/workout-challenges']);
        this.snackbar.successSnackBar("Challenge added successfully", '');
      }
      else {
        this.invalid = true;
      }
    })
  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
