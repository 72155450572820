<div class="categoryname"> {{category_name|titlecase}}</div>

<div class="row" >

  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
   <div class="card card-border">
    <div class="card-body" id = "example_wrapper">
          <form [formGroup] = "videoForm">
           <div class="row">
               <div class="col-sm-4">
                   <div class="form-group">
                       <label for="category_id" class="required">Select Category:</label>
                       <select class="form-control" formControlName="category_id" [ngClass]="{ 'is-invalid': submitted && f.category_id.errors}">
                        <option *ngFor="let category of categories; let i = index" [value]="category._id">{{category.name}}</option>
                       </select>
                   </div>
                   <div *ngIf="videoForm.controls.category_id.invalid &&  submitted"
                     class="error">
                     <div *ngIf="videoForm.controls.category_id.errors.required">*Please select category
                     </div>
                 </div>
               </div>
               <div class="col-sm-4">
                <div class="form-group">
                    <label for="title" class="required">Video Title:</label>
                    <input id="title" formControlName="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors}" class="form-control" placeholder="Enter title">
                </div>
                <div *ngIf="videoForm.controls.title.invalid &&  submitted" class="error">
                  <div *ngIf="videoForm.controls.title.errors.required">*Please enter  Title
                  </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="image-upload-container btn btn-bwm" class="notii">Select Caption</label>  

               <input  type="file" formControlName="captions" (change)="onCaptionChange($event)" >
               <p style="color: crimson;">example:abc.vtt</p>
               <div *ngIf="videoForm.controls.captions.invalid &&  submitted"
                      class="error">
                      <div *ngIf="fileExtensionError == true ">*Please select Caption
                      </div>
                  </div>
                  
              </div>

            
          </div>
            
            <div class="col-sm-4">
              <div class="form-group">
                <label class="image-upload-container btn btn-bwm" class="notii">Select Video</label>                

                <input  type="file" formControlName="videos" (change)="onFileChange($event)" accept="video/*" >
                <div *ngIf="videoForm.controls.videos.invalid &&  submitted"
                     class="error">
                     <div *ngIf="videoForm.controls.videos.errors.required">*Please select Video
                     </div>
                 </div>
                  
              </div>
            
            
           </div>
           <div class="row">
            <video width="400" height="300" autoplay [src]="videos"
           *ngIf="videos!==undefined && videos !==null" controls (loadedmetadata)="getDuration($event)">
            <source [src]="videos" id ="video_here">
            Your browser does not support HTML video.
          </video>
        </div>
      
           </div>   
  
        <div class="justify-content-center d-flex mt-4">
          <button type="submit" class="btn btn-submit mr-3" (click)="submit()" >Submit</button>
        </div>
      
      </form>
    </div>
   </div>
  </div>
</div>
