

  <button type="button" class="btn btn-success" (click)="addCategory(null)"  style="margin-bottom: 30px;">Add Category</button>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example" datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Name</th>
                      <th>Is Food Category</th>
                      <th>Have Subscription</th>
                      <th>Action</th>                      
                  </tr>
              </thead>
             <tbody>
               <tr *ngFor="let category of categories; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{category.name}}</td>
                     <td>
                        <span>{{ category.isFoodCategory ? 'Yes' : 'No' }}</span>
                     </td>
                     <td>
                      <span>{{ category.haveSubscription ? 'Yes' : 'No' }}</span>
                   </td>
                     <td>
                      <mat-icon  color="primary" aria-hidden="false" aria-label="view icon" routerLink="/{{route.productsByCategory}}/{{category._id}}" matTooltip="view products">open_in_new</mat-icon>   
                      <mat-icon class="marginLeft10" color="primary" aria-hidden="false" aria-label="edit icon" matTooltip="edit category" (click)="addCategory(category)">edit</mat-icon> 
                      <mat-icon class="marginLeft10" color="warn" aria-hidden="false" aria-label="delete icon" matTooltip="delete category" (click)="deleteCategory(category._id)">delete</mat-icon>
                     </td>
                 </tr> 
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>