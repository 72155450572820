
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          
            <div class="card-body">

               <div *ngIf="invalid" style="color: #dc3545; padding-left:26px; font-size: 100%;">Email already exist!</div>
 
                 <form [formGroup] = "formdata" (ngSubmit) = "addUser(formdata.value)" enctype="multipart/form-data">
                  <div class="form-group">
                    <label for="Name" class="required">First Name:</label>
                    <input type="text" formControlName='firstName' class="form-control" id="inputName" placeholder="Enter First Name" name="Name" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors}">
                  </div>
                  <div class="form-group">
                    <label for="pwd" class="required">Last Name:</label>
                    <input type="text" formControlName='lastName' class="form-control" id="inputDuration" placeholder="Enter Last Name" name="category"  [ngClass]="{ 'is-invalid': submitted && f.lastName.errors}">
                  </div> 

                  <div class="form-group">
                    <label for="pwd" class="required">Email:</label>
                    <input type="text" formControlName='email' class="form-control" id="inputDuration" placeholder="Enter Email" name="type" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                  </div> 

                 <div class="form-group">
                    <label for="pwd" class="required">Password:</label>
                    <input type="text" formControlName='password' class="form-control" id="inputDuration" placeholder="Enter Password" name="type" [ngClass]="{ 'is-invalid': submitted && f.password.errors}">
                  </div> 
                 
                  <div class="justify-content-center d-flex mt-4">
                  <button type="submit" class="btn btn-submit mr-3">Submit</button>
                  <button type="submit"routerLink="/all-users" class="btn btn-danger">Cancel</button>
                  </div>
                </form>
            </div>
           
          </div>
        </div>
      


