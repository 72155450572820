  <div class="row">
    <div class="col-sm-6">
      <button type="button" class="btn btn-success" (click)="addMedication()" style="margin-bottom: 30px;">Add Medication</button>
    </div>
    <div class="col-sm-6 form-group">
      <input type="text" class="form-control" placeholder="Search Medication" [(ngModel)]="search">
    </div>
  </div>
  

        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
           <div class="card card-border" >
            <div class="card-body" id = "example_wrapper" *ngIf="medications.length > 0">
                <mat-chip-list aria-label="medication selection">
                    <mat-chip style="cursor: pointer;" color="warn"  *ngFor="let medication of medications | filter : search | sort : 'medName'; let i = index" selected >{{medication.medName}}
                        <mat-icon matChipRemove  aria-hidden="true" aria-label="edit icon" matTooltip="Click to update" (click)="updateMedication(medication)">edit</mat-icon>
                        <mat-icon matChipRemove  aria-hidden="true" aria-label="edit icon" matTooltip="Click to delete" (click)="deleteMedication(medication,i)">delete</mat-icon>
                      </mat-chip>
                    
                  </mat-chip-list>
            </div>
            <div class="card-body" id = "example_wrapper" *ngIf="medications.length == 0">No Medication Found</div>
           </div>
          </div>
        </div>
      