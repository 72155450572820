import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShopAndProductService } from '../service/shop-and-product.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from 'src/app/_validators/noWhitespaceValidator';


@Component({
  selector: 'app-add-or-update-shop-category',
  templateUrl: './add-or-update-shop-category.component.html',
  styleUrls: ['./add-or-update-shop-category.component.css']
})
export class AddOrUpdateShopCategoryComponent implements OnInit {

  title : string = 'Add Category';

  id : string = '';

  fileTypes = ["image/jpeg","image/png","image/jpg","image/tiff"];

  imagePreview : string = undefined;

  file : File = undefined;
   
  categoryForm : FormGroup;

  submitted : boolean = false;


  constructor(public dialogRef: MatDialogRef<AddOrUpdateShopCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private _shopAndProductService : ShopAndProductService,
    private _fb : FormBuilder,
    private snackbar: SnackbarService) { }

  ngOnInit(): void {
      if(this.data) {
         this.title = "Update Category";
         this.id = this.data._id;
        
        this.categoryForm = this._fb.group({
          category : new FormControl(this.data.name,[Validators.required,noWhitespaceValidator, Validators.maxLength(25)]),
          image : new FormControl(''),
          isFoodCategory: new FormControl(this.data.isFoodCategory),
          saveSubscription: new FormControl(this.data.haveSubscription),
        });

        if(this.data.image)
          this.imagePreview = environment.url+this.data.image;
      }
      else {
        this.categoryForm = this._fb.group({
          category : new FormControl('', [Validators.required, Validators.maxLength(25),noWhitespaceValidator]),
          image : new FormControl('', Validators.required),
          isFoodCategory: new FormControl(false),
          saveSubscription: new FormControl(false)
         });
      }
  }

  onFileChange(event : any){
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
     const fileType=file.type;
    if(this.fileTypes.includes(fileType)){
            this.file = file;
            reader.onload = () => {
                this.imagePreview = reader.result as string;
            }   
    }else{
      alert("only "+this.fileTypes.toString()+" format supports");
    }
    }
  }
  
  get f(){
    return this.categoryForm.controls;
  }

  submit(){
     this.submitted = true;
     if(!this.data)
          this.addCategory();
     else
       this.updateCategory();      
  }

  addCategory(){
    if(this.categoryForm.valid && this.file) {
         this._shopAndProductService.addCategory(
          this.categoryForm.controls.category.value,
          this.file,
          this.categoryForm.controls.isFoodCategory.value,
          this.categoryForm.controls.saveSubscription.value).subscribe((resp :any) =>{
            if(resp.status == 200){
            this.snackbar.successSnackBar(" Category added successfully.", '');
              this.dialogRef.close('success')
            }
            else {
              this.snackbar.successSnackBar(resp.message, '');
              this.dialogRef.close('success');
            }
       })
    }
  }

  updateCategory(){
    if(this.categoryForm.valid){
      this._shopAndProductService.updateCategory(
        this.id,
        this.categoryForm.controls.category.value,
        this.file,
        this.categoryForm.controls.isFoodCategory.value,
        this.categoryForm.controls.saveSubscription.value ).subscribe((resp :any) =>{
        if(resp.status == 200){
           this.dialogRef.close('success')
           this.snackbar.successSnackBar(resp.message, '');
        }
        else{
          this.snackbar.successSnackBar(" Category updated successfully .", '');
          this.dialogRef.close('success');
  
        }

   })
  }
}


}
