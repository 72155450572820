

        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
           <div class="card card-border">
            <div class="card-body" id = "example_wrapper">
              <table id="example" *ngIf="allFeedback"  datatable class="display">
                <thead>
                    <tr >
                        <th>Sr. No.</th>
                        <th>User Name</th>
                        <th>Feedback</th>                                           
                        <th>Created Date</th>               
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let feedback of allFeedback, let i=index">
                        <td>{{i+1}}</td>
                        <td>{{feedback.userName}}</td>
                        <td>{{feedback.feedback}}</td>
                        <td>{{feedback.createdOn | date}}</td>
                                            
                    </tr>                   
                </tbody>
              </table>
            </div>
           </div>
          </div>
        </div>
