import * as $ from 'jquery';
import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TransactionService } from '../services/transaction.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {

  showLoader : boolean = true;

  categories : any[] = [];

  route : Routes = new Routes();

  constructor(private transactionService : TransactionService, public dialog: MatDialog) { }

  ngOnInit(): void {
     this.getAlltransaction();
  }

  

getAlltransaction(){
    this.transactionService.gettransactions().subscribe((response : any) => {
      this.showLoader = false;
     // console.log(response);
      this.categories = response.data;
      console.log(this.categories,"kkkkkkkkkkkkkkkkkkkkkkkkk")
    $(document).ready(function() {
      $('#example').DataTable();
      } );
  },error => this.showLoader = false);

}

// deleteOffer(categoryId : string){
//     const confirm = window.confirm("Are you sure want to delete this category?");
//     if(confirm){
//       this.transactionService.deleteOfferById(categoryId).subscribe((response : any)=>{
//          //   this.showLoader = false;
//             var index = -1;
//             var loopIndex = -1;
//             this.categories.forEach(quiz => {
//                 loopIndex = loopIndex+1;
//                  if(quiz._id === categoryId){
//                          index = loopIndex;
//                  }        
//             });
//             if(index !==-1)
//                 this.categories.splice(index,1);
//       }, error => this.showLoader = false)
//     }
//   }
}