<button type="button" class="btn btn-success" routerLink="/add_quiz" style="margin-bottom: 30px;">Add New Quiz</button>
<button type="button" class="btn btn-success" routerLink="/quiz" style="margin-bottom: 30px; margin-left: 10px;">View
  All Quiz</button>

<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="card card-border">
      <div class="card-body" id="example_wrapper">
        <form [formGroup]="quiz">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="quizname" class="required">Name:</label>
                <input type="text" class="form-control" id="quizname" formControlName="name" placeholder="Enter Name"
                  [ngClass]="{ 'is-invalid': submitted && f.name.errors}">
              </div>
            </div>
            <div class="col-sm-6">
              <!-- <div class="form-group">
                      <label for="quizlocation" class="required">Location:</label>
                      <input type="text" ngx-google-places-autocomplete  (onAddressChange)="AddressChange($event)" class="form-control" aria-describedby="basic-addon2" id="locations" formControlName="locations" placeholder="Enter Location"  
                    [ngClass]="{ 'is-invalid': submitted && f.locations.errors}"> -->
              <!--  <mat-select class="form-control" formControlName="locations" multiple [ngClass]="{ 'is-invalid': submitted && f.locations.errors}">
                          <mat-select-trigger>
                            {{f.locations.value ? f.locations.value[0] : ''}}
                            <span *ngIf="f.locations.value?.length > 1" class="example-additional-selection">
                              (+{{f.locations.value.length - 1}} {{f.locations.value?.length === 2 ? 'other' : 'others'}})
                            </span>
                          </mat-select-trigger>
                          <mat-option *ngFor="let location of locations" [value]="location">{{location}}</mat-option>
                        </mat-select>
                     -->
              <!-- </div> -->
            </div>
          </div>

          <ng-container formArrayName="questions">
            <ng-container *ngFor="let question of questions.controls; let qi = index" [formGroup]="question">

              <ng-container *ngIf="qi===currentQuestionIndex">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="form-group">
                      <label for="question+{{qi}}" class="required">Question:</label>

                      <input type="text" class="form-control" aria-describedby="basic-addon2" id="question+{{qi}}"
                        formControlName="title" placeholder="Enter question"  maxlength="50"
                        [ngClass]="{ 'is-invalid': submitted && question.controls.title.errors}">
                      <mat-icon style="display: block;
                                float: right;
                                margin-top: -35px;
                                margin-right: -30px; font-size: 30px;" class="mat-icon-color"
                        matTooltip="remove this question" (click)="removeQuestion(qi)">delete</mat-icon>
                    </div>
                  </div>
                  <div class="col-sm-2" style="margin-left: 10px;">
                    <div class="form-group">
                      <label for="sel1">Answer Type:</label>
                      <select class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && question.controls.answertype.errors}"
                        (change)="answerTypeChangeFromQuestion($event, qi)" formControlName="answertype">
                        <option *ngFor="let type of answerTypes" [value]="type">{{type}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <ng-container formArrayName="answers">
                  <ng-container *ngFor="let answer of answers(qi).controls; let i = index">
                    <div class="row align-items-center" [formGroup]="answer">
                      <div class="col-sm-1" style="margin-top: 33px; margin-right: -40px !important">
                        <div class="form-group">
                          <mat-icon style="font-size: 30px;" class="mat-icon-color" matTooltip="remove this answer"
                            (click)="removeAnswer(qi,i)">delete</mat-icon>
                        </div>
                      </div>
                      <!-- <div class="col-sm-3">
                                    <div class="form-group">
                                      <label for="sel1">Answer Type:</label>
                                      <select class="form-control" [ngClass]="{ 'is-invalid': submitted && answer.controls.type.errors}" (change)="answerTypeChange($event, qi,i)" formControlName="type">
                                        <option *ngFor="let type of answerTypes" [selected]="type===getAnswerType(qi,i)" [value]="type">{{type}}</option>
                                      </select>
                                      </div>
                                    </div> -->
                      <div class="col-sm-4" style="margin-top: 37px;" *ngIf="getAnswerType(qi,i)==='image'">
                        <div class="form-group" >
                          <input type="file"  accept="image/*" (change)="onFileChange($event,qi,i)">
                          
                          <img *ngIf="answer.get('value').value" [src]="answer.get('value').value.startsWith('data') ? answer.get('value').value : API_URL + answer.get('value').value"
                           style="max-height:100px;max-width:150px" />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <section style="margin-top: 39px;">
                          <mat-checkbox color="primary" formControlName="isRight"
                            (click)="isAlreadyAnswerSelected(qi)">Correct Answer</mat-checkbox>
                        </section>
                      </div>

                      <div class="col-sm-12" *ngIf="getAnswerType(qi,i)==='text'">
                        <div class="form-group">
                          <label for="answer" class="required">Answer:</label>
                          <input type="text" class="form-control" formControlName="value" id="answer"
                            placeholder="Enter Answer"  maxlength="15" 
                            [ngClass]="{ 'is-invalid': submitted && answer.controls.value.errors}">
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <a *ngIf="answers(qi).length<4" (click)="addAnswer(undefined,qi)"
                  style="color: rgb(72, 72, 204); cursor: pointer; font-weight: bold; text-decoration: underline;">+ Add
                  Answer</a>
              </ng-container>

            </ng-container>
          </ng-container>
          <br>
          <a *ngIf="questions.length<10" (click)="addQuestion(undefined)"
            style="color: rgb(72, 72, 204); cursor: pointer; font-weight: bold; text-decoration: underline;">+ Add
            Question</a>


          <ul class="list-group list-group-horizontal" style="list-style-type: none;">
            <li><b>Question Number : </b></li>
            <li *ngFor="let q of questions.controls; let i = index"
              [ngStyle]="currentQuestionIndex == i ? {'border': '1px solid #000'} : {}"
              style="background-color: #00C9FF; color: white;width: 30px; font-weight: bold; font-size: 16px; border-radius: 50%; text-align: center; margin-left: 10px; cursor: pointer;"
              matTooltip="View question {{i+1}}" (click)="currentQuestionIndex=i">{{i+1}}

            </li>
          </ul>

          <div class="justify-content-center d-flex mt-4">
            <button type="submit" class="btn btn-submit mr-3" (click)="updateQuiz()">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>