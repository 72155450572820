import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-med-posts',
  templateUrl: './med-posts.component.html',
  styleUrls: ['./med-posts.component.css']
})
export class MedPostsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
