import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:'root'
})
export class NotificationService{

    apiBaseUrl: string = environment.url;

    constructor(private _http: HttpClient) { }

    getNotifications(pageNumber){
        let http = new HttpParams()
        http= http.set("page",pageNumber)
        return this._http.get(this.apiBaseUrl+'v1/admin/get-notifications',{params:http})
    }

    getCount(){
        return this._http.get(this.apiBaseUrl+'v1/admin/count-notifications')
    }
    
}
