import * as $ from 'jquery';
import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OfferService } from '../services/offer.service';


@Component({
  selector: 'app-offers-management',
  templateUrl: './offers-management.component.html',
  styleUrls: ['./offers-management.component.css']
})
export class OffersManagementComponent implements OnInit {

  showLoader: boolean = true;

  categories: any[] = [];

  route: Routes = new Routes();

  constructor(private offerService: OfferService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllOffers();
  }



  getAllOffers() {
    this.offerService.getOffers().subscribe((response: any) => {
      this.showLoader = false;
      // console.log(response);
      this.categories = response.offers;

      $(document).ready(function () {
        $('#example').DataTable();
      });
    }, error => this.showLoader = false);

  }




  deleteOffer(categoryId: string) {
    const confirm = window.confirm("Are you sure want to delete this Offer?");
    if (confirm) {
      this.offerService.deleteOfferById(categoryId).subscribe((response: any) => {
        //   this.showLoader = false;
        window.location.reload();

        // var index = -1;
        // var loopIndex = -1;
        // this.categories.forEach(quiz => {
        //     loopIndex = loopIndex+1;
        //      if(quiz._id === categoryId){
        //              index = loopIndex;
        //      }        
        // });
        // if(index !==-1)
        //     this.categories.splice(index,1);
      }, error => this.showLoader = false)
    }
  }
}
