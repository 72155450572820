import { SnackbarService } from '../services/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthDataService } from '../services/health-data.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-health-data',
  templateUrl: './health-data.component.html',
  styleUrls: ['./health-data.component.css']
})
export class HealthDataComponent implements OnInit {

  stats: any[]
  form: FormGroup
  formSubmitted = false
  countForm:FormGroup
  count:any
  editId: any
  count_type: string
  value_prefix: any
  countSubmitted: boolean
  selectedOption: string
  previousValue:string
  countTypeList=['K','L','M','B','T']
  @ViewChild('editableElement', { static: true }) editableElement: ElementRef;
  constructor(
    private healthDataService: HealthDataService,
    private formBuilder: FormBuilder,
    private snackBarService: SnackbarService) { }

  ngOnInit(): void {
    this.initForm()
    this.getAll();
    this.initCountForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      label: [''],
      value: ['', [Validators.required]]
    });
  }

  initCountForm(){
    this.countForm = this.formBuilder.group({
      count :['',Validators.required],
      count_type:[''],
      value_prefix:['']
    })
  }

  addStats() {
    this.formSubmitted = true;

    if (!this.form.invalid) {
      this.form.get('label').setValue("DISEASE_YEAR")
      let value = this.form.value
      this.healthDataService.add(value).subscribe((response: any) => {
        let index = this.stats.findIndex(stat => stat.title == value.title);
        this.snackBarService.successSnackBar("Health data added successfully.", '')
        this.form.get('value').setValue(response.data)

      })
    }
  }

  getAll() {
    this.healthDataService.getAll().subscribe((response: any) => {
      this.stats = response.getAllGraph
      
      this.form.get('value').setValue(response.year)
    })
  }

  edit(stat: any) {
    this.editId = stat._id
    this.countForm.patchValue(stat)
  }

  submitCount(data) {
    this.countSubmitted = true
    if (this.countForm.invalid) {
      return
    }
    this.healthDataService.updateCount(data._id, this.countForm.value).subscribe(response => {
      data.count = this.countForm.get('count').value
      this.editId = null
    })
  }

  onPrefixChange(event: any, id: any) {
    this.updateDollarChange(id, event.target.value)
  }

  updateDollarChange(statId: number, newOption: string) {
    const statToUpdate = this.stats.findIndex(stat => stat._id === statId);
    if (statToUpdate != -1) {
      this.countForm.get('value_prefix').setValue(newOption)
      this.stats[statToUpdate].value_prefix = newOption
    }
  }

  onUpdateOptionClick(event: any, id: any) {
    this.updateUserOption(id, event.target.value);
  }

  updateUserOption(statId: number, newOption: string) {
    const statToUpdate = this.stats.findIndex(stat => stat._id === statId);
    if (statToUpdate != -1) {
      this.countForm.get('count_type').setValue(newOption)
      this.stats[statToUpdate].count_type = newOption

    }
  }

  space(event, formControl, form) {
    if (event.target.value.trim() == "") {
      form.get(formControl).setValue('')
    }
  }
  onChange(e, value, formgroup) {
    let reg = /^[1-9]\d*(\.\d{0,2})?$/
    if (reg.test(e.target.value) || !e.target.value) {
        this.previousValue = e.target.value
        formgroup.get(value).setValue(e.target.value)
    } else {
        formgroup.get(value).setValue(this.previousValue)
    }
}
}
