
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
           <div class="card card-border">
            <div class="card-body" id = "example_wrapper">
              <table id="example" *ngIf="allUsers"  datatable class="display">
                <thead>
                    <tr >
                        <th>Sr. No.</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Age</th>
                        <th>Weight</th>
                        <th>Height</th>
                        <th>Login Type</th>
                        <th>Signup Date</th>                       
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of allUsers, let i=index">
                        <td>{{i+1}}</td>
                        <td>{{user.firstName}}</td>
                        <td>{{user.lastName}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.age}}</td>
                        <td>{{user.weight}}</td>
                        <td>{{user.height}}</td>
                        <td>{{user.loginType}}</td>
                        <td>{{user.signUpDate | date}}</td>
                    </tr>                   
                </tbody>
              </table>
            </div>
           </div>
          </div>
        </div>