
<div class="row" >
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
            <form [formGroup] = "OfferForm">
             <div class="row">
                 <div class="col-sm-4">
                    <div class="form-group">
                         <label for="product_id" class="required">Select Product:</label>
                         <select class="form-control" formControlName="product_id" [ngClass]="{ 'is-invalid': submitted && f.product_id.errors}">
                          <option *ngFor="let category of categories; let i = index" [value]="category._id" >{{category.name}}</option>
                         </select>
                    </div>

                 </div>
                 <div class="col-sm-4">
                  <div class="form-group">
                      <label for="off_percent" class="required">Off Percent:</label>
                      <input id="off_percent" maxlength="5" formControlName="off_percent" (keypress)="onlyNumber($event)" class="form-control" placeholder="Enter off percent">
                      <div *ngIf="OfferForm.controls.off_percent &&  submitted" class="error">
                      <div *ngIf="OfferForm.controls.off_percent.errors.required">
                          Off Percent required
                      </div>
                  </div>
                    </div>
              </div>
             
            </div> 
            <div class="row">
              <div class="col-sm-12">
               <div class="form-group">
                   <input type="file" id="image" class="form-control1" accept="image/*" (change)="onFileChange($event)" placeholder="Enter blog title" >
               </div>
           </div>
          </div> 

          <div class="row" *ngIf="offerImage">
             <div class="col-sm-12">
              <div class="form-group">
                  <img [src] = "!isFileChanged ? offerImage : offerImage" style="width: 250px; height: 200px;">
              </div>
          </div>
         </div> 
    
          <div class="justify-content-center d-flex mt-4">
            <button type="submit" class="btn btn-submit mr-3"  (click)="updateOffer()">Submit</button>
          </div>
        
        <!-- </form>
      </div>
     </div> -->
    <!-- </div> -->
  <!-- </div> -->
  