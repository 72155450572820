import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _router : Router, private _authService : AuthService){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLoggedIn =  sessionStorage.getItem('adminId');
   // alert(isLoggedIn)
    if(isLoggedIn === 'YesLoggedIn'){
      this._authService.showNavBar(true);
      return true;
    }
    else{
       this._router.navigate(['']);
       return false;
    }  
  }
  
}
