<!--<button type="button" class="btn btn-success" routerLink="/quiz"  style="margin-bottom: 30px;">View All Quiz</button>
-->

<div class="categoryname"> {{categoryname|titlecase}}</div>

<div class="row" >
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
   <div class="card card-border">
    <div class="card-body" id = "example_wrapper">
          <form [formGroup] = "productForm">
           <div class="row">
               <div class="col-sm-4">
                   <div class="form-group">
                       <label for="category" class="required">Select Category:</label>
                       <select class="form-control" formControlName="category" [ngClass]="{ 'is-invalid': submitted && f.category.errors}" >
                        <option *ngFor="let category of categories; let i = index" [value]="category._id">{{category.name}}</option>
                       </select>
                   </div>
               </div>
               <div class="col-sm-4">
                <div class="form-group">
                    <label for="productName" class="required">Product Name:</label>
                    <input id="productName" maxlength="35"    formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors}" class="form-control" placeholder="Enter product name">
                    <div *ngIf="productForm.controls.name.invalid &&  submitted"
                    class="error">
                    <div *ngIf="productForm.controls.name.errors.required">
                        Product name required
                    </div>
                </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label for="quantity" class="required">Quantity:</label>
                    <input id="quantity" maxlength="7" formControlName="quantity" [ngClass]="{ 'is-invalid': submitted && f.quantity.errors}" class="form-control" placeholder="Enter quantity" (keypress)="onlyNumber($event)">
                    <div *ngIf="productForm.controls.quantity.invalid &&  submitted"
                    class="error">
                    <div *ngIf="productForm.controls.quantity.errors.required">
                        Please enter quantity
                    </div>
                </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="productPrice" class="required">Price(one time order):</label>
                    <input id="productPrice" maxlength="7" [ngClass]="{ 'is-invalid': submitted && f.price.errors}" formControlName="price" class="form-control" placeholder="Enter product one time order price" (keypress)="onlyNumber($event)">
                    <div *ngIf="productForm.controls.price.invalid &&  submitted" class="error">
                    <div *ngIf="productForm.controls.price.errors.required">
                        Please enter price
                    </div>
                </div>
                </div>
            </div>      
            

            <div class="col-sm-4">
                <div class="form-group">
                    <input #file type="file" id="uploadImages" style=" display: none;"  (change)="onFileChange($event)" accept="image/*" multiple>
                    <button class="btn btn-success" style="margin-top: 27px;"
                  
                      (click)="file.click();" >+ Add images</button>
                    
                </div>
  
            </div>
           </div>
           <div class="row">
            <div class="col-sm-3 form-group" *ngFor="let image of previewImages; let i = index">
                <img [src]="image" height="170px" width="100%" class="ml-1">
                <mat-icon color="warn" style="background-color: #FFFFFF; border-radius: 50%;" (click)="removeImage(i)">clear</mat-icon>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="description" class="required">Description:</label>
                    <textarea id="description" [ngClass]="{ 'is-invalid': submitted && f.description.errors}" formControlName="description" class="form-control" placeholder="Enter product description" rows="3"></textarea>
                    <div *ngIf="productForm.controls.description.invalid &&  submitted" class="error">
                    <div *ngIf="productForm.controls.description.errors.required">
                        Please enter description
                    </div>
                </div>
                </div>
            </div>

           </div>   



           <div class="col-sm-4">
            <div class="form-group">
                <label for="size">Select Size:</label>
                <select class="form-control" formControlName="size" [ngClass]="{ 'is-invalid': submitted && f.category.errors}" multiple>
                 <option *ngFor="let category of sizes; let i = index" [value]="category">{{category}}</option>
                </select>
            </div>
        </div>
        <ng-container formArrayName="colors">
        <ng-container *ngFor="let color of colors.controls; let qi = index"  [formGroup] = "color" >
        <ng-container *ngIf="qi===currentColorIndex">

        <div class="col-sm-4">
            <div class="form-group">
                <label for="color+{{qi}}" >Color:</label>
                <!-- <input type="text" id="color" name="color" class="form-control" spellcheck="false" formControlName="value" > -->
                <input ejs-colorpicker type="color" class="form-control"  [(value)]="value"  id="color+{{qi}}" formControlName = "p_color" style ="background-color: white;" />  
                <mat-icon style="display: block;
                float: right;
                margin-top: 3px;
                margin-right: 151px;
                font-size: 23px;"
                 class="mat-icon-color" matTooltip="remove this color"
                  (click)="removeColor(qi)">delete</mat-icon>


                  
            </div>
        </div>
    </ng-container>
</ng-container>
<a *ngIf="colors.length<10"  (click)="addQuestion()" style="color: rgb(72, 72, 204); cursor: pointer; font-weight: bold; text-decoration: underline;">+ Add color</a> 

</ng-container>

<ul class="list-group list-group-horizontal" style="list-style-type: none;">
    <li><b>Color Number : </b></li>
    <li *ngFor="let q of colors.controls; let i = index" style="background-color: #00C9FF; color: white;width: 30px; font-weight: bold; font-size: 16px; border-radius: 50%; text-align: center; margin-left: 10px; cursor: pointer;" matTooltip="View color {{i+1}}" (click)="currentColorIndex=i">{{i+1}}
    
    </li>
  </ul>




  <hr>
  <span class="dimensions">Dimensions</span>
  <hr>
  <div class="col-sm-4">
    <div class="form-group">
        <label for="length" class="required">Length(in inches)</label>
        <input id="length" maxlength="7" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" pattern="^[1-9][0-9]*$" formControlName="length" [ngClass]="{ 'is-invalid': submitted && f.length.errors}" class="form-control" placeholder="Enter length" (keypress)="onlyNumber($event)">
        <div *ngIf="productForm.controls.length.invalid &&  submitted"
        class="error">
        <div *ngIf="productForm.controls.length.errors.required">
            Please enter Length
        </div>
    </div>
    </div>
</div>  

<div class="col-sm-4">
    <div class="form-group">
        <label for="width" class="required">Width(in inches)</label>
        <input id="width" maxlength="7" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" pattern="^[1-9][0-9]*$" formControlName="width" [ngClass]="{ 'is-invalid': submitted && f.width.errors}" class="form-control" placeholder="Enter width" (keypress)="onlyNumber($event)">
        <div *ngIf="productForm.controls.width.invalid &&  submitted"
        class="error">
        <div *ngIf="productForm.controls.width.errors.required">
            Please enter width
        </div>
    </div>
    </div>
</div>  

<div class="col-sm-4">
    <div class="form-group">
        <label for="heigth" class="required">Heigth(in inches)</label>
        <input id="heigth" maxlength="7" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" pattern="^[1-9][0-9]*$" formControlName="heigth" [ngClass]="{ 'is-invalid': submitted && f.heigth.errors}" class="form-control" placeholder="Enter heigth" (keypress)="onlyNumber($event)">
        <div *ngIf="productForm.controls.heigth.invalid &&  submitted"
        class="error">
        <div *ngIf="productForm.controls.heigth.errors.required">
            Please enter heigth
        </div>
    </div>
    </div>
</div> 


<div class="col-sm-4">
    <div class="form-group">
        <label for="weigth" class="required">Weigth(in ounces)</label>
        <input id="weigth" maxlength="7" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" pattern="^[1-9][0-9]*$" formControlName="weigth" [ngClass]="{ 'is-invalid': submitted && f.weigth.errors}" class="form-control" placeholder="Enter weigth" (keypress)="onlyNumber($event)">
        <div *ngIf="productForm.controls.weigth.invalid &&  submitted"
        class="error">
        <div *ngIf="productForm.controls.weigth.errors.required">
            Please enter weigth
        </div>
    </div>
    </div>
</div> 




           <!-- </div>  -->
  
        <div class="justify-content-center d-flex mt-4">
          <button type="submit" class="btn btn-submit mr-3" (click)="addProduct($event)" >Submit</button>
        </div>
      
      </form>
    </div>
   </div>
  </div>
</div>
