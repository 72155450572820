import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.css']
})
export class AddVideoComponent implements OnInit {

  type;
  filesize
  imageSrc;
  videoFile;
  submitted;
  categoryName;
  imageUrl:File;
  unvalidImgTypeMsg;
  formdata:FormGroup
  invalid: boolean = false;
  uploaded: boolean = false;

  @ViewChild('fileInput') fileInput :ElementRef
  public showLoader: boolean = false;
  apiBaseUrl: string = environment.url;


  constructor(private http: HttpClient, 
    private router: Router, 
    private snackbar: SnackbarService,
    private formbuilder:FormBuilder) { }

  ngOnInit() {
   this.initForm() 
  }

  initForm(){
    this.formdata = this.formbuilder.group({
      videoFile: new FormControl("", [Validators.required]),
      categoryName: new FormControl("", [Validators.required]),
      type: new FormControl("", [Validators.required]),
    });
  }

  get f() { return this.formdata.controls; }

  addExerciseVideos(data) {
    this.submitted = true;
    if(this.formdata.invalid){
      return
    }
    if (this.filesize > 50) {
      alert("Please provide video with less then 50 Mb.");
      return;
    }
    if (this.unvalidImgTypeMsg == true) {
      return;
    }
    let formdetail = new FormData()

    formdetail.append("videoLink",this.imageUrl)
    formdetail.append("categoryName",data.categoryName)
    formdetail.append("type",data.type)
     
    console.log(formdetail)

    this.http.post(this.apiBaseUrl + 'v1/addExerciseVideo', formdetail).subscribe((data: any) => {
      if (data.status == 200) {
        this.router.navigate(['/exercise-videos']);
        this.snackbar.successSnackBar(" Video added successfully.", '');
      }
      else {
        this.invalid = true;
        return
      }
    })
  }


  uploadInstallerImage(imageData) {

    const formData: any = new FormData();
    const fileName: any = imageData.target.files[0];
    this.imageUrl = imageData.target.files[0];
    formData.append('videoFile', fileName);

    const idxDot = imageData.target.files[0].name.lastIndexOf(".") + 1;
    const extFile = imageData.target.files[0].name.substr(idxDot, imageData.target.files[0].name.length).toLowerCase();
    this.filesize = fileName.size / 1024 / 1024
    if (this.filesize > 50) {
      alert("Please provide video with less then 50 Mb.");
      return;
    }

    if (extFile == "mp4") {
      this.showLoader = true;
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(fileName);
      this.unvalidImgTypeMsg = false;
      this.showLoader = false;
      this.uploaded = true;
    } else {
      this.fileInput.nativeElement.value=''
      this.unvalidImgTypeMsg = true;
    }
  }
}
