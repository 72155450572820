import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ChargesService } from 'src/app/services/charges.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-charges',
  templateUrl: './edit-charges.component.html',
  styleUrls: ['./edit-charges.component.css']
})
export class EditChargesComponent implements OnInit {

  categories ;
  charge;

  chargeForm: FormGroup;

  submitted : boolean = false;

  chargeType : string = '';

  numbers = ["0","1","2","3","4","5","6","7","8","9","."];
  productName;



  constructor(private _fb : FormBuilder, private _activateRoute : ActivatedRoute,
     private _chargeService : ChargesService,
     private router: Router) {


      this.chargeForm = this._fb.group({
        charges : ['', Validators.required],        
      
      });
  }

 ngOnInit(): void {
  this.chargeType = this._activateRoute.snapshot.paramMap.get('string');
  this.getAllProducts();
}


getAllProducts(){
  this._chargeService.getCharges().subscribe((resp : any)=>{
    if(resp.status ==200){
         this.categories = resp.tax;
         this.charge = resp.charge
         if (this.chargeType == "TAX"){
              this.chargeForm.controls.charges.setValue(this.categories);
         
         }
         if (this.chargeType == "CHARGE"){
          this.chargeForm.controls.charges.setValue(this.charge);
     
     }
            
    }
  })
}



get f(){
    return this.chargeForm.controls;
 }



onlyNumber(event : KeyboardEvent){
  const key = event.key;
  const price = this.chargeForm.controls.charges.value;
  if(key==='.')
     return String(price).indexOf(key) > -1 ? false : true;
  return this.numbers.includes(key);
}


 updateCharge(){
     this.submitted = true;
     if(this.chargeForm.valid){
     const product = this.chargeForm.value;
     const event = {
      "charge": this.chargeForm.value.charges,
      "type":this.chargeType    
    }
     this._chargeService.updateCharge(event).subscribe((response : any) =>{
            this.router.navigate(['/charges/']);      
     })

    }
 }
 }





