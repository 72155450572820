<div class="row">
    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-border">
            <div class="card-body">
                <h2 class="card-title">{{ isEdit ? 'Update Certification Logo' :'Add Certification Logo'}}</h2>
                <div class="form-group">
                    <label class="required">{{ isEdit ? 'Existing Logo:' :'Select Logo:'}}</label>
                    <img [ngClass]="{ 'show-photo' : !photo }" [src]="photo" height="180px" width="180px" >
                    <input type="file" accept="image/*" (change)="onFileChange($event)" #image class="form-control cursor-pointer"
                        [ngClass]="{ 'is-invalid': fileInvalid }">

                    <label [class.text-danger]="fileInvalid">*The image dimensions must be 180x180 pixels.</label>
                </div>
                <div class="justify-content-center d-flex mt-4">
                    <button type="button" class="btn btn-submit mr-3" (click)="addLogo()">Submit</button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <h2 class="card-title">Added Certification Logo</h2>
                <table id="example" *ngIf="certifications" datatable class="display table table-bordered">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Logo</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="certifications">
                        <tr *ngFor="let certification of certifications, let i=index">
                            <td>{{ i+1 }}</td>
                            <td>
                                <img [src]="certification.certification" height="100px" width="100px">
                            </td>
                            <td>
                                <button class="btn btn-success mr-2" type="button" matTooltip="View" 
                                (click)="edit(certification._id,certification.certification)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-danger" type="button" 
                                (click)="delete(certification._id)" matTooltip="Delete">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="!certifications">No certifications added yet</div>
            </div>
        </div>
    </div>
</div>