import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiBaseUrl: string = environment.url;

  constructor(private _http: HttpClient) { }

  login(email:string,password:string) {
    let data = { email : email, password : password }
    return this._http.post(environment.url+'v1/adminLogin', data);
  }

  getUser(userId: string) {
    return this._http.get(this.apiBaseUrl + 'v1/user-profile/'+userId);
  }

  getUsersByType(userType: string) {
    let params = new HttpParams().set('userType', userType);
    return this._http.get(this.apiBaseUrl + 'v1/getAllUsers', { params });
  }

  approveRejectAccount(userId: string, status: string,rejection_reason:string) {
    return this._http.put(this.apiBaseUrl + 'v1/user-request/' + userId, { admin_status: status, rejection_reason: rejection_reason });
  }

  deleteUser(userId: string) {
    let data = { "userId": userId };
    return this._http.post(this.apiBaseUrl + 'v1/deleteUser/', data);
  }

  enableDisable(data){
    return this._http.post(this.apiBaseUrl + 'v1/acticeInactiveUser', data)
  }
}
