import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { OfferService } from 'src/app/services/offer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { noWhitespaceValidator } from 'src/app/_validators/noWhitespaceValidator';

@Component({
  selector: 'app-add-offers',
  templateUrl: './add-offers.component.html',
  styleUrls: ['./add-offers.component.css']
})
export class AddOffersComponent implements OnInit {

  categories : any[] = [];

  OfferForm : FormGroup;

  files : any[] = [];

  submitted : boolean = false;
  categoryname;
  numbers = ["0","1","2","3","4","5","6","7","8","9","."];
  fileTypes = ["image/jpeg","image/png","image/jpg","image/tiff","image/gif"];


  constructor(private snackbar: SnackbarService,private _offerService : OfferService, private _fb : FormBuilder,private router: Router,private _activateRoute : ActivatedRoute,) { }

  ngOnInit(): void {
    this.categoryname = this._activateRoute.snapshot.paramMap.get('name')

    this.getAllProducts();

    this.OfferForm = this._fb.group({
       product_id : ['', Validators.required],
       off_percent : ['', [Validators.required,noWhitespaceValidator]],
       image : ['', Validators.required],
     

     
     });
  }

 
  onFileChange(event : any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
     const fileType=file.type;
    if(this.fileTypes.includes(fileType)){
         this.OfferForm.controls.image.setValue(file);
    }else{
      alert("only "+this.fileTypes.toString()+" format supports");
    }
    }
  }

  onlyNumber(event : KeyboardEvent){
    const key = event.key;
    const off_percent = this.OfferForm.controls.off_percent.value;
    if(key==='.')
       return String(off_percent).indexOf(key) > -1 ? false : true;
    return this.numbers.includes(key);
  }

  getAllProducts(){
    this._offerService.getProducts('').subscribe((resp : any)=>{
      if(resp.status ==200){
           this.categories = resp.data;
           console.log(this.categories,"allproducts")
           if(this.categories.length > 0) 
                   this.OfferForm.controls.category.setValue(this.categories[0]._id);        
      }
    })
  }

  get f(){
    return this.OfferForm.controls;
  }

 
  // addOffer(event : any){
  //   this.submitted = true;
  //    if(this.OfferForm.valid){
  //     const product =  this.OfferForm.value;
  //     const product_id = this.OfferForm.value.product_id 
  //     console.log(this.OfferForm.value.category,"ooooooooooooooooooooooooooooooooooooooooooooo")

  //    console.log(product,"sdnckdfnvkndfknvkndfknvnkdfnkv")
  //     this._offerService.addOffer(product).subscribe((resp : any)=>{
  //         if(resp.status == 200){
  //           this.OfferForm.reset();
  //           this.submitted = false;
  //         }
  //         this.router.navigate(['/offers/']);

  //     });
  //    }else{
  //       return
  //      }
  //    }


  addOffer(event : any){
      this.submitted = true;
      console.log(this.OfferForm.value);
      if(this.OfferForm.valid){
            const offer = this.OfferForm.value;
            const formData = new FormData();
            formData.append('product_id',offer.product_id);
            formData.append('off_percent', offer.off_percent);
            formData.append('image', offer.image);
            formData.append('type', 'product');

            this._offerService.addOffer(formData).subscribe((response : any) =>{
              if(response.status == 200){
                          this.OfferForm.reset();
                          this.submitted = false;
                          this.router.navigate(['/offers/']);

                        }
              else{
                this.snackbar.successSnackBar("Offer already added for this product.", '');
                this.router.navigate(['/offers/']);

                }
                    
            })
      }else{
        // alert("Offer already added for this product.")
        return
         }
      }
  }

  



