<div class="categoryname">Rewards</div>

<div class="row" >
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
   <div class="card card-border">
    <div class="card-body" id = "example_wrapper">
          <form [formGroup] = "productForm">
           <div class="row">
               
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="fail" class="required">Fail Reward:</label>
                        <input id="fail" maxlength="7"  formControlName="fail" [ngClass]="{ 'is-invalid': submitted && f.fail.errors}" class="form-control" placeholder="Enter reward for fail">
                        <div *ngIf="productForm.controls.fail.invalid &&  submitted" class="error">
                            <div *ngIf="productForm.controls.fail.errors.required">
                                Please enter reward for fail
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="pass" class="required">Pass Reward</label>
                        <input id="pass" maxlength="7"  formControlName="pass" [ngClass]="{ 'is-invalid': submitted && f.pass.errors}" class="form-control" placeholder="Enter reward for pass" (keypress)="onlyNumber($event)">
                        <div *ngIf="productForm.controls.pass.invalid &&  submitted" class="error">
                            <div *ngIf="productForm.controls.pass.errors.required">
                                Please enter reward for pass
                            </div>
                        </div>
                    </div>
                </div>
                
            
            </div>

                <div class="justify-content-center d-flex mt-4">
                <button type="submit" class="btn btn-submit mr-3"  (click)="updateReward()">Submit</button>
                </div>
      </form>
    </div>
   </div>
  </div>
</div>
