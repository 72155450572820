import { Router } from "@angular/router";
import { QuizService } from "src/app/services/quiz.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormArray,FormBuilder,FormGroup,Validators,} from "@angular/forms";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-add-quiz",
  templateUrl: "./add-quiz.component.html",
  styleUrls: ["./add-quiz.component.css"],
})
export class AddQuizComponent implements OnInit {
  
  showLoader: boolean = false;
  submitted: boolean = false;
  quiz: FormGroup;
  answerTypes: string[] = ["text", "image"];
  currentQuestionIndex: number = -1;
  API_URL = environment.url
  fileTypes = ["image/jpeg", "image/png", "image/jpg", "image/tiff"];

  @ViewChild("scrollContainer")
  private scrollContainer: ElementRef;

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _quizService: QuizService
  ) {}

  ngOnInit(): void {
    this.quiz = this._fb.group({
      name: ["", [Validators.required]],
      questions: this._fb.array([], [ Validators.required ])
    });
  }

  get f() {
    return this.quiz.controls;
  }

  get questions() {
    return this.quiz.get("questions") as FormArray;
  }

  addQuestion() {
    const question = this._fb.group({
      title: ["", [Validators.required]],
      answertype: [this.answerTypes[0], Validators.required],
      answers: this._fb.array([], [ Validators.required ]
      ),
    });
    this.questions.push(question);
     
    this.currentQuestionIndex = this.questions.length - 1;
     
  }

  answers(index: number) {
    return this.questions.controls[index].get("answers") as FormArray;
  }

  addAnswer(index: number) {
    const answerType=this.questions.controls[index].get("answertype").value
    const answer = this._fb.group({
      type: [answerType,Validators.required,],
      value: ['', [Validators.required]],
      isRight: [false, Validators.required],
    });
    
    this.answers(index).push(answer);
    scrollTo(0, this.scrollContainer.nativeElement.scrollHeight);
  }

  answerTypeChange(event: any, questionIndex: number, answerIndex: number) {
    this.answers(questionIndex).controls[answerIndex].get("isRight").setValue(false);
    this.answers(questionIndex).controls[answerIndex].get("type").setValue(event.target.value);
    this.answers(questionIndex).controls[answerIndex].get("value").setValue("");
  }

  answerTypeChangeFromQuestion(event: any, questionIndex: number) {
    const answerType = event.target.value;
    this.answers(questionIndex).controls.forEach((control) => {
      control.get("type").setValue(answerType);
      control.get("isRight").setValue(false);
      control.get("value").setValue('');
    });
  }

  getAnswerType(questionIndex: number, answerIndex: number) {
    return this.answers(questionIndex).controls[answerIndex].get("type").value;
  }

  async onFileChange(event: any, questionIndex: number, answerIndex: number) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      const fileType = file.type;
      if (this.fileTypes.includes(fileType)) {
        if (confirm("Do you really want to upload this file")) {
          const formData = new FormData();

          formData.append("media", this.modifyFile(file));
          this._quizService.quizAnswerImage(formData).subscribe((res:any) => {
            this.answers(questionIndex).controls[answerIndex].get("value").setValue(res.data);
          });
        }
      } else {
        alert("only " + this.fileTypes.toString() + " format supports");
      }
    }
  }

  modifyFile(originalFile:File){
    // Original file name
    const originalFileName = originalFile.name;

    // Extract the first 5 characters of the original filename
    const first5Characters = originalFileName.slice(0, 5);

    // Determine the original file extension
    const originalExtension = originalFileName.split('.').pop();

    // Default extension if the original extension is not known
    const defaultExtension = 'png';

    // Create a new file name with the first 5 characters and default extension
    const newFileName = first5Characters + '.' + (originalExtension || defaultExtension);

    // Create a new Blob with the same content and a different name
    const modifiedFile = new File([originalFile], newFileName, { type: originalFile.type });

    return modifiedFile;
  }

  removeQuestion(questionIndex: number) {
    this.questions.removeAt(questionIndex);
  }

  removeAnswer(questionIndex: number, answerIndex: number) {
    this.answers(questionIndex).removeAt(answerIndex);
  }

  isAlreadyAnswerSelected(questionIndex: number) {
    this.answers(questionIndex).controls.forEach((answer) => {
      const value = answer.get("isRight").value;
      if (value) answer.get("isRight").setValue(false);
    });
  }

  saveQuiz() {
    const quiz = this.quiz.value;
    console.log(this.quiz,this.quiz.valid);
    
    this.submitted = true;
    if (this.quiz.valid) {
      const questionIndex = this._quizService.isNoAnswerSelectedAsCorrect(quiz);
      console.log('questionIndex should be -1...',questionIndex)
      if (questionIndex === -1) {
        this._quizService.addQuiz(quiz).subscribe((response: any) => {
          if (response.status == 200) this._router.navigate(["quiz"]);
        });
      } else {
        this.currentQuestionIndex = questionIndex;
        alert("Question doesn't have any correct answer");
      }
    } else {
      console.log('quiz form is not valid...')
      this.logValidationErrors(this.quiz)
      const questionIndexAndAnswerLength =
        this._quizService.renderOnErrorQuestion(quiz,this.currentQuestionIndex);
      this.currentQuestionIndex =
        questionIndexAndAnswerLength.currentQuestionIndex;
      if (this.questions.length === 0) {
        this.addQuestion();
      }
      /*  if(questionIndexAndAnswerLength.answerLength===0)
           this.addAnswer(this.currentQuestionIndex);
   */
      if (
        this.answers(this.currentQuestionIndex)?.errors?.minlength ||
        this.answers(this.currentQuestionIndex)?.errors?.required
      ) {
        this.addAnswer(this.currentQuestionIndex);
      }
    }
  }

  logValidationErrors(group: FormGroup = this.quiz): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);

      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        console.log('Key:', key, 'Errors:', abstractControl.errors);
      }
    });
  }
}
