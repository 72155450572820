import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TestimonialService {
    
  apiBaseUrl: string = environment.url;

  constructor(private _http: HttpClient) { }

  add(data) {
    return this._http.post(this.apiBaseUrl + 'v1/add-testimonial', data );
  }

  getAll() {
    return this._http.get(this.apiBaseUrl + 'v1/get-testimonial');
  }

  deleteTestimonial(id){
    return this._http.delete(this.apiBaseUrl + `v1/delete-testimonial/${id}`);
    
  }


}
