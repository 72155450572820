import 'datatables.net';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
import { Router} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-premium-users',
  templateUrl: './premium-users.component.html',
  styleUrls: ['./premium-users.component.css']
})
export class PremiumUsersComponent implements OnInit {
  allUsers;
  userCount;
  userId;
  metricData;
  sendDataToStatusApi;
  
  public showLoader:boolean= false;
  
  apiBaseUrl : string = environment.url;
  
      constructor(private http: HttpClient, private router: Router) { }
  
    ngOnInit() {
  
  this.getPremiumUser();
       $(document).ready(function() {
      $('#example').DataTable();
      } );
  
  
  }
     getPremiumUser() {  
       this.showLoader = true;
      this.http.get(this.apiBaseUrl+'v1/getPremiumUsers').subscribe((data : any) => {
      
       if(data.status == 200){ 
         this.showLoader = false;
        this.allUsers= data.data;  
        this.metricData= data.metric;  
        console.log(this.metricData)    
       $(document).ready(function() {
      $('#example').DataTable();
      } );
  
  
     }     
           else
           {
            
           this.allUsers= ''; 
    
           }
  
       })
  
       }
  
  
  
  
 
  
  
  
  
  
  

  
  
  }

  
  