import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-professional-accounts',
  templateUrl: './professional-accounts.component.html',
  styleUrls: ['./professional-accounts.component.css']
})
export class ProfessionalAccountsComponent implements OnInit {


  rejectSubmitted=false;
  rejectReason=""
  rejectingAccount:any;
  accounts=[]
  sendDataToStatusApi:any
  

  constructor(
    private fb:FormBuilder,
    private userService:UserService,
    private ngxBootstrapConfirmService : NgxBootstrapConfirmService) { }

  ngOnInit(): void {
    this.getUsers()
  }

  getUsers() {
    this.userService.getUsersByType("Professional").subscribe((response:any) => {
      this.accounts=response.data;

      $(document).ready(function () { $('#example').DataTable(); });
    })
  }

  manageAccount(account:any,status:string) {
    
    this.ngxBootstrapConfirmService.confirm({ title:"Are you sure to perform this action?", confirmLabel:'Yes', declineLabel:'No' })
    .then((confirmed:boolean) => {
      if(confirmed) {
        if(status=='Rejected') {
          this.rejectSubmitted=false;
          this.rejectReason=""
          this.rejectingAccount=account;
        } else {
          this.approveRejectAccount(account,status,null);
        }
      }
    })
  }

  approveRejectAccount(account:any,status:string,rejection_reason:string) {
    this.userService.approveRejectAccount(account._id,status,rejection_reason).subscribe((response:any) => {
      account.admin_status=status;
      this.rejectingAccount=null
    })
  }

  rejectAccount() {
    this.rejectSubmitted=true; 
    if(this.rejectReason && this.rejectReason.length >= 20) {
     this.approveRejectAccount(this.rejectingAccount,"Rejected",this.rejectReason)
    }
  }

  delete(account,index:number) {
    if (confirm("Are you sure to want to delete?")) {
      this.userService.deleteUser(account._id).subscribe((response:any) => {
        this.accounts.splice(index,1);
      })
    }
  }

  toggleSwitch(value, id) {
    if (value == "1") {
      this.sendDataToStatusApi = { "status": '0', "userId": id }
    } else if (value == "0") {
      this.sendDataToStatusApi = { "status": '1', "userId": id }
    }

    this.userService.enableDisable(this.sendDataToStatusApi).subscribe((date:any) =>{})
  }

}
