import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import {FormBuilder,FormControl,} from "@angular/forms";
import { QuizService } from "src/app/services/quiz.service";

@Component({
  selector: 'app-quiz-reward',
  templateUrl: './quiz-reward.component.html',
  styleUrls: ['./quiz-reward.component.css']
})
export class QuizRewardComponent implements OnInit {
  showLoader: boolean = false;

  submitted: boolean = false;

  selectControl : FormControl = new FormControl();
pass;
fail;


  constructor(private _fb: FormBuilder,
    private _router: Router,
    private _quizService: QuizService) { }

  ngOnInit(): void {
    
    this.getQuizRewards();

     
  }

  getQuizRewards(){
    this._quizService.getQuizRewards().subscribe((resp : any)=>{
      if(resp.status ==200){

           this.pass = resp.data.pass;
           this.fail = resp.data.fail;
           $(document).ready(function() {
            $('#example').DataTable();
            } );
      }

    } );

  }
}