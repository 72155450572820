<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer *ngIf="isLoggedIn$ | async" [class]="(isHandset$ | async) ? 'mobile-sidenav' : 'sidenav'"
    fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) ? false : true">
    <mat-toolbar style="color: #ffff;">Menu</mat-toolbar>
    <mat-nav-list>
      <!-- <a mat-list-item href="#">Link 1</a>
        <a mat-list-item href="#">Link 2</a>
        <a mat-list-item href="#">Link 3</a> -->
      <ng-container *ngFor="let menu of menus">
        <ng-container *ngIf="menu.route!==null">
          <a class="link card-hover" mat-list-item [routerLink]="menu.route" routerLinkActive="active">{{menu.name}}
            <mat-divider></mat-divider></a>
        </ng-container>
        <ng-container *ngIf="menu.route===null">
          <mat-accordion mat-list-item multi="false">
            <mat-expansion-panel class="link card-hover" [expanded]="menuName === menu.name"
              (opened)="setMenuName(menu.name)">
              <mat-expansion-panel-header class="link card-hover">
                <mat-panel-title class="link card-hover">
                  {{menu.name}}

                </mat-panel-title>

              </mat-expansion-panel-header>
              <a class="link card-hover" *ngFor="let smenu of menu.subMenu" mat-list-item [routerLink]="smenu.route"
                routerLinkActive="active">{{smenu.name}}
                <mat-divider></mat-divider></a>

            </mat-expansion-panel>
          </mat-accordion>
          <mat-divider></mat-divider>
        </ng-container>
      </ng-container>
      <p class="link" mat-list-item>
        <mat-divider></mat-divider>
      </p>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" *ngIf="isLoggedIn$ | async">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleDrawer()">
        <mat-icon aria-label="Side nav toggle icon" style="font-size: xx-large;">menu</mat-icon>
      </button>
      <span style="margin-left: 10px;">BIG4 Health Admin</span>
      <span class="example-spacer"></span>
      <div class="notification-top">
        <label class="notification-count">{{count ? count : 0}}</label>
        <button routerLink="/notifications" mat-icon-button class="example-icon favorite-icon"
          aria-label="Example icon-button with heart icon">
          <mat-icon>notifications</mat-icon>
        </button>
      </div>
      <button (click)="logout()" mat-icon-button class="example-icon favorite-icon"
        aria-label="Example icon-button with heart icon">
        <mat-icon>logout</mat-icon>
      </button>
    </mat-toolbar>
    <div class="page-wrapper">
      <div class="">
        <app-loader *ngIf="!gettingNotifications" ></app-loader>
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>