

  <!-- <button type="button" class="btn btn-success"   style="margin-bottom: 30px;">Add Charges</button> -->

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example"   datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Type</th>
                      <th>Charges</th>
                      <th>Action</th>                      
                  </tr>
              </thead>
             <tbody>
               <tr >
                     <td>1</td>
                     <td>Tax</td> 
                     <td>${{ tax }}</td> 
                
                     <td>
                      <mat-icon  color="primary" aria-hidden="false" aria-label="view icon" routerLink="/update-charges/TAX" matTooltip=" update tax charges">open_in_new</mat-icon>   
                      <!-- <mat-icon class="marginLeft10" color="primary" aria-hidden="false" aria-label="edit icon" matTooltip="edit charges"  routerLink="/{{route.eLearningVideos}}/{{category.type}}">edit</mat-icon> -->
                      <!-- <mat-icon class="marginLeft10" color="warn" aria-hidden="false" aria-label="delete icon" matTooltip="delete category" (click)="deleteCharges(category._id)">delete</mat-icon> -->
                     </td>

                 </tr>
                 <!-- <tr >
                  <td>2</td>
                  <td>Shipping</td> 
                  <td>${{ charge }}</td> 
             
                  <td>
                   <mat-icon  color="primary" aria-hidden="false" aria-label="view icon" routerLink="/update-charges/CHARGE" matTooltip=" update shipping charges">open_in_new</mat-icon>   
            <mat-icon class="marginLeft10" color="primary" aria-hidden="false" aria-label="edit icon" matTooltip="edit charges"  routerLink="/{{route.eLearningVideos}}/{{category.type}}">edit</mat-icon> -->
                   <!-- <mat-icon class="marginLeft10" color="warn" aria-hidden="false" aria-label="delete icon" matTooltip="delete category" (click)="deleteCharges(category._id)">delete</mat-icon> -->
                  <!-- </td> -->

              <!-- </tr> --> 
                
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>