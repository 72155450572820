import * as $ from 'jquery';
import { Routes } from 'src/app/models/routes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailService } from '../services/email.service';

@Component({
  selector: 'app-email-management',
  templateUrl: './email-management.component.html',
  styleUrls: ['./email-management.component.css']
})
export class EmailManagementComponent implements OnInit {

  showLoader : boolean = true;

  categories : any[] = [];

  route : Routes = new Routes();

  emailFormArray:any[]=[];
  // checkedCategoryList:any;

  masterSelected:boolean;
  checklist:any;
  checkedList:any;
  isSelected = false;
  a = false;
  isMasterSel:boolean
  constructor(private emailService : EmailService, public dialog: MatDialog) { }

  ngOnInit(): void {
     this.getAllUserEmail();
     this.getCheckedItemList();
  }

  
getAllUserEmail(){
    this.emailService.getUserEmails().subscribe((response : any) => {
      this.showLoader = false;
      this.categories = response.data;
      
    $(document).ready(function() {
      $('#example').DataTable();
      } );
  },error => this.showLoader = false);
}




checkUncheckAll() {
  for (var i = 0; i < this.categories.length; i++) {
    this.categories[i].isSelected = this.isMasterSel;
  }
  this.getCheckedItemList();
}
 
isAllSelected() {
  this.isMasterSel = this.categories.every(function(item:any) {
      return item.isSelected == true;
    })
  this.getCheckedItemList();
}

getCheckedItemList(){
 
  this.emailService.checkedCategoryList = [];
  for (var i = 0; i < this.categories.length; i++) {
    if(this.categories[i].isSelected)
    this.emailService.checkedCategoryList.push(this.categories[i]._id);
  }
  console.log(this.emailService.checkedCategoryList.length,"length");
  if (this.emailService.checkedCategoryList.length > 0){
    this.a = true;

  }
  if (this.emailService.checkedCategoryList.length == 0){
    this.a = false;
  }
  // this.emailService.checkedCategoryList = JSON.stringify(this.emailService.checkedCategoryList);
}


}



