import { PostService } from '../services/post.service';
import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-md-posts',
  templateUrl: './md-posts.component.html',
  styleUrls: ['./md-posts.component.css']
})
export class MdPostsComponent implements OnInit {

  @Input() type:string
  list=[]

  constructor( private postService:PostService ) { }

  ngOnInit(): void {
    this.getPosts()
  }

  getPosts(){
    this.postService.getMdPosts(this.type).subscribe((response:any) =>{
      this.list = response.data
      $(document).ready(function () { 
        $('#example').DataTable(); 
      });
    })
  }

}
