<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card card-border">
            <div class="card-body" id="example_wrapper">
                <form [formGroup]="blogForm">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="productName" class="required">Title:</label>
                                <input id="productName" maxlength="150" class="form-control"
                                    (input)="space($event,'title')"
                                    [ngClass]="{ 'is-invalid': submitted && f.title.errors}"
                                    placeholder="Enter Blog Title" formControlName="title">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="blogCategory" class="required">Category:</label>
                                <select class="form-control" formControlName='blogCategory'
                                    [ngClass]="{ 'is-invalid': submitted && f.blogCategory.errors}">
                                    <option value="" selected>Choose Category</option>
                                    <option value="Diabetes">Diabetes</option>
                                    <option value="Hypertension">Hypertension</option>
                                    <option value="High Cholesterol">High Cholesterol </option>
                                    <option value="Weight Management">Weight Management </option>

                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="description" class="required">Description:</label>
                                <quill-editor
                                    [styles]="{height: '200px', border : submitted && f.description.errors ? '1px solid red' : ''}"
                                    [ngClass]="{ 'ql-editor-border': submitted && f.description.errors}"
                                    formControlName="description" placeholder="Enter Blog Description"
                                    (onContentChanged)="contentChanged($event)"
                                    (onEditorCreated)="created($event)"></quill-editor>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <input type="file" id="images" [ngClass]="{ 'is-invalid': submitted && f.image.errors}"
                                    class="form-control" accept="image/*" (change)="onFileChange($event)">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="author" class="required">Author:</label>
                                <input id="author" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.author.errors}"
                                    (input)="space($event,'author')" placeholder="Enter Blog Author"
                                    formControlName="author">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="author_bio" class="required">Bio:</label>
                                <textarea id="author-bio" maxlength="250" formControlName="bio" class="form-control"
                                    (input)="space($event,'bio')" placeholder="Enter author bio" rows="3"
                                    [ngClass]="{ 'is-invalid': submitted && f.bio.errors}"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="justify-content-center d-flex mt-4">
                        <button type="submit" class="btn btn-submit mr-3" (click)="createBlog()">Submit</button>
                    </div>

                </form>




            </div>
        </div>
    </div>
</div>