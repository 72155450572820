import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Pagination } from '../shared/pagination';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  dataMap: Map<string, any> = new Map();
  pagination = new Pagination(0)
  notifications: any[] = [];


  constructor(private notificationService: NotificationService,
    private router:Router) {
     this.dataMap.set('CREATE_CLIENT_USER', '/all-users');
     this.dataMap.set('CREATE_PRO_USER', '/professional_accounts');
     this.dataMap.set('CREATE_POST_MED', '/posts/med');
     this.dataMap.set('CREATE_POST_ME', '/posts/me');
     this.dataMap.set('CREATE_POST_WORLD', '/posts/world');
     this.dataMap.set('CREATE_CONTACT', '/contact_us');
     this.dataMap.set('CREATE_USER', '/all-users');
  }

  ngOnInit(): void {
    this.getAllNotifications();
  }

  getAllNotifications() {
    this.notificationService.getNotifications(this.pagination.currentIndex).subscribe((response: any) => {
      this.notifications = response.data
      this.pagination.total = response.count
      this.pagination.size = response.page_size
    })
  }

  onPageChange(event) {
    this.pagination.page = event
    this.pagination.currentIndex=event-1
    this.getAllNotifications()
  }

  viewNotification(type){
    let value = this.dataMap.get(type);
    this.router.navigate([value])
  }
}

