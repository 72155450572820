import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CertificationLogoService {

  apiBaseUrl: string = environment.url;

  constructor(private _http: HttpClient) { }

  add(data) {
    return this._http.post(this.apiBaseUrl + 'v1/add-certification', data );
  }

  getAll() {
    return this._http.get(this.apiBaseUrl + 'v1/get-certification');
  }

  delete(id){
    return this._http.delete(`${this.apiBaseUrl}v1/delete-certificates/${id}`);
  }

  edit(id,data){
    return this._http.put(`${this.apiBaseUrl}v1/edit-certificates/${id}`,data);
  }


}
