import { SwiperOptions } from 'swiper';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {

  config: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoplay: true,
    fadeEffect: { crossFade: true },
    spaceBetween: 30
  };

  product: any;
  apiBaseUrl = environment.url;
  SERVER_URL: string = environment.url;
  returnRequested: any;
  refunded: any;
  total_payable_amount;
  percent;

  constructor(private _activateRoute: ActivatedRoute, private _orderService: OrdersService) {

  }

  ngOnInit(): void {
    console.log(this._activateRoute.snapshot.paramMap)
    this.getProductDetailById(this._activateRoute.snapshot.paramMap.get('id'))
  }

  getProductDetailById(productId: string) {
    this._orderService.getOrderDetail(productId).subscribe((resp: any) => {
      if (resp.status == 200) {
        this.product = resp.data;
        this.refunded = this.product.refunded
        this.returnRequested = this.product.returnRequested
        var number = this.product.price
        var total_percent = this.product.tax
        this.percent = (total_percent / 100) * number;
        this.total_payable_amount = (this.product.price + this.percent + this.product.shipping_charge)
      }
    })
  }
}
