

  <button type="button" class="btn btn-success" routerLink="/add-offers"  style="margin-bottom: 30px;">Add Offer</button>

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example"   datatable class="display">
              <thead>
                <tr>
                      <th>Sr. No.</th>
                      <th>Off Percent</th>
                      <th>Created On</th>
                      <th>Action</th>                      
                </tr>
              </thead>
             <tbody>
               <tr *ngFor="let category of categories; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{category.off_percent}}%</td>      
                     <td>{{category.createdOn | date :'LLLL dd, yyyy , hh:mm a'}} </td>           
                     <td>
                      <mat-icon class="marginLeft10" color="primary" aria-hidden="false" aria-label="edit icon" matTooltip="edit category" routerLink="/update-offers/{{category._id}}">edit</mat-icon> 
                      <mat-icon class="marginLeft10" color="warn" aria-hidden="false" aria-label="delete icon" matTooltip="delete category" (click)="deleteOffer(category._id)">delete</mat-icon>
                     </td>
                </tr>
                 
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>