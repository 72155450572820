import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmailService {

  apiBaseUrl : string = environment.url;
  checkedCategoryList:any;


  constructor(private _http : HttpClient) { }

  getUserEmails(){
    return this._http.get(this.apiBaseUrl+'v1/getBulkEmailUsers');
 }

 getAllSentEmails(){
  return this._http.get(this.apiBaseUrl+'v1/get-massEmailss');
  }

  getUsersSentEmails(user_id){
    return this._http.get(this.apiBaseUrl+'v1/get-massEmails?user='+user_id);
  }

 createEmail(data:any){
  return this._http.post(this.apiBaseUrl+'v1/mass-email',data);
}

SendEmail(blog : FormData){
  return this._http.post(`${this.apiBaseUrl}v1/mass-email`, blog);
}

deleteEmail(ids:string[]){
  return this._http.delete(`${this.apiBaseUrl}v1/delete-mass-email`, { params : { ids: ids } } );
}
 
}